import TwoColContent from "../../../templates/twoColContent";
import { SideBar } from "../components";
import { useVerifyEmail } from "./hooks";
import Loading from "../../../components/loading";
import { Redirect } from "react-router-dom";
import { clearToken } from "../../../common/localstorage";

const ExhangeCodeManual = () => {
  const { loading, email, done } = useVerifyEmail();
  clearToken()

  if (loading) {
    return (
      <TwoColContent
      leftContent={<SideBar />}
      rightContent={
        <div className="flex h-full items-center justify-center">
          <Loading />
        </div>
      }
    />
    )
  }

  if (done && email) {
    return (
      <Redirect 
        to={{
          pathname: "/signup/manual",
          state: { email }
        }}
    />
    )
  }

  // TODO : add error view here
  return (
    <TwoColContent 
      leftContent={<SideBar />}
      rightContent={
        <div className="flex h-full items-center justify-center">
          <h3>Oops, something went wrong</h3>
        </div>
      }
    />
  )
}

export default ExhangeCodeManual;