import { useFormik } from "formik";
import { useState } from "react";
import { verifyEmail } from "../../../../api";
import { validationSchema } from "../../components/SignUpEmail/validations.signUpEmail";

export const useSignUpEmailForm = () => {
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [submitError, setSubmitError] = useState("");

  const onSubmit = async (values) => {
    try {
      setSubmitError("");
      setLoading(true);
      await verifyEmail(values);
      setEmail(values?.email);
      setDone(true);
    } catch (e) {
      if (e?.response?.data?.email) {
        setSubmitError(e.response.data.email);
      }
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit,
  });

  return {
    formik,
    loading,
    submitError,
    done,
    email,
    setEmail,
  };
};
