import { Icon } from "@iconify/react";
import Circle from "../circle";

const AccountingItem = ({ accoutingConnection }) => {
  return (
    <div className="flex flex-row items-center justify-between border-t py-4 px-6">
      <div className="flex gap-4">
        <div className="border rounded py-2 px-7 h-fit">
          <Icon icon="ph:chart-bar" width={22} />
        </div>
        <div className="flex flex-row">
          <h5 className="text-sm font-bold text-float-purple-2 mb-0.5">
            {accoutingConnection.integration.label}{" "}
            {accoutingConnection.status !== "DISABLED_MANUALLY" ||
            accoutingConnection.status !== "DISABLED_BY_SYSTEM"
              ? "connected"
              : null}
          </h5>
          {accoutingConnection.status === "DISABLED_MANUALLY" ||
          accoutingConnection.status === "DISABLED_BY_SYSTEM" ? (
            <div className="ml-2 mb-auto tooltip">
              <Circle color="#dc2626" width="18" height="18" />
              <span className="text">Connection expired</span>
            </div>
          ) : null}
        </div>
      </div>
      {/* <Icon
            className="cursor-pointer"
            icon="mdi:cancel-box-outline"
            width={20}
            color="#959595"
          /> */}
    </div>
  );
};

export const AccountingConnectionList = ({ accountingList }) => {
  return (
    <div className="flex flex-col">
      {accountingList.map((accounting) => (
        <AccountingItem
          key={accounting.uuid}
          accoutingConnection={accounting}
        />
      ))}
    </div>
  );
};
