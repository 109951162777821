import { Icon } from "@iconify/react"
import { useContext } from "react";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import { UserContext } from "../hooks/useWhoAmI";

export const SettingsLayout = ({ children }) => {
  const history = useHistory();
  const { isConnectStep } = useContext(UserContext);

  return (
    <div className="bg-float-purple-6 flex flex-col pt-[130px] pb-11 pr-11 md:pr-24 lg:pr-11 gap-y-4 w-full min-h-screen h-full pl-[120px]">
      <div className="pl-12 flex flex-col gap-6">
        {
          isConnectStep &&
          <button className="w-fit flex items-center gap-2 bg-transparent hover:bg-transparent hover:border-none border-none">
          <Icon 
              icon="mdi:arrow-left"
              className="text-float-purple-2"
              width={20}
            />
          <p className="text-float-purple-2 font-bold" onClick={() => history.push('/')}>
            Back to connecting
          </p>
        </button>
        }

        <h2>Settings</h2>
        <div className="border-b w-full flex gap-4">
          <NavLink 
            to="/settings/my-details" 
            exact
            className="settings-active pb-2"
          >
            <p className="font-bold">
              Your details
            </p>
          </NavLink>
          <NavLink 
            to="/settings/company-details" 
            exact
            className="settings-active pb-2"
          >
            <p className="font-bold text-float-grey-90">
              Company details
            </p>
          </NavLink>
        </div>
        {children}
        </div>
    </div>
  )
}