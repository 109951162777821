import * as React from "react";

function Logo({ color = "black", width = 150, height = 50 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 3421 933"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M0 932.943H206.454C206.543 853.477 237.483 777.293 292.487 721.108C347.49 664.922 422.063 633.325 499.842 633.25V422.318C367.34 422.499 240.314 476.354 146.616 572.073C52.9171 667.793 0.191914 797.567 0 932.943V932.943Z"
          fill={color}
        />
        <path
          d="M0 510.795H206.454C206.543 431.324 237.482 355.134 292.483 298.939C347.485 242.745 422.058 211.135 499.842 211.044V0.113281C367.33 0.294278 240.296 54.1563 146.596 149.888C52.8958 245.621 0.177155 375.409 0 510.795V510.795Z"
          fill={color}
        />
        <path
          d="M923.743 205.229V317.194H836.697V475.791H923.743V932.942H1078.97V475.791H1238.78V317.194H1078.69V205.229C1078.71 194.106 1083.04 183.443 1090.74 175.577C1098.44 167.712 1108.88 163.286 1119.76 163.271H1238.5V4.67383H1119.76C1067.77 4.80954 1017.94 25.992 981.204 63.5823C944.464 101.173 923.801 152.107 923.743 205.229Z"
          fill={color}
        />
        <path
          d="M1507.9 0H1352.67V785.234C1352.69 823.205 1367.48 859.609 1393.78 886.442C1420.07 913.275 1455.72 928.34 1492.89 928.325H1576.58V769.671H1507.9V0Z"
          fill={color}
        />
        <path
          d="M3421 349.861V191.149H3288.59V0H3133.36V785.234C3133.4 823.161 3148.17 859.521 3174.41 886.345C3200.65 913.168 3236.24 928.265 3273.36 928.325H3421V769.671H3288.59V349.861H3421Z"
          fill={color}
        />
        <path
          d="M1955.62 272.898C1892.06 272.898 1829.93 292.155 1777.09 328.233C1724.24 364.311 1683.05 415.589 1658.73 475.584C1634.41 535.578 1628.05 601.594 1640.46 665.282C1652.86 728.969 1683.47 787.469 1728.42 833.382C1773.36 879.295 1830.63 910.559 1892.97 923.22C1955.3 935.882 2019.92 929.372 2078.63 904.514C2137.35 879.656 2187.53 837.567 2222.84 783.569C2258.14 729.571 2276.98 666.089 2276.97 601.153C2276.88 514.112 2242.99 430.664 2182.74 369.122C2122.5 307.58 2040.82 272.974 1955.62 272.898ZM1955.62 770.867C1922.77 770.867 1890.65 760.914 1863.34 742.265C1836.02 723.617 1814.73 697.111 1802.15 666.1C1789.58 635.089 1786.29 600.965 1792.7 568.044C1799.11 535.122 1814.93 504.882 1838.16 481.147C1861.39 457.412 1890.99 441.248 1923.22 434.7C1955.44 428.151 1988.84 431.512 2019.19 444.358C2049.54 457.203 2075.49 478.956 2093.74 506.865C2111.99 534.774 2121.73 567.587 2121.73 601.153C2121.69 646.15 2104.17 689.291 2073.03 721.109C2041.89 752.927 1999.66 770.822 1955.62 770.867V770.867Z"
          fill={color}
        />
        <path
          d="M2865.53 326.203C2817.04 293.889 2760.91 275.557 2703.07 273.145C2645.23 270.732 2587.82 284.329 2536.92 312.498C2486.01 340.666 2443.51 382.361 2413.88 433.176C2384.26 483.99 2368.62 542.037 2368.62 601.183C2368.62 660.328 2384.26 718.375 2413.88 769.19C2443.51 820.005 2486.01 861.7 2536.92 889.868C2587.82 918.037 2645.23 931.634 2703.07 929.221C2760.91 926.809 2817.04 908.477 2865.53 876.163V933.171H3020.65V270.562H2865.53V326.203ZM2690.26 770.869C2657.41 770.869 2625.29 760.915 2597.98 742.267C2570.66 723.618 2549.37 697.113 2536.8 666.101C2524.22 635.09 2520.93 600.966 2527.34 568.045C2533.75 535.124 2549.57 504.883 2572.81 481.149C2596.04 457.414 2625.63 441.25 2657.86 434.701C2690.08 428.153 2723.48 431.514 2753.83 444.359C2784.19 457.204 2810.13 478.957 2828.38 506.866C2846.63 534.776 2856.38 567.588 2856.38 601.154C2856.33 646.171 2838.8 689.33 2807.63 721.151C2776.47 752.972 2734.21 770.853 2690.15 770.869H2690.26Z"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default Logo;
