export const useGetRadioButtons = () => {
  const radioButtons = [
    {
      value: "online_search",
      label: "Online search",
    },
    {
      value: "social_media",
      label: "Social media",
    },
    {
      value: "referral",
      label: "Referral from a friend or family member",
    },
    {
      value: "email",
      label: "Email or online advertisement",
    },
    {
      value: "event_conference",
      label: "Event or conference",
    },
    {
      value: "press_media",
      label: "Press or media",
    },
    {
      value: "family_friends",
      label: "Family or friends",
    },
    {
      value: "other",
      label: "Other",
    },
  ];

  return {
    radioButtons,
  };
};
