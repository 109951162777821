import * as Yup from "yup";

export const validationSchema = Yup.object({
  first_name: Yup.string().required("Please enter your first name"),
  last_name: Yup.string().required("Please enter your last name"),
  email: Yup.string()
    .email("Must be a valid e-mail")
    .required("Email is required")
    .test(
      "Check for non-company e-mail",
      "Must be company e-mail",
      (email) => !/hotmail|gmail|apple|outlook/.test(email)
    ),
  phone_number: Yup.string().required("Please enter your phone number"),
});
