import { useState } from "react";
import { getShowOnboardingCookie } from "../../../../../common/cookie";

export const useShowWelcomeModal = () => {
  const [showWelcomeModal, setShowWelcomeModal] = useState(getShowOnboardingCookie());

  return {
    showWelcomeModal,
    setShowWelcomeModal,
  }
}