import { useState } from "react";
import { BankItem } from "../bankItem";

export const BankList = ({
  banks,
  country,
  setSelectedBank,
  selectedBank,
  setCountry,
}) => {
  const [filteredBanks, setFilteredBanks] = useState(banks);

  return (
    <div className="flex flex-wrap h-full overflow-auto max-h-[500px]">
      <div className="flex w-full mb-4 h-8">
        <input
          type="text"
          placeholder="Search"
          className="border rounded-md ml-8 mr-4 cursor-pointer w-full h-full"
          onChange={(e) => {
            const value = e.target.value;
            if (!value) {
              setFilteredBanks(banks);
              return;
            }
            const filteredBanks = banks.filter((b) =>
              b.name.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredBanks(filteredBanks);
          }}
        />

        <img
          src={`https://flagcdn.com/w40/${country.code}.png`}
          alt={`Country flag for ${country.name}`}
          className="border p-1 ml-auto mr-8 cursor-pointer w-8 h-8 rounded-full"
          onClick={() => {
            setCountry(null);
          }}
        />
      </div>
      {filteredBanks?.map((b) => (
        <BankItem
          key={b.id}
          logo={b.logo}
          name={b.name}
          onClick={() => {
            setSelectedBank(b.id);
          }}
          selected={selectedBank === b.id}
        />
      ))}
    </div>
  );
};
