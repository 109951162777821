import { useState, useEffect, useContext } from "react";
import { getSubscriptionFiles, deleteSubscriptionFile } from "../api"
import { UserContext } from "./useWhoAmI";
import { Toast } from "../components";
import { ToastType } from "../types";
import { setExpandedAccordion, StorageKeys } from '../common/localstorage';
import { useFileListFormat } from "../utils";


export const useSubscriptionFiles = ({ uploadComplete }) => {
  const { updateWhoAmI } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const { getFileListFormat } = useFileListFormat();
  const [subscriptionFiles, setSubscriptionFiles] = useState([]);

  // TODO: add error handling
  const getFiles = (deletingFile = false) => {
    setIsLoading(true);
    getSubscriptionFiles()
      .then((data) => {
        const files = getFileListFormat(data);

        setSubscriptionFiles(files);
        if (deletingFile && files.length === 0) {
          setExpandedAccordion(StorageKeys.SUBSCRIPTION);
          updateWhoAmI()
        }
        return files;
      })
      .finally(() => setIsLoading(false));
  }

  const deleteSubscriptionFileById = async (file) => {
    deleteSubscriptionFile(file.id)
      .then(() => getFiles(true))
      .then(() => {
        Toast(
          {
            type: ToastType.SUCCESS,
            title: 'Yes, success!',
            text: `${file.name} has been deleted`,
          }
          )
        })
      .catch((err) => console.log('Error: ', err))
  }

  
  useEffect(() => {
    getFiles()
    return () => setSubscriptionFiles([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadComplete])

  return {
    subscriptionFiles,
    deleteSubscriptionFileById,
    isLoading,
  }
}