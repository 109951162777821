export const addWorkingDays = (days, startDate = null) => {
  let currentDate = new Date();
  if (startDate) {
    currentDate = new Date(startDate);
  }
  const numToAdd = days;
  for (let i = 1; i <= numToAdd; i++) {
    currentDate.setDate(currentDate.getDate() + 1);
    if (currentDate.getDay() === 6) {
      currentDate.setDate(currentDate.getDate() + 2);
    } else if (currentDate.getDay() === 0) {
      currentDate.setDate(currentDate.getDate() + 1);
    }
  }

  const payOutDate = new Date(currentDate);
  return payOutDate;
};
