import { useFormik } from "formik";
import * as Yup from "yup";
import { inviteSignatory } from "../../../../../api";
import { Toast } from "../../../../../components";
import { ToastType } from "../../../../../types";

export const useInviteSignatoryForm = ({ hide }) => {
  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
    },
   validationSchema: Yup.object({
      first_name: Yup.string()
        .required('First name is required')
        .min(2, 'First name is too short - should be 2 charactes minimum.'),
      last_name: Yup.string()
        .required('Last name is required')
        .min(2, 'Last name is too short - should be 2 charactes minimum.'),
      email: Yup.string()
        .email("Must be a valid e-mail")
        .required("Email is required")
        .test(
          "Check for non-company e-mail",
          "Must be company e-mail",
          (email) => !/hotmail|gmail|apple|outlook/.test(email)
        ),
      }),
    onSubmit: async (values) => {
      try {
        await inviteSignatory(values);
        Toast({
          type: ToastType.SUCCESS,
          title: `Successfully invited ${values.first_name} ${values.last_name}`,
        })
        hide();
        formik.resetForm();
      } catch (e) {
        Toast({
          type: ToastType.ERROR,
          title: 'Error - something went wrong',
          text: e?.response?.data?.message
        })
      }
    },
  });

  return {
    formik
  }
}
