import { useState } from "react";
import { Link } from "react-router-dom";
import CountrySelector from "../../components/countrySelector";
import TwoColWithImage from "../../templates/twoColWithImage";
import {
  HowItWorks,
  SignUpBankID,
  SignUpEmail,
  SignUpGoogleSSO,
} from "./components";
import Image from "../../assets/images/secure_pattern.png";

const SignUp = () => {
  const [country, setCountry] = useState({});
  const handleCountry = (e) => {
    const parsed = JSON.parse(e.target.value);
    setCountry(parsed);
  };

  return (
    <TwoColWithImage leftContent={<HowItWorks />} image={<Image />}>
      <div className="drop-shadow-large bg-white rounded-2xl p-12 flex flex-col items-center max-w-[600px] z-10 gap-4 ">
        <h2 className="text-float-purple-1">Sign up and get an offer</h2>
        <p className="text-float-grey-90 mb-4 mt-2">
          It’s easy and free to get an accurate credit offer from us, just start
          by entering your details.
        </p>
        <div className="w-full">
          <CountrySelector
            onChange={(e) => handleCountry(e)}
            value={country}
            setValue={setCountry}
          />
        </div>
        <div className="w-full flex flex-col items-center gap-2 mb-7">
          {country && country.login_verified && (
            <SignUpBankID country={country} />
          )}
          {country && country.login_email && <SignUpEmail />}
          {country && country.login_sso_google && <SignUpGoogleSSO />}
        </div>
        <div className="flex gap-2">
          <p className="text-float-grey-70">Already have an account?</p>
          <Link className="font-normal text-float-purple-2" to={"/login"}>
            Sign in
          </Link>
        </div>
      </div>
    </TwoColWithImage>
  );
};

export default SignUp;
