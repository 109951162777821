import { initiateLogin } from "../api";
import { useSuccessLogin } from "./useSuccessLogin";
import { CountryContext } from "./useCountry";
import { useContext } from "react";

export const useLoginEid = () => {
  const { setCountry } = useContext(CountryContext);
  const { setCookiesAndLogin } = useSuccessLogin();

  const login = async ({ values, formik, invite_token = null }) => {
    let url = "/process/login";
    if (invite_token) {
      url += `?invite_token=${invite_token}`;
    }

    const startLogin = await initiateLogin(
      values.ssn,
      values.country.code,
      url
    );

    if (startLogin && startLogin.url) {
      setCountry(JSON.stringify(values.country));
      setCookiesAndLogin({ url: startLogin.url });
    }
    formik.setSubmitting(false);
  };

  return {
    login,
  };
};
