import { useContext } from "react";
import useAPI from "../../hooks/useAPI";
import { UserContext } from "../../hooks/useWhoAmI";
import Loading from "../../components/loading";

const Company = ({ company, setSelected }) => {
  return (
    <div
      className="cursor-pointer flex flex-row"
      onClick={() => {
        setSelected(company.id);
      }}
    >
      <div className="w-2/4">
        {company.name} ({company.organisation_number})
      </div>
      <div className="mx-auto">{company.country}</div>
    </div>
  );
};

const CompanyHeaders = () => {
  return (
    <div className="flex flex-row mb-4">
      <div className="w-2/4">Name (orgnr)</div>
      <div className="mx-auto">Country</div>
    </div>
  );
};

const Companies = ({ setSelected }) => {
  const { me } = useContext(UserContext);
  const [result, loading, error] = useAPI({ me, path: "v1/admin/company/" });
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return error;
  }
  return (
    <div className="flex flex-col overflow-auto h-screen">
      <CompanyHeaders />
      {result.map((company) => (
        <Company company={company} setSelected={setSelected} key={company.id} />
      ))}
    </div>
  );
};

export default Companies;
