import { Icon } from "@iconify/react"
import { useState } from "react";
import { logout } from "../../../../../common"
import { InviteSignatoryModal } from "./InviteSignatoryModal";

const NoCompany = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className="flex flex-col gap-2 mt-44 mx-auto w-[560px] px-6">
        <h2 className="text-float-grey-100">Company details</h2>
        <div className="mt-4">
          <div className="flex gap-2">
            <div>
              <Icon
                icon="mingcute:alert-line"
                className="text-functional-yellow-100"
                width={24}
                />
            </div>
            <p className="text-float-grey-90 max-w-md">
              We can’t see that you have any company engagements right now. To sign-up a new company with Float you will need to be a signatory. The signatory can then invite more users if needed.
            </p>
          </div>
          <div className="mt-16 flex gap-4">
            <button
              type="button"
              className="grow"
              onClick={() => setShowModal(true)}
              >
              Invite company signatory
            </button>
            <button
              type="button"
              className="secondary w-[120px]"
              onClick={() => logout('/dashboard')}
              >
              Logout
            </button>
          </div>
        </div>
      </div>
      <InviteSignatoryModal 
        visible={showModal}
        hide={() => setShowModal(false)}
      />
    </>
  )
}

export default NoCompany;
