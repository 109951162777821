import { Icon } from "@iconify/react";

export const UploadButton = ({ onClick, width = "xl:w-48" }) => {
  return (
    <button
      className={`bg-transparent border-float-grey-25 text-float-purple-2 flex gap-2 justify-center ${width}`}
      onClick={onClick}
    >
      <Icon icon="material-symbols:upload-rounded" width={24} />
      Upload
    </button>
  );
};
