import { useState } from "react";
import { useHistory } from "react-router-dom";
import { SideBar } from "../components";
import TwoColContent from "../../../templates/twoColContent";
import { YourDetails, CompanyDetails } from "./components";
import useQuery from "../../../hooks/useQuery";

const AutomatedSignUp = () => {
  const query = useQuery();
  const step = query.get("current_step");
  const [currentStep, setCurrentStep] = useState(step || 0);
  const history = useHistory();

  const steps = [
    {
      step: 0,
      component: YourDetails,
    },
    {
      step: 1,
      component: CompanyDetails,
    },
  ];

  const RenderedComponent = steps[currentStep]?.component;
  const nextPage = () => setCurrentStep(currentStep + 1);
  const prevPage = () => setCurrentStep(currentStep - 1);

  return (
    <TwoColContent
      leftContent={<SideBar />}
      rightContent={
        <RenderedComponent
          nextPage={nextPage}
          prevPage={prevPage}
          history={history}
        />
      }
    />
  );
};

export default AutomatedSignUp;
