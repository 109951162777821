export const ARR = [
  {
    value: "less_than_1",
    label: "Less than 1.0M€",
  },
  {
    value: "1_5",
    label: "1-5M€",
  },
  {
    value: "5_20",
    label: "5-20M€",
  },
  {
    value: "20_50",
    label: "20-50M€",
  },
  {
    value: "more_than_50",
    label: ">50M€",
  },
];

export const BUSINESS_MODEL = [
  {
    value: "saas",
    label: "SaaS",
  },
  {
    value: "paas_or_laas",
    label: "PaaS or IaaS",
  },
  {
    value: "ecommerce",
    label: "eCommerce subscription",
  },
  {
    value: "other",
    label: "Other subscription",
  },
  {
    value: "not_recurring",
    label: "Our revenues are not recurring",
  },
];

export const ANNUAL_GROWTH = [
  {
    value: "less_than_0",
    label: "Less than 0%",
  },
  {
    value: "0_30",
    label: "0-30%",
  },
  {
    value: "30_100",
    label: "30-100%",
  },
  {
    value: "more_than_100",
    label: ">100%",
  },
];

export const CURRENT_RUNWAY = [
  {
    value: "more_than_3",
    label: "Yes, we have more than 3 months of runway",
  },
  {
    value: "less_than_3",
    label: "No, our runway is shorter than 3 months",
  },
];
