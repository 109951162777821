import { Icon } from "@iconify/react"

export const Avatar = ({ size = 24 }) => {
  return (
    <div className={`bg-float-purple-5 rounded-full ${size === 24 ? "p-2" : "p-1.5" } h-fit w-fit`}>
      <Icon
        className="text-float-purple-2"
        icon="mingcute:user-1-line"
        width={size} />
    </div>
  )
}