import { Icon } from "@iconify/react";

export const Row = ({ icon, text, onClick }) => {
  return (
      <div className="flex gap-4 p-4 hover:bg-float-purple-6 cursor-pointer select-none text-black" onClick={onClick}>
        <Icon
          className="text-float-purple-2"
          icon={icon}
          width={24}
        />
        <p className="text-sm font-light">{text}</p>
      </div>
  );
}