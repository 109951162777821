import { useState } from "react";

export const useDeleteFileModal = ({ deleteFileById }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentFile, setCurrentFile] = useState({});

  const openDeleteModal = (file) => {
    setCurrentFile(file);
    setShowDeleteModal(true);
  }

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setCurrentFile({});
  }

  const onDeleteFile = () => {
    deleteFileById(currentFile);
    setCurrentFile({});
    setShowDeleteModal(false);
  }
  
  return {
    showDeleteModal,
    openDeleteModal,
    closeDeleteModal,
    onDeleteFile,
    currentFile,
  };
}