import { InfoModal } from "../../../../components"

export const DeleteUserModal = ({ user, visible, hide, onDelete }) => {
  
  return (
    <InfoModal
      hide={hide}
      visible={visible}
      title="Delete this user?"
      content={
        <p className="mb-6 w-[480px]">
          {`This user will not be able to log in to your company account anymore. Are you sure you want to delete ${user.first_name} ${user.last_name}?`}
        </p>
      }
      closeText="No, cancel"
      submitButton={
        <button onClick={onDelete}>
          Yes, delete
        </button>
      }
    />
  )
}