import { BankConnection } from "./bankConnection";

export const BankConnectionList = ({ bankConnections }) => {
  return (
    <ul>
      {bankConnections.map((connection) => (
        <li key={connection.bank_name}>
          <BankConnection
            status={connection.status}
            bank_name={connection.bank_name}
            accounts={connection.accounts}
          />
        </li>
      ))}
    </ul>
  );
};
