import * as Yup from "yup";
import { useContext } from "react";
import { useFormik } from "formik";
import { UserContext } from "./useWhoAmI";
import { CountryContext } from "./useCountry";
import { inviteUser } from "../api";
import { useState } from "react";
import { Toast } from "../components";
import { ToastType } from "../types";

export const useInviteUserForm = ({ setOpenAddUserModal }) => {
  const { me } = useContext(UserContext);
  const { country } = useContext(CountryContext);

  const [submitErrors, setSubmitErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string()
        .required("First name is required")
        .min(2, "First name is too short - should be 2 charactes minimum."),
      last_name: Yup.string()
        .required("Last name is required")
        .min(2, "Last name is too short - should be 2 charactes minimum."),
      email: Yup.string()
        .email("Must be a valid e-mail")
        .required("Email is required")
        .test(
          "Check for non-company e-mail",
          "Must be company e-mail",
          (email) => !/hotmail|gmail|apple|outlook/.test(email)
        ),
    }),

    onSubmit: async (values) => {
      setSubmitErrors([]);
      setIsLoading(true);
      const is_automated = country?.login_verified;
      try {
        const params = {
          ...values,
          company: [me.selected_company.id],
          is_automated,
        };
        await inviteUser(params);
        formik.resetForm();
        setOpenAddUserModal(false);
        Toast({
          type: ToastType.SUCCESS,
          title: "Yes, success!",
          text: `You successfully invited ${values.first_name} ${values.last_name}`,
        });
      } catch (e) {
        setSubmitErrors(e.response.data);
      } finally {
        setIsLoading(false);
      }
    },
  });

  return {
    formik,
    submitErrors,
    isLoading,
  };
};
