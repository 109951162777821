import { useEffect, useState } from "react";
import { getExpandedAccordion, removeExpandedAccordion, StorageKeys } from "../../../../../common/localstorage";

export const useExpandedAccordion = () => {
  const [accountingExpanded, setAccountingExpanded] = useState(false);
  const [subscriptionExpanded, setSubscriptionExpanded] = useState(false);
  const [transactionExpanded, setTransactionExpanded] = useState(false);


  useEffect(() => {
    setAccountingExpanded(!!getExpandedAccordion(StorageKeys.ACCOUNTING));
    setSubscriptionExpanded(!!getExpandedAccordion(StorageKeys.SUBSCRIPTION));
    setTransactionExpanded(!!getExpandedAccordion(StorageKeys.TRANSACTION));


    removeExpandedAccordion(StorageKeys.ACCOUNTING);
    removeExpandedAccordion(StorageKeys.SUBSCRIPTION);
    removeExpandedAccordion(StorageKeys.TRANSACTION);

  }, [setAccountingExpanded, setSubscriptionExpanded, setTransactionExpanded]);


  return {
    accountingExpanded,
    subscriptionExpanded,
    transactionExpanded,
  }
}