import { createContext, useState, useEffect, useCallback } from "react";

export const ModalItem = ({ visible, children, hide, title, wrapperCSS }) => {
  const closeFunction = useCallback(
    (event) => {
      if (event.key === "Escape") {
        hide();
      }
    },
    [hide]
  );

  useEffect(() => {
    document.addEventListener("keydown", closeFunction, false);

    return () => {
      document.removeEventListener("keydown", closeFunction, false);
    };
  }, [closeFunction]);

  return (
    <div
      id="modal"
      aria-hidden="true"
      className={`${
        visible ? "visible" : "hidden"
      } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-screen md:inset-0 h-screen md:h-full flex items-center justify-center bg-black bg-opacity-50`}
    >
      <div className={`relative p-4 h-full md:h-auto ${wrapperCSS || "w-2/4"}`}>
        <div className="relative bg-white rounded-xl shadow">
          <div className="flex justify-between items-start p-5 rounded-t relative">
            <h3 className="text-xl font-semibold text-gray-900 lg:text-2xl m-auto">
              {title}
            </h3>
            <div
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm items-center cursor-pointer absolute right-5"
              onClick={hide}
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
          </div>
          <div className="pb-6 px-6 space-y-6">{children}</div>
        </div>
      </div>
    </div>
  );
};

export const ModalContext = createContext(null);

const Modal = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState(null);
  const [content, setContent] = useState(null);
  const [wrapperCSS, setWrapperCSS] = useState(null);

  const hide = () => {
    setVisible(false);
    setTitle(null);
    setContent(null);
  };

  return (
    <ModalContext.Provider
      value={{ setTitle, setVisible, setContent, hide, setWrapperCSS }}
    >
      <ModalItem
        visible={visible}
        hide={hide}
        title={title}
        wrapperCSS={wrapperCSS}
      >
        {content}
      </ModalItem>
      {children}
    </ModalContext.Provider>
  );
};

export default Modal;
