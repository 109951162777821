import { UserRow } from "../UserRow";
import { useUsers } from "../../hooks";
import Loading from "../../../../components/loading";

export const UserTable = () => {
  const { users, loading } = useUsers();

  const hasUsers = users && users.length > 0 && !loading;

  return (
    <div className={`flex flex-col ${loading ? "" : "border-b"}`}>
      {loading && (
        <div className="flex items-center justify-center">
          <Loading />
        </div>
      )}
      {hasUsers &&
        users.map((user) => <UserRow key={user.email} user={user} />)}
    </div>
  );
};
