// import Info from "../info";

const InterestBox = ({ tableInfo, currency }) => {
  return (
    <div className="flex flex-row justify-between w-full px-8 pb-4 pt-2 h-full gap-x-4">
      <div className="border-float-purple-5 border-2 rounded bg-float-purple-6 p-1 lg:py-2 lg:px-4 h-full w-full flex flex-col">
        <p className="text-sm xl:text-base font-bold">Principal amount</p>
        <p className="text-sm xl:text-base">
          {tableInfo.amount} {currency}
        </p>
      </div>
      <div className="border-float-purple-5 border-2 rounded bg-float-purple-6 p-1 lg:py-2 lg:px-4 h-full w-full flex flex-col">
        <div className="flex align-center">
          <p className="text-sm xl:text-base font-bold ">Interest (up-front)</p>
          {/* <Info
              classname="text-sm font-medium text-gray-700"
              value={"Charged at pay-out"}
            /> */}
        </div>
        <p className="text-sm xl:text-base">
          {tableInfo.interestRate}% = {tableInfo.interest} {currency}
        </p>
      </div>
      <div className="border-float-purple-5 border-2 rounded bg-float-purple-6 p-1 lg:py-2 lg:px-4 h-full w-full flex flex-col">
        <p className="text-sm xl:text-base font-bold">Monthly payments</p>
        {tableInfo.grace !== "0" && (
          <ul className="list-outside">
            <li>
              <div className="flex flex-row justify-between">
                <p className="text-sm xl:text-base">
                  Month 0-{tableInfo.grace}:
                </p>
                <p className="text-sm xl:text-base">
                  0 {currency}
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-row justify-between">
                <p className="text-sm xl:text-base">
                  Month {parseInt(tableInfo.grace) + 1}-{tableInfo.months}:
                </p>
                <p className="text-sm xl:text-base">
                  {tableInfo.repayment} {currency}
                </p>
              </div>
            </li>
          </ul>
        )}
        {tableInfo.grace === "0" && (
          <ul className="list-outside">
            <li>
              <p className="text-sm xl:text-base">
                Month 1-{tableInfo.months}:{" "}
                {tableInfo.repayment} {currency}
              </p>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default InterestBox;
