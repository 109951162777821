export const CountryItem = ({ name, code, onClick, selected }) => {
  return (
    <div
      className={`cursor-pointer flex flex-row w-2/5 mb-2 border rounded-md  p-1 ml-8 ${
        selected ? "border-float-purple-4" : "border-float-grey-10"
      }`}
      onClick={onClick}
    >
      <img
        src={`https://flagcdn.com/w40/${code}.png`}
        className="w-6 h-6 rounded-full"
        alt={`Country flag for ${name}`}
      />
      <p className="my-auto ml-2">{name}</p>
    </div>
  );
};
