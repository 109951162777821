import { useState } from "react";
import { verifyEmail } from "../../../../api";
import { Toast } from "../../../../components";
import { ToastType } from "../../../../types";

export const useCheckYourEmail = ({ email }) => {
  const [loading, setLoading] = useState(false);

  const resendVerificationMail = async () => {
    if (!email) {
      //TODO : redirect and error toast?
    }
    try {
      setLoading(true);
      await verifyEmail({ email: email });
      Toast({
        type: ToastType.SUCCESS,
        title: "Success",
        text: `Successfully re-sent activation mail to ${email}.`,
      });
    } catch (e) {
      console.log(e);
      Toast({
        type: ToastType.ERROR,
        title: "Error",
        text: e?.response?.data?.email
          ? "This email has already been registered and verified."
          : "An error occurred, please try again later.",
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    resendVerificationMail,
    loading,
  };
};
