import { doDownload } from "../common/axiosUtil";

export const downloadInvoice = async (loan_reference, invoice_id) => {
  // const get = await doGet(
  //   `v1/loan/${loan_reference}/invoice/${invoice_id}/download/`,
  //   undefined,
  //   { responseType: "blob" }
  // );
  const get = await doDownload(
    `v1/loan/${loan_reference}/invoice/${invoice_id}/download/`
  );
  return get;
};
