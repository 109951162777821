import * as Yup from "yup";

export const validationSchema = Yup.object({
  start_timespan: Yup.string().required("Please choose an option."),
  amount: Yup.number().required().positive().integer(),
  currency: Yup.string().required("Please include amount and currency"),
  loan_taker: Yup.object().test(
    'loan-taker',
    'Please choose a loan taker',
    (loanTaker) => {
    const result = Object.entries(loanTaker)
      .filter(([key, value]) => value)
      .map(([key]) => key);
    return result.length > 0;
  }).required('Please choose a loan taker'),
});
