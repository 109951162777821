import { useState, useEffect, useContext } from "react";
import { UserContext } from "./useWhoAmI";
import { getAccountingFiles, deleteAccountFile } from "../api"
import { Toast } from "../components";
import { ToastType } from "../types";
import { useFileListFormat } from "../utils";
import { setExpandedAccordion, StorageKeys } from "../common/localstorage";

export const useAccountingFiles = ({ uploadComplete }) => {
  const { updateWhoAmI } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const { getFileListFormat } = useFileListFormat();
  const [accountingFiles, setAccountingFiles] = useState([]);


  // TODO: error handling
  const getFiles = (deletingFile = false) => {
    setIsLoading(true);
    getAccountingFiles()
      .then((data) => {
        const files = getFileListFormat(data);

        setAccountingFiles(files);
        if (deletingFile && files.length === 0) {
          setExpandedAccordion(StorageKeys.ACCOUNTING);
          
          updateWhoAmI()
        }
        return files;
      })
      .finally(() => setIsLoading(false));
  }

  const deleteAccountFileById = async (file) => {
    deleteAccountFile(file.id)
      .then(() => getFiles(true))
      .then(() => {
        Toast(
          {
            type: ToastType.SUCCESS,
            title: 'Yes, success!',
            text: `${file.name} has been deleted`,
          }
          )
        })
      .catch((err) => console.log('Error: ', err))
  }

  
  useEffect(() => {
    getFiles()
    return () => setAccountingFiles([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadComplete])

  return {
    accountingFiles,
    deleteAccountFileById,
    isLoading,
  }
}