import { useState, useEffect, createContext } from "react";
import { getConfiguration } from "../api";
import { setConfigCookie, getConfigCookie } from "../common/cookie";
export const ConfigContext = createContext(null);

const Config = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState(null);

  useEffect(() => {
    async function doStuff() {
      try {
        // TODO: Save in localstorage -> if there dont request if from the backend.
        let config = getConfigCookie();
        if (!config) {
          config = await getConfiguration();
          setConfigCookie(config);
        }
        setConfig(config);
        setLoading(false);
      } catch (ex) {
        setLoading(false);
      }
    }
    doStuff();
  }, []);

  return (
    <ConfigContext.Provider
      value={{
        config,
        loading,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export default Config;
