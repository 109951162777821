import { useContext } from "react";
import { Icon } from "@iconify/react";
import { InfoModal } from "../../../../../components";
import { useMailToSupport } from "../../../../../utils";
import { ConfigContext } from "../../../../../hooks/useConfig";

export const HelpModal = ({ hide, visible }) => {
  const { mailToSupport } = useMailToSupport();
  const { config } = useContext(ConfigContext);

  return (
    <InfoModal
      hide={hide}
      visible={visible}
      title="What do you need help with?"
      closeText="Close"
      content={
        <div className="md:w-[562px] flex flex-col gap-6 mb-8">
          <div>
            <p className="font-bold">Technical and data related questions</p>
            <p>
              {" "}
              If you are having technical issues, questions regarding a certain
              feature or what type of data to provide.
            </p>
            <button
              onClick={mailToSupport}
              className="mt-4 flex flex-row items-center gap-2 bg-white text-float-purple-2 font-bold border-float-grey-25"
            >
              <Icon icon="tabler:messages" width={21} />
              Contact technical support
            </button>
          </div>
          <div>
            <p className="font-bold">Business related questions</p>
            <p>
              {" "}
              If you have general support inquiries around billing, pricing and
              strategy.
            </p>
            <button
              onClick={() => window.open(config.schedule_meeting_link)}
              className="mt-4 flex flex-row items-center gap-2 bg-white text-float-purple-2 font-bold border-float-grey-25"
            >
              <Icon icon="charm:phone-incoming" width={20} />
              Book a call with client services
            </button>
          </div>
        </div>
      }
    />
  );
};
