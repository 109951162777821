import { Icon } from "@iconify/react";

const Info = ({ value }) => {
  return (
    <div className="tooltip">
      <Icon icon="ant-design:info-circle-outlined" width={15} />
      <span className="text">{value}</span>
    </div>
  );
};

export default Info;
