import { doGet, doDelete, doPost, doPatch } from "../common/axiosUtil";

export const getBankConnectionsList = async () => {
  const get = await doGet("v1/ais/connections/");
  return get;
};

export const getTransactionFiles = async () => {
  const get = await doGet("v1/ais/file/");
  return get;
};

export const deleteTransactionFile = async (id) => {
  const removed = await doDelete("v1/ais/file", id);
  return removed;
};

export const postCommentTransaction = async (data) => {
  const post = await doPost("v1/ais/comment/", data);
  return post;
};

export const postAccount = async (data) => {
  const post = await doPost("v1/ais/accounts/", data);
  return post;
};

export const patchAccount = async (account_id, data) => {
  const patch = await doPatch(`v1/ais/accounts/${account_id}/`, data);
  return patch;
};

export const getAccount = async () => {
  const get = await doGet("v1/ais/accounts/");
  return get;
};

export const getAspsp = async (country, provider) => {
  const get = await doGet(
    `v1/ais/aspsp/?country=${country}&provider=${provider}`
  );
  return get;
};

export const postRequisition = async (data) => {
  const post = await doPost("v1/ais/requisition/", data);
  return post;
};

export const getRequisitions = async () => {
  const get = await doGet("v1/ais/requisition/");
  return get;
};

export const getRequisition = async (requisition_id) => {
  const get = await doGet(`v1/ais/requisition/${requisition_id}/`);
  return get;
};

export const postAISSync = async (data) => {
  const post = await doPost("v1/ais/sync/", data);
  return post;
};
