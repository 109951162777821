import * as Yup from "yup";
import { useContext, useEffect } from "react";
import { useFormik } from "formik";
import { useState } from "react";
import Input from "../../../../../components/input";
import { UserContext } from "../../../../../hooks/useWhoAmI";
import { Toast } from "../../../../../components";
import { ToastType } from "../../../../../types";
import {
  postSubscriptionConnection,
  patchSubscriptionConnection,
  getSubscriptionConnection,
} from "../../../../../api";
import { ManualModal } from "../../../../../components";
import { ChargebeeLogo } from "../../../../../assets";

export const ConnectChargebeeModal = ({
  hide,
  step,
  setStep,
  subscriptionCallback,
}) => {
  const { me } = useContext(UserContext);
  const [submitError, setSubmitError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [connectionData, setConnectionData] = useState();

  const fetchSubsriptionData = async () => {
    const data = await getSubscriptionConnection();
    setConnectionData(data);
  };

  useEffect(() => {
    if (step === 10) {
      fetchSubsriptionData();
    }
  }, [step]);

  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
      site_name: connectionData?.site_name || "",
      api_key: connectionData?.api_key || "",
    },
    validationSchema: Yup.object({
      site_name: Yup.string().required("Sitename is required"),
      api_key: Yup.string().required("API key is required"),
    }),
    onSubmit: async (values) => {
      setSubmitError(null);
      try {
        const params = {
          ...values,
          company: me.selected_company.id,
        };

        let response;
        if (connectionData) {
          response = await patchSubscriptionConnection(
            connectionData.id,
            params
          );
        } else {
          response = await postSubscriptionConnection(params);
          fetchSubsriptionData();
        }

        if (!response?.connection_established) {
          Toast({
            type: ToastType.ERROR,
            title: "Connection failed!",
            text: `Failed to establish a connection to Chargebee!`,
          });
        } else {
          formik.resetForm();
          Toast({
            type: ToastType.SUCCESS,
            title: "Connection established!",
            text: `You successfully connected Chargebee!`,
          });
          setStep(0);
          subscriptionCallback();
        }
        setIsLoading(false);
      } catch (e) {
        setSubmitError(e?.response?.data.error);
      }
    },
  });
  return (
    <ManualModal
      hide={hide}
      visible={step === 10}
      title={
        <div className="flex flex-row">
          <h3 className="my-auto">Connect</h3>
          <img className="ml-4" alt="chargebee_logo" src={ChargebeeLogo} />
        </div>
      }
      closeText="Close"
      submitButton={
        <button
          disabled={isLoading}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Connect
        </button>
      }
      leftContent={
        <div>
          <form className="flex flex-col gap-4 mb-8" id="chargebee_form">
            <Input
              type="text"
              id="site_name"
              label="Sitename"
              placeholder="Fill in sitename"
              onChange={formik.handleChange}
              value={formik.values.site_name}
              error={formik.errors.site_name}
            />
            <Input
              type="text"
              id="api_key"
              label="API key"
              placeholder="Fill in API key"
              onChange={formik.handleChange}
              value={formik.values.api_key}
              error={formik.errors.api_key}
            />
            {submitError && (
              <p className="text-xs text-red-600">{submitError}</p>
            )}
          </form>
        </div>
      }
      rightContent={
        <div className="flex flex-col w-full">
          <h5 className="text-base font-semibold">What is my sitename?</h5>
          <ul className="list-disc list-outside font-medium text-gray-700 ml-5">
            <li>
              The sitename is your company specific part of your Chargebee URL
            </li>
            <li>
              Example: https://<b>sitename</b>.chargebee.com/
            </li>
          </ul>
          <h5 className="text-base font-semibold mt-5">
            How do I get the API key
          </h5>
          <ul className="list-disc list-outside font-medium text-gray-700 ml-5">
            <li>
              You can generate an API key in your Chargebee account under
              Settings → Configure Chargebee → API Keys
            </li>
            <li>
              Float needs a <b>Read-only</b> key for; <b>All data</b>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.chargebee.com/docs/2.0/api_keys.html#types-of-api-keys_read-only-key "
              >
                Instructions on how to create a key
              </a>
            </li>
          </ul>
        </div>
      }
    />
  );
};
