import * as React from "react";

function LogoSmall({ color = "white", width = 150, height = 50 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 63.9882H14.6045C14.6107 58.5372 16.7995 53.3113 20.6904 49.4572C24.5813 45.6031 29.8565 43.4356 35.3586 43.4304V28.9614C25.9854 28.9738 16.9997 32.668 10.3715 39.234C3.74333 45.8 0.0135759 54.702 0 63.9882Z"
        fill={color}
      />
      <path
        d="M0 35.0307H14.6045C14.6107 29.5793 16.7993 24.353 20.6901 20.4983C24.5809 16.6435 29.8562 14.4752 35.3586 14.469V0C25.9848 0.0124157 16.9984 3.70713 10.3701 10.274C3.74183 16.8408 0.0125319 25.7438 0 35.0307Z"
        fill={color}
      />
    </svg>
  );
}
export default LogoSmall;
