import { useContext } from "react";
import { UserContext } from "../../../../../hooks/useWhoAmI";
import { connectBankLater } from "../../../../../api";
import { Toast } from "../../../../../components";
import { ToastType } from "../../../../../types";

export const useConnectBankLater = () => {
  const { updateWhoAmI, loading } = useContext(UserContext);

  const connectLater = () => {
    connectBankLater(true)
      .then(() => updateWhoAmI())
      .then(() => Toast(
        {
          type: ToastType.INFO,
          title: 'You will need to connect your bank before taking out a loan',
          text: 'You cannot connect your bank(s) once you have recieved your credit offer.',
        }
      )).catch()
  };

  return {
    loading,
    connectLater,
  }
}