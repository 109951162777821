import { Redirect, withRouter } from "react-router";
import { useContext } from "react";
import { UserContext } from "../../hooks/useWhoAmI";
import Loading from "../../components/loading";
import Onboard from "../dashboard/onboard";
import useAPI from "../../hooks/useAPI";

const Connect = () => {
  const { me } = useContext(UserContext);
  const [result, loading, error] = useAPI({ me, path: "v1/credit_offer/" });

  if (loading) {
    return (
      <div className="bg-float-purple-6 w-screen min-h-screen max-h-full flex items-center justify-center">
        <Loading />
      </div>
    );
  }
  if (error) {
    return <p>An error occurred, please try again later.</p>;
  }

  if (result.length === 0) {
    return <Redirect to="/dashboard" />;
  }

  return <Onboard edit={true} />;
};

export default withRouter(Connect);
