import { getCompanyDetails } from "../../../../../../../common/localstorage";

export const useGetInitialValues = ({ isSwedish, companies }) => {
  const details = getCompanyDetails();
  const savedDetails = details ? JSON.parse(details) : {};

  if (isSwedish) {
    const initialValues = {
      company: companies?.length === 1 ? companies[0].value : "",
      business_model: "",
      annual_recurring_revenue: "",
      annual_growth: "",
      ...savedDetails,
    };
    return {
      initialValues,
    };
  } else {
    const initialValues = {
      company: "",
      business_model: "",
      annual_recurring_revenue: "",
      annual_growth: "",
      organisation_number: "",
      ...savedDetails,
    };
    return {
      initialValues,
    };
  }
};
