import { useContext } from "react";
import { Redirect } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { UserContext } from "../hooks/useWhoAmI";
import Loading from "../components/loading";

const InnerRouter = () => {
  const { me, loading } = useContext(UserContext);
  if (loading) {
    return <Loading />;
  }
  if (!me?.is_staff) {
    return <Redirect to="/dashboard" />;
  }
  return "";
};

const AdminRoute = ({ children, ...rest }) => {
  return (
    <PrivateRoute {...rest}>
      {children}
      <InnerRouter />
    </PrivateRoute>
  );
};

export default AdminRoute;
