import { useGoogleLogin } from "@react-oauth/google";
import { useState, useContext } from "react";
import { Redirect } from "react-router-dom";
import GoogleLogo from "../../../../components/logoGoogle";
import { googleLogin } from "../../../../api/";
import { Button } from "../../../../components";
import { Toast } from "../../../../components/toast";
import { ToastType } from "../../../../types";
import { UserContext } from "../../../../hooks/useWhoAmI";

export const SignUpGoogleSSO = () => {
  const [ssoComplete, setSsoComplete] = useState(false);
  const { login: loginContext, me } = useContext(UserContext);

  const login = useGoogleLogin({
    onSuccess: async (googleData) => {
      try {
        // const accessToken = googleData?.access_token;
        // const userInfo = await googleUserInfo(accessToken);
        const response = await googleLogin(googleData);
        loginContext(response);
        setSsoComplete(true);
      } catch (err) {
        console.error(err);
        Toast({
          type: ToastType.ERROR,
          title: "Failed to signup!",
          text: "Authentication with google - try again or use email/password!",
        });
      }
    },
    onError: (response) => console.error(response),
  });
  if (ssoComplete && me) {
    return (
      <Redirect
        to={{
          pathname: "/signup/manual",
          state: { step: 0, email: me.email, isActiveUser: true },
        }}
      />
    );
  }
  return (
    <>
      <Button onClick={() => login()} className="w-full mt-8">
        <div className="flex flex-row">
          <GoogleLogo width={30} height={30} />
          <p className="my-auto mx-auto">Continue with Google</p>
        </div>
      </Button>
    </>
  );
};
