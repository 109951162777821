export const InfoModal = ({
  visible,
  hide,
  title,
  submitButton,
  content,
  closeText,
}) => {
  return (
    <div
      id="modal"
      aria-hidden="true"
      className={`${visible ? "visible" : "hidden"}
      overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-screen md:inset-0 h-screen md:h-full flex items-center justify-center bg-black bg-opacity-50`}
    >
      <div className={`relative p-4 h-3/4 w-fit max-w-4xl mb-10`}>
        <div className="relative bg-white rounded-xl shadow-xl .box-shadow">
          <div className="flex justify-between items-start p-6 relative border-b">
            <h3 className="font-bold">{title}</h3>
            <div
              className="text-gray-400 bg-transparent rounded-lg text-sm items-center cursor-pointer absolute right-5 height-inherit"
              onClick={hide}
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row p-6 height-inherit">
            {content}
          </div>
          {(closeText || submitButton) && (
            <div className="bg-float-grey-5 rounded-b-xl py-4 px-6 flex justify-end gap-2">
              {closeText && (
                <button
                  onClick={hide}
                  className="bg-transparent text-float-purple-2 my-auto cursor-pointer border-transparent font-bold hover:border-transparent hover:bg-float-purple-5"
                >
                  {closeText}
                </button>
              )}
              {submitButton}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
