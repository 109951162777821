import { Icon } from "@iconify/react"

const Info = ({ icon, title, text }) => {
  return (
    <div className="flex flex-row gap-4">
      <div>
        {icon}
      </div>
      <div className="flex flex-col gap-1 text-float-grey-100 max-w-[280px]">
        <h3 className="text-sm lg:text-base font-bold leading-5">{title}</h3>
        <p className="text-sm leading-5">{text}</p>
      </div>
    </div>
  )
}

export const SideBar = () => {
  return (
    <div className="flex flex-col gap-8 pb-20">
      <Info 
        icon={
        <div className="bg-float-purple-5 h-[40px] rounded-full flex items-center justify-center w-[40px]">
          <Icon 
            icon="mdi:shield-check-outline"
            width={22}
            className="text-float-purple-2"
          />
        </div>
        }
        title="We keep your data secure"
        text="We only use your information to assess your credit rating. All data you share is protected by our strict non-disclosure policy."
      />
      <Info 
      icon={
        <div className="bg-float-purple-5 h-[40px] rounded-full flex items-center justify-center w-[40px]">
          <Icon 
            icon="game-icons:european-flag"
            width={32}
            className="text-float-purple-2"
          />
        </div>
        }
        title="Servers in the EU"
        text="Your connected data is safely stored on servers in Sweden and in Germany."
      />
      <Info 
            icon={
              <div className="bg-float-purple-5 h-[40px] rounded-full flex items-center justify-center w-[40px]">
                <Icon 
                  icon="emojione:flag-for-sweden"
                  width={28}
                  className="text-float-purple-2"
                />
              </div>
              }
        title="Made in Sweden"
        text="Member of Swedish Fintech Assoc. and registered with the Swedish Financial Supervisory Authority."
      />
    </div>
  )
}