import Logo from "../components/logo"
import Image from '../assets/images/secure_pattern.png'


export const SecurePatternLayout = ({ children }) => {
  return (
    <div className="w-full h-screen bg-float-purple-6 flex items-center justify-center">
      <div className="absolute top-14 left-20 h-64">
          <Logo 
            color="#450B70"
            height={34}
          />
      </div>
      {Image && 
          <img
            className="absolute bottom-0 right-0 md:h-[500px] w-2/5"
            src={Image}
            alt="secure_pattern" />
        }
        {
          children
        }
    </div>
  )
}