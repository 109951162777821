import { getCompanyDetails } from "../../../../../../../common/localstorage"

export const useGetInitialValues = () => {
  const details = getCompanyDetails();
  const savedDetails = details ? JSON.parse(details) : {};

    const  initialValues = {
        name: '',
        business_model: '',
        annual_recurring_revenue: '',
        annual_growth: '',
        organisation_number: '',
        ...savedDetails,
    }
    return { 
      initialValues,
    }
}