import { InfoModal } from "../infoModal"
import Input from "../input";
import { useInviteUserForm } from "../../hooks/useInviteUserForm";

export const AddUserModal = ({ hide, visible, setOpenAddUserModal }) => {
  const { formik, submitErrors, isLoading } = useInviteUserForm({ setOpenAddUserModal });

  return (
    <InfoModal 
      hide={hide}
      visible={visible}
      title="Add a user"
      closeText="Cancel"
      submitButton={<button disabled={isLoading} form="addUserForm" type="submit" onClick={formik.handleSubmit}>Add a user</button>}
      content={
        <form className="w-[300px] md:w-[514px] flex flex-col gap-4 mb-8" id="addUserForm">
          <Input
            type="text"
            id="first_name"
            label="First name"
            placeholder="Fill in first name"
            onChange={formik.handleChange}
            value={formik.values.first_name}
            error={formik.errors.first_name}
          />
          <Input
            id="last_name"
            label="Last name"
            placeholder="Fill in last name"
            type="text"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            error={formik.errors.last_name}
          />
          <Input
            id="email"
            label="Email - company address"
            placeholder="Fill in email"
            type="text"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.errors.email}
          />
          {
            submitErrors && submitErrors.map((error) => <p key={error} className="text-xs text-red-600">{error}</p>)
          }
        </form>
      }
    />
  )
}