import { useState, useEffect, createContext } from "react";
import { getCountries } from "../api";
import { getCountiresCookie, setCountriesCookie } from "../common/cookie";
export const CountryContext = createContext(null);

const Country = ({ children }) => {
  const [countries, setCountries] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [country, setCountry] = useState(null);

  useEffect(() => {
    async function doStuff() {
      try {
        // TODO: Save in localstorage -> if there dont request if from the backend.
        let _countries = getCountiresCookie();
        if (!_countries) {
          _countries = await getCountries();
          setCountriesCookie(_countries);
        }
        const sorted = _countries.sort((a, b) => a.name.localeCompare(b.name));
        setCountries(sorted);
        setLoading(false);
      } catch (ex) {
        setLoading(false);
        setError(ex);
      }
    }
    doStuff();
  }, []);

  return (
    <CountryContext.Provider
      value={{
        error,
        countries,
        loading,
        setCountry,
        country,
      }}
    >
      {children}
    </CountryContext.Provider>
  );
};

export default Country;
