import { Icon } from "@iconify/react";
import Loading from "../loading";
import { useGetFormatBytes } from "../../utils";

export const FileList = ({ files, onHandleRemove, isLoading }) => {
  const { formatBytes } = useGetFormatBytes();

  if (!files || files.length === 0) {
    return null;
  }

  if (isLoading) {
    return (
      <div className="flex flex-row gap-4 pt-3 items-center">
        <Loading height={24} width={24} />
        <p className="text-sm italic">Loading files</p>
      </div>
    );
  }

  return (
    <ul>
      {files.map((file, index) => (
        <li
          key={`${file.name}+${index}`}
          className="grid grid-cols-[40px_minmax(160px,_1fr)_40px] gap-3 border-b border-float-grey-10 last:border-none py-3"
        >
          <div className="bg-float-purple-6 px-2 rounded-md h-10 flex flex-wrap content-center">
            <Icon icon="mi:document-check" width={24} color="#450B70" />
          </div>
          <div className="grow">
            <p
              className="cursor-pointer text-float-purple-2 font-medium text-sm leading-6 truncate"
              onClick={() => window.open(file.file)}
            >
              {file.name}
            </p>
            <p className="text-xs font-normal float-black-90 leading-5">
              {formatBytes(file.size)}
            </p>
          </div>
          {
            <div className="flex items-center justify-end">
              <Icon
                className="cursor-pointer"
                icon="mdi:cancel-box-outline"
                width={20}
                color="#959595"
                onClick={() => onHandleRemove(file)}
              />
            </div>
          }
        </li>
      ))}
    </ul>
  );
};
