import * as Yup from "yup";
import { COMPANY_LOOKUP } from "../../../../../../../common/constants";

export const useGetValidationSchema = ({ country }) => {
  const organisationNumberValidator = () => {
    if (country === "other") {
      return Yup.string()
        .required("Organization number is required")
        .matches(
          COMPANY_LOOKUP[country].regex,
          `Needs to be at least ${COMPANY_LOOKUP[country].placeholder.length} characters.`
        );
    } else {
      return Yup.string()
        .length(
          COMPANY_LOOKUP[country].length,
          `Must be ${COMPANY_LOOKUP[country].placeholder.length} characters long.`
        )
        .required("Organization number is required")
        .matches(
          COMPANY_LOOKUP[country].regex,
          `Needs to be ${COMPANY_LOOKUP[country].placeholder.length} characters.`
        );
    }
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Please enter company name"),
    organisation_number: organisationNumberValidator(),
    business_model: Yup.string().required("Please choose a business model"),
    annual_recurring_revenue: Yup.number().required(
      "Please choose your latest ARR"
    ),
    annual_growth: Yup.string().required('Please enter your annual growth')
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(1, 'Must be at least one digit')
      .max(4, 'Cannot be larger than 4 digits'),
  });

  return {
    validationSchema,
  };
};
