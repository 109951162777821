// import { Icon } from "@iconify/react"
import { useDeleteUser } from "../../hooks";
import { Avatar } from "../../../../components";
import { DeleteUserModal } from "../DeleteUserModal";
import { inviteNotify } from "../../../../api";
import { ToastType } from "../../../../types";
import { Toast } from "../../../../components/toast";

export const UserRow = ({ user }) => {
  const { showDeleteUserModal, setShowDeleteUserModal, deleteUser } =
    useDeleteUser();

  // TODO: handle delete for users with correct rights

  const handleReinvite = (email) => {
    try {
      inviteNotify({ email: email });
      Toast({
        type: ToastType.SUCCESS,
        title: "Success",
        text: "Invitiation successfully sent!",
      });
    } catch (e) {
      Toast({
        type: ToastType.ERROR,
        title: "Oh no, there was an error",
        text: e.message,
      });
    }
  };
  return (
    <div className="border-t grid grid-cols-[minmax(220px,_1fr)_minmax(250px,_1fr)_minmax(150px,_0.3fr)_minmax(80px,_1fr)] py-4">
      <div className="flex items-center gap-4">
        <Avatar size={16} />
        <p className="text-sm font-bold">
          {user.first_name} {user.last_name}
        </p>
      </div>
      <div className="flex items-center">
        <p className="text-sm">{user.email}</p>
      </div>
      <div className="flex items-center">
        <p className="text-sm">{user.phone_number}</p>
      </div>
      <div className="flex items-center justify-end">
        {!user.active && (
          <button
            className="w-fit text-xs whitespace-nowrap cursor-pointer"
            onClick={() => {
              handleReinvite(user.email);
            }}
          >
            Resend invitation
          </button>
        )}
      </div>
      <DeleteUserModal
        visible={showDeleteUserModal}
        hide={() => setShowDeleteUserModal(false)}
        onDelete={() => deleteUser(user)}
        user={user}
      />
    </div>
  );
};
