export const UploadTransactionInformation = () => {
  return (
    <div className="flex flex-col w-full">
      <h5 className="text-base font-semibold">Information needed:</h5>
      <ul className="list-disc list-outside font-medium text-gray-700 ml-5">
        <li>
          <u>All</u> bank transactions for the past 12 months, from <u>all</u>{" "}
          of the company's bank accounts.
        </li>
        <li>Not older than 15 days</li>
        <li>In Excel format (.xls(x) or .csv)</li>
      </ul>
    </div>
  );
};
