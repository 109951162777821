export const useGetErrorMessage = () => {
  const errorMessages = (data) => {
    if (Array.isArray(data)) {
      return {
        other: data[0]
      };
    }

    const keys = Object.keys(data);
    const error = keys.reduce((acc, curr, i) => {
      return {
        ...acc,
        [curr]: data[curr][0]
      }
    }, {})
    return error;
  };

  return {
    errorMessages
  }
};
