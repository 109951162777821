import { useContext } from "react";
import { useFormik } from "formik";
import { validationSchema } from "./validation";
import { UserContext } from "../../../../../hooks/useWhoAmI";
import { postFeedback } from "../../../../../api";
import { Toast } from "../../../../../components";
import { ToastType } from "../../../../../types";

export const useFeedbackForm = ({ currentSmiley, hide }) => {
  const { me } = useContext(UserContext);

  const onSubmit = async (data) => {
    const values = {
      ...data,
      company: me.selected_company.id,
    }
    try {
      await postFeedback(values);
      hide();
      Toast({
        type: ToastType.SUCCESS,
        title: 'Thank you for your feedback!',
        text: "Your feedback is immensely valuable to us.",
      })
    } catch (e) {
      hide()
      Toast({
        type: ToastType.ERROR,
        title: 'Error!',
        text: 'Oh no, something went wrong'
      })
    }
  }

  const formik = useFormik({
    validateOnChange: false,
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      text: '',
      rating: currentSmiley,
      discovered_by: null,
      discovered_by_text: '',
    },
    onSubmit,
  });

  return {
    formik,
  }
}