import Logo from "../components/logo";

const TwoColContent = ({ leftContent, rightContent, logoColor = "#1C003F" }) => (
  <div className="flex sm:flex-row w-full min-h-screen max-h-full">
    <div className="flex flex-1 justify-between flex-col bg-float-purple-6 pt-14 px-6 md:px-10 lg:px-14 pb-18 max-w-md">
      <div className="h-64">
          <Logo 
            color={logoColor} 
          />
        </div>
      {leftContent}
    </div>
    <div className="flex-1">{rightContent}</div>
  </div>
);

export default TwoColContent;
