import { Layout } from "../../templates/Layout";
import { AddUserModal } from "../../components";
import { UserTable } from "./components";
import { useState } from "react";

const UserPage = () => {
  const [openAddUserModal, setOpenAddUserModal] = useState(false);

  return (
    <Layout>
      <h2 className="">Users</h2>

      <div className="relative h-full">
        <div className="flex flex-row mb-8">
          <p className="my-auto">
            Here you can add new users and manage existing ones in your company.
          </p>
          <button
            className="top-[164px] right-4 sm:right-10 w-fit ml-auto"
            onClick={() => setOpenAddUserModal(true)}
          >
            Add user
          </button>
        </div>
        <UserTable />
      </div>
      <AddUserModal
        hide={() => setOpenAddUserModal(false)}
        visible={openAddUserModal}
        setOpenAddUserModal={setOpenAddUserModal}
      />
    </Layout>
  );
};

export default UserPage;
