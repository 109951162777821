export const START_TIMESPAN = [
  {
    value: "one_month",
    label: "One month",
  },
  {
    value: "three_months",
    label: "Three months",
  },
  {
    value: "six_months",
    label: "Six months",
  },
  {
    value: "twelve_months",
    label: "Twelve months",
  },
  {
    value: "later",
    label: "Later",
  },
];

export const CURRENCY_ARRAY = [
  {
    value: 'SEK',
    label: 'SEK',
  },  
  {
    value: 'NOK',
    label: 'NOK',
  },
  {
    value: 'EUR',
    label: 'EUR',
  },
  {
    value: 'DKK',
    label: 'DKK',
  },
  {
    value: 'CHF',
    label: 'CHF',
  },
];

export const LOAN_TAKER = [
  {
    value: 'ceo',
    label: 'CEO',
  },  
  {
    value: 'cfo',
    label: 'CFO',
  },
  {
    value: 'coo',
    label: 'COO',
  },
  {
    value: 'other_co_founder',
    label: 'Other co founder',
  },
  {
    value: 'board',
    label: 'Board',
  },
  {
    value: 'shareholders',
    label: 'Shareholders',
  },
  {
    value: 'other',
    label: 'Other',
  },
];