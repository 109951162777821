import { useYourDetailsForm } from "./hooks";
import Input from "../../../../../components/input";
import { Button } from "../../../../../components";
import { useContext } from "react";
import { UserContext } from "../../../../../hooks/useWhoAmI";

export const YourDetails = ({ nextPage }) => {
  const { me } = useContext(UserContext);
  const { formik, loadingSubmit } = useYourDetailsForm({ nextPage, me });

  return (
    <div className="flex flex-col gap-2 mt-24 mx-auto w-[560px] px-6">
      <h2 className="text-float-grey-100">Your details</h2>
      <div>
        <h3 className="text-float-grey-90 text-base	font-normal">
          Information from BankID is prefilled to make your life easier.
        </h3>
      </div>
      <form
        className="mt-6 w-full flex flex-col gap-3"
        onSubmit={formik.handleSubmit}
      >
        <Input
          type="text"
          label="First name"
          id="first_name"
          value={formik.values.first_name}
          onChange={formik.handleChange}
          error={formik.errors.first_name}
          placeholder="Enter first name"
        />
        <Input
          type="text"
          label="Last name"
          id="last_name"
          value={formik.values.last_name}
          onChange={formik.handleChange}
          error={formik.errors.last_name}
          placeholder="Enter last name"
        />
        <Input
          type="text"
          label="E-mail"
          id="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.errors.email}
          placeholder="Enter company email"
        />
        <Input
          type="text"
          label="Phone number"
          id="phone_number"
          value={formik.values.phone_number}
          onChange={formik.handleChange}
          error={formik.errors.phone_number}
          placeholder="Enter phone number"
        />
        <Button type="submit" className="mt-11 w-full" loading={loadingSubmit}>
          Continue
        </Button>
      </form>
    </div>
  );
};
