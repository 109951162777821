const useLoanChart = (formatInput, formik) => {
  const getMonthlyRepayment = () =>
    formik.isValid
      ? formatInput(
          formik.values.amount /
            (formik.values.term - formik.values.grace_period)
        )
      : "-";

  const setLoanChartValues = (
    loanChart,
    setTermsOfferArray,
    interest,
    pay_out_date
  ) => {
    const {
      setRepayment,
      setLabels,
      setPrinciple,
      setInterest,
      setOutstandingPrinciple,
      setTableInfo,
      setHideGraph,
    } = loanChart;
    if (formik.errors.amount) {
      return setHideGraph(true);
    }

    setHideGraph(false);

    const entries = Object.entries(interest);
    const termsInterestOffer = entries.filter(
      (entry) => !Object.values(entry[1]).every((x) => x === null)
    );

    setTermsOfferArray(termsInterestOffer);
    const repayment = getMonthlyRepayment();
    const date = pay_out_date;

    const startingMonth = date.toLocaleDateString("en-EN", { month: "short" });
    const labels = [startingMonth];
    const amount = parseInt(formik.values.amount);
    const roundedPaymentMonthly = parseInt(repayment.replaceAll(" ", ""));
    const repaymentArray = Array(
      parseInt(formik.values.term - formik.values.grace_period)
    ).fill(-roundedPaymentMonthly);

    const interestRate = (
      interest[formik.values.term][formik.values.grace_period] || 0
    ).toFixed(2);

    const currentInterest = ((amount * interestRate) / 100).toFixed(0);
    const getDefaultOutstandingPrinciple = () => {
      if (formik.values.grace_period === "0") {
        return [];
      } else if (formik.values.grace_period === "3") {
        return [amount, amount, amount];
      } else if (formik.values.grace_period === "6") {
        return [amount, amount, amount, amount, amount, amount];
      }
    };
    const outstandingPrinciple = getDefaultOutstandingPrinciple();

    for (
      let i = 0;
      i < termsInterestOffer[termsInterestOffer.length - 1][0];
      i++
    ) {
      const nextDate = new Date(date.setMonth(date.getMonth() + 1));
      const month = nextDate.toLocaleDateString("en-EN", { month: "short" });
      labels.push(month);
    }

    for (let i = 0; i < repaymentArray.length + 1; i++) {
      if (i === repaymentArray.length) {
        outstandingPrinciple.push(0);
      } else {
        outstandingPrinciple.push(amount - i * roundedPaymentMonthly);
      }
    }

    for (let i = 0; i <= formik.values.grace_period; i++) {
      repaymentArray.unshift(0);
    }

    setRepayment(repaymentArray);
    setLabels(labels);
    setPrinciple([amount]);
    setInterest([-currentInterest]);
    setOutstandingPrinciple(outstandingPrinciple);
    setTableInfo({
      grace: formik.values.grace_period,
      interest: formatInput(currentInterest),
      months: parseInt(formik.values.term),
      repayment: formatInput(roundedPaymentMonthly),
      interestRate,
      amount: formatInput(formik.values.amount),
    });
  };

  return {
    setLoanChartValues,
  };
};

export default useLoanChart;
