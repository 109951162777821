const GoogleLogo = ({ width = 46, height = 46 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32.936 23.2342C32.936 22.5003 32.8701 21.7946 32.7478 21.1172H23V25.1208H28.5702C28.3302 26.4145 27.601 27.5107 26.5049 28.2446V30.8415H29.8498C31.8069 29.0396 32.936 26.3863 32.936 23.2342Z"
        fill="#4285F4"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23 33.3483C25.7945 33.3483 28.1374 32.4215 29.8498 30.8408L26.5049 28.2439C25.5781 28.8649 24.3925 29.2319 23 29.2319C20.3043 29.2319 18.0226 27.4112 17.2087 24.9648H13.7509V27.6464C15.4539 31.029 18.9541 33.3483 23 33.3483Z"
        fill="#34A853"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.2087 24.9662C17.0017 24.3452 16.8841 23.6818 16.8841 22.9997C16.8841 22.3175 17.0017 21.6542 17.2087 21.0332V18.3516H13.7509C13.0499 19.7488 12.65 21.3295 12.65 22.9997C12.65 24.6698 13.0499 26.2505 13.7509 27.6477L17.2087 24.9662Z"
        fill="#FBBC05"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23 16.7649C24.5196 16.7649 25.8839 17.2871 26.9565 18.3127L29.9251 15.3441C28.1326 13.674 25.7898 12.6484 23 12.6484C18.9541 12.6484 15.4539 14.9678 13.7509 18.3503L17.2087 21.0319C18.0226 18.5856 20.3043 16.7649 23 16.7649Z"
        fill="#EA4335"
      />
    </svg>
  );
};

export default GoogleLogo;
