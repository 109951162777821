import {
  postCompanySubscription,
  postCommentSubscription,
} from "../../../../../api";
import {
  FileList,
  FileUploaderStateless,
  InfoModal,
  ManualModal,
  TextArea,
  UploadButton,
} from "../../../../../components";
import { SubmitButton } from "../submitButton";
import { SupportedSubscriptions } from "../supportedSubscription";
import { UploadSubscriptionInformation } from "../uploadSubscriptionInformation";
import { ConnectChargebeeModal } from "../connectChargebeeModal";
import { ConnectButton } from "../../../../../components/connectButton";

export const SubscriptionModals = ({
  hide,
  step,
  setStep,
  files,
  setFile,
  uploadedFilesLength,
  onHandleRemove,
  uploadFilesAndComment,
  text,
  setText,
  fileUploadError,
  loading,
  setFileUploadError,
  subscriptionCallback,
}) => {
  return (
    <>
      <ManualModal
        hide={hide}
        visible={step === 4}
        title={"Manual upload of subscription info"}
        rightContent={<UploadSubscriptionInformation />}
        leftContent={
          <div className="flex flex-col gap-3">
            <FileUploaderStateless
              files={files}
              setFile={setFile}
              uploadedFilesLength={uploadedFilesLength}
              onHandleRemove={onHandleRemove}
              error={fileUploadError}
              setFileUploadError={setFileUploadError}
            />
            <FileList files={files} onHandleRemove={onHandleRemove} />
            <div className="border-t pt-4">
              <TextArea
                id="subscriptionComment"
                value={text}
                onChange={setText}
                label="Additional comments on your uploads"
                placeholder="Write your comments here..."
              />
            </div>
          </div>
        }
        submitButton={
          <SubmitButton
            loading={loading}
            files={files}
            onHandleSubmit={(e) =>
              uploadFilesAndComment({
                e,
                fileEndpoint: postCompanySubscription,
                commentEndpoint: postCommentSubscription,
              })
            }
          />
        }
      />
      <InfoModal
        hide={hide}
        visible={step === 5}
        title="Supported subscription services"
        closeText="Close"
        content={<SupportedSubscriptions />}
        submitButton={
          <ConnectButton
            text={"Connect Chargebee"}
            onClick={() => {
              setStep(10);
            }}
          />
        }
      />
      <ConnectChargebeeModal
        hide={hide}
        step={step}
        setStep={setStep}
        subscriptionCallback={subscriptionCallback}
      />
      <InfoModal
        hide={hide}
        visible={step === 6}
        title="Manual upload of subscription data"
        closeText="Cancel"
        content={
          <div className="max-w-xl">
            <UploadSubscriptionInformation />
          </div>
        }
        submitButton={<UploadButton onClick={() => setStep(4)} />}
      />
    </>
  );
};
