import Swedbank from './logos/swedbank_logo.png';
import SEB from './logos/SEB_logo.png';
import Nordea from './logos/nordea_logo.png';
import Handelsbanken from './logos/handelsbanken_logo.png';
import DanskeBank from './logos/danskebank_logo.png';
import Lansforsakringar from './logos/lansforsakringar_logo.png';
import CreditSuisse from './logos/credit_suisse_logo.png';
import UBS from './logos/UBS_logo.png';
import Citi from './logos/citi_logo.png';
import DeutscheBank from './logos/deutsche_bank_logo.png';
import Citadele from './logos/citadele_logo.png';
import Revolut from './logos/revolut_logo.png';
import DNB from './logos/dnb_logo.png';
import AbnAmro from './logos/abn_amro_logo.png';
import CaisseDepargne from './logos/caisse-depargne_logo.png';

export const useGetBanks = () => {
  const banks = [
    {
      name: 'Swedbank',
      logo: Swedbank,
    },
    {
      name: 'SEB',
      logo: SEB,
    },
    {
      name: 'Nordea',
      logo: Nordea,
    },
    {
      name: 'Handelsbanken',
      logo: Handelsbanken,
    },    
    {
      name: 'DanskeBank',
      logo: DanskeBank,
    },
    {
      name: 'Lansforsakringar',
      logo: Lansforsakringar,
    },
    {
      name: 'CreditSuisse',
      logo: CreditSuisse,
    },
    {
      name: 'UBS',
      logo: UBS,
    },
    {
      name: 'Citi',
      logo: Citi,
    },
    {
      name: 'DeutscheBank',
      logo: DeutscheBank,
    },
    {
      name: 'Citadele',
      logo: Citadele,
    },
    {
      name: 'Revolut',
      logo: Revolut,
    },
    {
      name: 'DNB',
      logo: DNB,
    },
    {
      name: 'AbnAmro',
      logo: AbnAmro,
    },
    {
      name: 'CaisseDepargne',
      logo: CaisseDepargne,
    },
  ]
  
  return {
    banks,
  }
}