import { useGetBanks } from "./useGetBanks"

export const SupportedBanksInfo = () => {
  const { banks } = useGetBanks();

  return (
    <div className="flex flex-col md:flex-row gap-6">
      <div className="md:w-1/3">
        <h5 className="font-bold">What data do we use?</h5>
        <p className="text-sm mt-1">
          Balance sheet for last year and P&L: Latest available income statement, 18+ months history with a monthly break-down.
        </p>
        <p className="text-float-purple-2 text-sm mt-4 cursor-pointer">Read about PSD2</p>
      </div>
      <div className="max-h-96 md:w-2/3 flex flex-col gap-5 mb-10">
        <div className="border rounded-xl p-7 grid grid-cols-3 gap-8">
          {
            banks.map(({ logo, name }) => <img key={name} src={logo} alt={name} className="h-fit m-auto" />)
          }
        </div>
        <a
          className="text-sm"
          href="https://portal.openbanking.klarna.com/bank-matrix" 
          target="_blank" 
          rel="noreferrer"
        >
          View all supported banks on Klarna.com
        </a>
      </div>
    </div>
  )
}