import { useState } from "react";
import TwoColContent from "../../../templates/twoColContent";
import { SideBar } from "../components";
import { YourDetails, CompanyDetails } from "./components";
import useQuery from "../../../hooks/useQuery";

export const ManualSignUp = ({ location }) => {
  const search = useQuery();
  const step = search.get("step") ? parseInt(search.get("step")) : null;
  const [currentStep, setCurrentStep] = useState(
    step || location?.state?.step || 0
  );
  const [isActiveUser] = useState(
    search.get("isActiveUser") || location?.state?.isActiveUser || false
  );
  const [email] = useState(search.get("email") || location?.state?.email || "");

  const steps = [
    {
      step: 0,
      component: YourDetails,
    },
    {
      step: 1,
      component: CompanyDetails,
    },
  ];

  const RenderedComponent = steps[currentStep]?.component;
  const nextPage = () => setCurrentStep(currentStep + 1);
  const prevPage = () => setCurrentStep(currentStep - 1);

  return (
    <TwoColContent
      leftContent={<SideBar />}
      rightContent={
        <RenderedComponent
          nextPage={nextPage}
          prevPage={prevPage}
          email={email}
          isActiveUser={isActiveUser}
        />
      }
    />
  );
};
