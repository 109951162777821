import { doPost, doGet, doPatch } from "../common/axiosUtil";

export const postSubscriptionConnection = async (data) => {
  const post = await doPost("v1/subscription/connection/", data);
  return post;
};

export const getSubscriptionConnection = async () => {
  const data = await doGet("v1/subscription/connection/");
  return data;
};

export const patchSubscriptionConnection = async (id, data) => {
  const patch = await doPatch(`v1/subscription/connection/${id}/`, data);
  return patch;
};
