export const ManualModal = ({ visible, hide, title, submitButton, leftContent, rightContent }) => {

  return (
    <div
      id="modal"
      aria-hidden="true"
      className={`${ visible ? "visible" : "hidden"} 
      overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-screen md:inset-0 h-screen md:h-full flex items-center justify-center bg-black bg-opacity-50`}
    >
      <div className="relative px-20 lg:p-4 h-3/4 md:h-auto lg:w-3/4 max-w-[1200px]">
        <div className="relative bg-white rounded-xl box-shadow">
          <div className="flex justify-between items-start p-6 relative border-b">
            <h3 className="font-bold">{title}</h3>
            <div
              className="text-gray-400 bg-transparent rounded-lg text-sm items-center cursor-pointer absolute right-5"
              onClick={hide}
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row">
            <div className="lg:w-2/3 lg:border-r p-6">{leftContent}</div>
            <div className="lg:w-1/3 p-6">{rightContent}</div>
          </div>
          <div className="bg-float-grey-5 rounded-b-xl py-4 px-6 flex justify-end gap-2">
            <button onClick={hide} className="bg-transparent text-float-purple-2 my-auto cursor-pointer border-transparent font-bold hover:border-transparent hover:bg-float-purple-5">
              Cancel
            </button>
            {submitButton}
          </div>
        </div>
      </div>
    </div>
  );
};
