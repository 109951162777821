import { Icon } from "@iconify/react";
import { useContext } from "react";
import { ModalContext } from "../modal";

const Content = () => {
  const { hide } = useContext(ModalContext);

  const handleMail = () => {
    window.location.href = "mailto:support@gofloat.io";
    hide();
  };
  return (
    <div className="flex flex-col items-center space-y-6 pb-2">
      <p>
        Do you have any questions or ran into a problem? <br /> Send us an email
        by clicking the button below.
      </p>
      <button onClick={handleMail}>Email us</button>
    </div>
  );
};

const Help = ({ size = 32 }) => {
  const { setContent, setVisible, setTitle } = useContext(ModalContext);

  const handleQuestionModal = () => {
    setContent(<Content />);
    setTitle("Need help?");
    setVisible(true);
  };

  return (
    <Icon
      className="dashboard-icon"
      icon="akar-icons:chat-question"
      width={size}
      onClick={handleQuestionModal}
    />
  );
};

export default Help;
