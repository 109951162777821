import { Button, InfoModal } from "../../../../../components"
import { useInviteSignatoryForm } from "../../hooks"
import Input from "../../../../../components/input"

export const InviteSignatoryModal = ({ hide, visible }) => {
  const { formik } = useInviteSignatoryForm({ hide });
  const hideAndClear = () => {
    hide();
    formik.resetForm();
  }

  return (
    <InfoModal 
      visible={visible}
      hide={hideAndClear}
      title="Invite your company signatory"
      closeText="Cancel"
      content={
      <>
        <form className="w-full w-[300px] sm:w-[512px] flex flex-col gap-4 mb-5" id="inviteSignatoryForm" onSubmit={formik.handleSubmit}>
          <Input 
            type="text"
            label="First name"
            id="first_name"
            placeholder="Enter first name"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            error={formik.errors.first_name}
          />
          <Input 
            type="text"
            label="Last name"
            id="last_name"
            placeholder="Enter last name"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            error={formik.errors.last_name}
          />
          <Input 
            type="text"
            id="email"
            label="Email - company address"
            placeholder="Enter company email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.errors.email}
          />
        </form>
      </>
      }
      submitButton={
        <Button type="submit" form="inviteSignatoryForm" onClick={formik.handleSubmit}>
          Send invitation
        </Button>
      }
    />
  )
}
