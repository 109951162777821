import { useState, useEffect } from "react";
import { doGet } from "../common/axiosUtil";

function useAPI({ me, path, params }) {
  const [count, setCount] = useState(0);
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    let cancelled = false;

    if (me) {
      setLoading(true);
      doGet(path, params)
        .then((r) => {
          if (!cancelled) {
            if (r.data) {
              setResult(r?.data);
            } else {
              setResult(r);
            }
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error?.response?.data) {
            setError(error.response.data);
          } else if (error?.message) {
            setError(error.message);
          } else {
            setError(true);
          }
        });
    }
    return () => {
      cancelled = true;
    };
  }, [path, params, count, me]);

  const refresh = () => setCount(count + 1);

  return [result, loading, error, refresh];
}

export default useAPI;
