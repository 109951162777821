import { Chargebee } from "./logos";

export const useGetSubscriptionServices = () => {
  const subscriptions = [
    {
      logo: Chargebee,
      name: "Chargebee",
    },
    // {
    //   logo: Younium,
    //   name: 'Younium',
    // },
    // {
    //   logo: Recurly,
    //   name: 'Recurly',
    // },
  ];

  return {
    subscriptions,
  };
};
