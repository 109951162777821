export const UploadAccountingInformation = () => {
  return (
    <div className="flex flex-col w-full">
      <h5 className="text-base font-semibold">P&L (income statement)</h5>
      <ul className="list-disc list-outside font-medium text-gray-700 ml-5">
        <li>Monthly income statements of the past 18+ months (preferably 24+ months)</li>
      </ul>
      <h5 className="text-base font-semibold mt-5">Balance sheet</h5>
      <ul className="list-disc list-outside font-medium text-gray-700 ml-5">
        <li>The latest full-year balance sheet (e.g. FY 2022)</li>
        <li>The latest monthly balance sheet</li>
      </ul>
      <h5 className="text-base font-semibold mt-5">Is your company part of a group?</h5>
      <p className="font-medium text-gray-700">We require the above data as</p>
      <ul className="list-disc list-outside font-medium text-gray-700 ml-5">
        <li>
          consolidated group financials (preferred), or
        </li>
        <li>the individual financials for each affiliated legal entity (if consolidated is not available)</li>
      </ul>
    </div>
  );
};

