import { Icon } from "@iconify/react";
import { useState, useRef } from "react";
import { Row } from './Row';
import { logout } from "../../common";
import { useContext } from "react";
import { UserContext } from "../../hooks/useWhoAmI";
import { useHistory  } from "react-router";
import { SelectCompanyModal } from "../selectCompanyModal";
import { useHandleClickOutside } from "../../hooks/useHandleClickOutside";
import { AddUserModal } from "../addUserModal";
import { NavLink } from "react-router-dom";
import { Toast } from "../toast";
import { ToastType } from "../../types";

const Menu = ({ isOpen, onClickSwitchCompany, onClickAddUser }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="bg-white rounded-xl flex flex-col border py-2 w-48 md:w-64 absolute right-0 top-10">
      <div className="flex flex-col border-b pb-2">
        <NavLink to="/settings/my-details" exact>
          <Row 
            icon={"mingcute:user-1-line"} 
            text={"Your details"}
          />
        </NavLink>
        <NavLink to="/settings/company-details" exact>
          <Row 
            icon={"mingcute:building-1-line"}
            text={"Company details"}
          />
        </NavLink>
        <Row 
          icon={"mingcute:user-add-line"} 
          text ={"Add user"} 
          onClick={onClickAddUser}
        />
      </div>
      <div className="flex flex-col pt-2">
        <Row 
          icon={"mi:switch"}
          text={"Switch company"} 
          onClick={onClickSwitchCompany}
        />
        <Row 
          icon={"material-symbols:logout-rounded"}
          text={"Log out"} 
          onClick={() => {
            Toast({
              type: ToastType.SUCCESS,
              title: 'You have been logged out'
            })
            logout("/login");
          }}
        />
      </div>
    </div>
  )
}

export const ContextualMenu = () => {
  const { me } = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);
  const [openSwitchCompanyModal, setOpenSwitchCompanyModal] = useState(false);
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const ref = useRef();
  
  useHandleClickOutside(ref, () => setIsOpen(false));


  const fullName = `${me.first_name} ${me.last_name}`
  const history = useHistory();

  return (
    <>
      <div ref={ref} className="cursor-pointer flex gap-3 items-center justify-end" onClick={() => setIsOpen(!isOpen)}>
        <div className="bg-float-purple-5 rounded-full p-2 h-fit">
          <Icon
            className="text-float-purple-2"
            icon="mingcute:user-1-line"
            width={24} />
        </div>
          <div className="flex flex-col">
            <p className="text-sm font-medium select-none">{me?.selected_company?.name}</p>
            <p className="text-sm font-light select-none">{fullName}</p>
          </div>
          <Icon
            className={`text-float-purple-2 ${isOpen && 'rotate-180'}`}
            icon="ic:round-keyboard-arrow-down"
            width={20}
            color={"#959595"} />
          <div className="relative -mt-6">
            <Menu 
              isOpen={isOpen}
              onClickMyDetails={() => history.go('/my-details')}
              onClickSwitchCompany={() => setOpenSwitchCompanyModal(true)}
              onClickAddUser={() => setOpenAddUserModal(true)}
              />
          </div>
        </div>
        <SelectCompanyModal 
          me={me}
          hide={() => setOpenSwitchCompanyModal(false)}
          visible={openSwitchCompanyModal}
          title="Switch company"
        />
        <AddUserModal 
          hide={() => setOpenAddUserModal(false)}
          visible={openAddUserModal}
        />
    </>
  )};

