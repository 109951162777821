export const BankItem = ({ logo, name, onClick, selected }) => {
  return (
    <div
      className={`cursor-pointer flex flex-row w-2/5 mb-2 border rounded-md  p-1 ml-8 ${
        selected ? "border-float-purple-4" : "border-float-grey-10"
      }`}
      onClick={onClick}
    >
      <img alt={`logo for ${name}`} src={logo} className="w-10 h-10 my-auto" />
      <p className="my-auto ml-2">{name}</p>
    </div>
  );
};
