import VerifiedLogo from "../verifiedLogo";
import FloatLogo from "../logoFull";
import ArrowsLogo from "../logoArrows";
import BankIDButton from "../../components/bankIDButton";
import { SERVER_ENVIRONMENT } from "../../common/constants";
import { getOrgNr } from "../../common/localstorage";
import { capitalize } from "../../common/utils";
const converter = require("number-to-words");

export const InitiateVerified = ({ loan, currency, country }) => {
  const getFraction = (number) => {
    if (number === 3) {
      return "third";
    }
    if (number === 6) {
      return "sixth";
    }
    if (number === 9) {
      return "ninth";
    }
    if (number === 12) {
      return "twelfth";
    }
    if (number === 15) {
      return "fifteenth";
    }
  };

  const getRepayment = () => {
    if (loan.grace_period === 0) {
      return `${capitalize(
        converter.toWords(loan.term)
      )} installments of one-${getFraction(loan.term)} (1/${
        loan.term
      }) of the Principal Amount, the first one being due one month after the Activation Date`;
    }
    return `${capitalize(
      converter.toWords(loan.term - loan.grace_period)
    )} installments of one-${getFraction(loan.term - loan.grace_period)} (1/${
      loan.term - loan.grace_period
    }) of the Principal Amount, the first one being due ${converter.toWords(
      loan.grace_period + 1
    )} months after the Activation Date`;
  };

  const countryMap = {
    se: "Swedish",
    dk: "Danish",
    fi: "Finnish",
    no: "Norwegian",
  };

  const constructURL = () => {
    const baseUrl = new URL("https://app.verified.eu/web/float-contract/");
    const activationDate = new Date(loan.activation_date).toLocaleDateString();
    const interest_cost = loan.credit_amount * (loan.interest / 100);
    const advanced_amount = loan.credit_amount - interest_cost;
    if (SERVER_ENVIRONMENT !== "production") {
      baseUrl.searchParams.set("source", "dev-float");
    }
    baseUrl.searchParams.set("input1", currency);
    baseUrl.searchParams.set("input2", loan.credit_amount.toLocaleString());
    baseUrl.searchParams.set("input3", loan.term);
    baseUrl.searchParams.set("input4", parseFloat(loan.interest.toFixed(2)));
    baseUrl.searchParams.set("input5", interest_cost.toLocaleString());
    baseUrl.searchParams.set("input6", advanced_amount.toLocaleString());

    baseUrl.searchParams.set("input7", activationDate);
    baseUrl.searchParams.set("input8", " ");
    baseUrl.searchParams.set("input9", `${loan.grace_period} months`);
    baseUrl.searchParams.set("input10", getRepayment());
    baseUrl.searchParams.set("input14", getOrgNr());
    baseUrl.searchParams.set("input15", countryMap[country]);
    baseUrl.searchParams.set("input19", country);
    return baseUrl.href.replaceAll("+", "%20");
  };

  const doRedirect = () => {
    window.location.href = constructURL();
  };

  return (
    <div className="text-center flex flex-col m-auto">
      <div className="flex flex-row mx-auto">
        <div className="mx-2">
          <FloatLogo />
        </div>
        <div className="mx-2">
          <ArrowsLogo />
        </div>
        <div className="mx-2">
          <VerifiedLogo />
        </div>
      </div>
      <h3 className="font-semibold mt-12 mb-4">Almost done!</h3>
      <p>
        Before we can accept your loan, you need to answer “Know your customer”
        questions and sign the agreement with our partner Verified.
      </p>
      <p>Everything will be signed securly with BankID.</p>
      <BankIDButton
        onClick={doRedirect}
        className="my-8 mx-auto"
        text={"Proceed to signing"}
      />
    </div>
  );
};
