import { useState, useEffect, createContext, useContext } from "react";
import { getWhoAmI, getCreditOffer } from "../api";
import { saveOrgNr, getTokenAccess, getOrgNr } from "../common/localstorage";
import { CountryContext } from "./useCountry";
import { saveToken } from "../common/localstorage";
const VALUES = {
  COMPANY_USER_NO_PERMISSION:
    "You do not have permission to register this company.",
  COMPANY_DETAILS_ERROR: "There was an issue retrieving the company details.",
};

export const UserContext = createContext(null);

const User = ({ children }) => {
  const [me, setMe] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isConnectStep, setIsConnectStep] = useState(false);

  const { setCountry } = useContext(CountryContext);

  useEffect(() => {
    if (me) {
      setCountry(me?.selected_company?.country);
    }
  }, [me, setCountry]);

  const hasCreditOffer = async () => {
    const creditOffer = await getCreditOffer();
    const hasNoOffer = creditOffer.length <= 0;
    setIsConnectStep(hasNoOffer);
  };

  const updateWhoAmI = async () => {
    try {
      setLoading(true);
      const whoamI = await getWhoAmI();
      let selectedCompany = null;
      if (whoamI.company.length > 0) {
        const orgnr = getOrgNr() || whoamI.company[0].organisation_number;
        selectedCompany = whoamI.company.find((obj) => {
          return obj.organisation_number === orgnr;
        });
        saveOrgNr(orgnr);
      }
      setMe({ selected_company: selectedCompany, ...whoamI });
      setLoading(false);
    } catch (ex) {
      setError(ex);
      setLoading(false);
    }
  };

  const login = async (token) => {
    saveToken(token);
    updateWhoAmI();
  };

  useEffect(() => {
    if (getTokenAccess()) {
      updateWhoAmI();
      hasCreditOffer();
    } else {
      setLoading(false);
    }
  }, []);

  function clearError() {
    setError(null);
  }

  function getHumanReadableMessage() {
    if (error) {
      return (
        VALUES[error] ||
        "Your account is not connected to a company. Please enter your company's organisation number to begin."
      );
    }

    return null;
  }

  return (
    <UserContext.Provider
      value={{
        error,
        me,
        clearError,
        getHumanReadableMessage,
        loading,
        updateWhoAmI,
        isConnectStep,
        login,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default User;
