import * as Yup from "yup";

export const useGetValidationSchema = ({ isActiveUser }) => {
  if (isActiveUser) {
    const validationSchema = Yup.object({
      first_name: Yup.string().required("Please enter your first name"),
      last_name: Yup.string().required("Please enter your last name"),
      phone_number: Yup.string().required("Please enter you phone number"),
    });

    return {
      validationSchema,
    };
  } else {
    const validationSchema = Yup.object({
      first_name: Yup.string().required("Please enter your first name"),
      last_name: Yup.string().required("Please enter your last name"),
      phone_number: Yup.string().required("Please enter you phone number"),
      password1: Yup.string()
        .required("Password is required")
        .min(8, "Password is too short - should be 8 charactes minimum."),
      password2: Yup.string()
        .required("Confirmed password is required")
        .oneOf([Yup.ref("password1")], "Passwords must match."),
    });

    return {
      validationSchema,
    };
  }
};
