import { useFormik } from "formik";
import { useContext, useState } from "react";
import { UserContext } from "../../../../hooks/useWhoAmI";
import { validationSchema } from "./validation";

export const useCompanyDetailsForm = () => {
  const { me } = useContext(UserContext);
  const [error, setError] = useState({})
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    try {
      setError({})
      setIsLoading(true);
      // TODO: add handling of data?
      console.log(data);
      // TODO: todo add correct toast
    } catch (e) {
      setError(e?.response?.data);
      // TODO: add correct toast
    } finally {
      setIsLoading(false);
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      company_name: me?.selected_company?.name || '',
      organisation_number: me?.selected_company?.organisation_number || '',
      business_model: me?.selected_company?.business_model || '',
      business_website: me?.selected_company?.business_website || '',
    },
    onSubmit,
    validationSchema,
  });

  return {
    formik,
    isLoading,
    error,
  }
}