import { useState } from "react";
import { useFormik } from "formik";
import { registerCompany } from "../../../../../../../api";
import { getCountryCookie } from "../../../../../../../common/cookie";
import { useGetValidationSchema, useGetInitialValues } from "../index";
import { logout } from "../../../../../../../common/index";
import { clearCompanyDetails } from "../../../../../../../common/localstorage";

export const useCompanyDetailsForm = () => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [done, setDone] = useState(false);
  const [ARR, setARR] = useState();

  const country = getCountryCookie();
  const updatedCountryCode =
    country?.code !== "se" ||
    country?.code !== "dk" ||
    country?.code !== "fi" ||
    country?.code !== "no"
      ? "other"
      : country?.code;

  const { validationSchema } = useGetValidationSchema({
    country: updatedCountryCode,
  });
  const { initialValues } = useGetInitialValues();

  const onSubmit = async (data) => {
    setLoadingSubmit(true);
    try {
      await registerCompany({ ...data, country: country.id });
      clearCompanyDetails();
      setARR(data.annual_recurring_revenue);
      setDone(true);
    } catch (e) {
      if (e?.response.status === 409) {
        logout("/signup/manual/company-exists");
      }
    } finally {
      setLoadingSubmit(false);
    }
  };

  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  });

  return {
    formik,
    loadingSubmit,
    done,
    ARR,
  };
};
