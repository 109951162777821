import { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import useAPI from "../../hooks/useAPI";
import { UserContext } from "../../hooks/useWhoAmI";
import Loading from "../loading";
import { Selector } from "./selector";
import { ModalContext } from "../modal";
import { SignedInformation } from "../signedInformation";
import ExpiredCreditOffer from "../expiredCreditoffer";
import LoanChart from "../loanChart";
import { useGetFormValues } from "../loanChart/hooks/useGetFormValues";
import InterestBox from "./interestBox";
import { addWorkingDays } from "../../utils";

const LoanComponent = ({ location }) => {
  const loanChart = useGetFormValues();
  const { tableInfo } = loanChart;

  const { me } = useContext(UserContext);
  const [result, loading, error, refresh] = useAPI({
    me,
    path: "v1/credit_offer/",
  });
  const [resultAccounts, loadingAccounts, errorAccounts, refreshAccounts] =
    useAPI({
      me,
      path: "v1/ais/accounts/",
    });

  const { setContent, setVisible, setWrapperCSS } = useContext(ModalContext);
  const [expiredCreditOfferVisible, setExpiredOfferVisible] = useState(false);
  const search = new URLSearchParams(location.search);
  let state = search.get("state");

  useEffect(() => {
    const onModalClose = () => {
      setVisible(false);
    };
    if (state === "signed" && me) {
      setWrapperCSS("");
      setContent(
        <SignedInformation onClose={onModalClose} me={me} loading={loading} />
      );
      setVisible(true);
    }
  }, [state, setContent, setVisible, setWrapperCSS, me, loading]);

  useEffect(() => {
    if (!result || result.length === 0) {
      return;
    }
    const latestOffer = result[0];
    const validDate = latestOffer.valid_date;
    const today = new Date().toISOString();
    if (validDate <= today) {
      setExpiredOfferVisible(true);
    }
  }, [result, setExpiredOfferVisible]);

  if (loading || loadingAccounts) {
    return <Loading />;
  }
  if (!result || result.length === 0 || error) {
    return <p>No credit offer found.</p>;
  }
  if (errorAccounts) {
    return <p>Failed loading accounts</p>;
  }
  const latestOffer = result[0];
  const validDate = latestOffer.valid_date;
  const maxDate = addWorkingDays(5, validDate);
  const today = new Date().toISOString();
  const expiredOffer = validDate <= today;

  if (latestOffer.total_credit - latestOffer.used_credit <= 0) {
    return (
      <div className="flex flex-col flex-1 space-y-4">
        <p className="text-gray-600">
          You've already applied for the maximum amount possible within this
          credit offer.
        </p>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col lg:flex-row drop-shadow-lg">
        <div className="bg-float-purple-5 p-4 space-y-1 relative flex flex-col justify-between h-full w-full rounded-t-lg lg:rounded-none lg:rounded-l-lg lg:w-1/3">
          <div className="space-y-1">
            <h3 className="font-semibold">Parameters</h3>
            <p className="pb-6">What amount would you like to take out?</p>
          </div>
          <Selector
            {...latestOffer}
            refresh={refresh}
            loanChart={loanChart}
            expiredOffer={expiredOffer}
            validMaxDate={maxDate}
            accounts={resultAccounts}
            refreshAccounts={refreshAccounts}
          />
        </div>
        <div className="bg-white h-full lg:w-2/3 flex flex-col rounded-b-lg lg:rounded-none lg:rounded-r-lg">
          <h3 className="font-semibold p-4">Cash flows</h3>
          <div className="bg-white h-4/5 p-4 flex items-center lg:rounded-r-lg">
            <LoanChart
              outstandingPrinciple={loanChart.outstandingPrinciple}
              repayment={loanChart.repayment}
              interest={loanChart.interest}
              principle={loanChart.principle}
              labels={loanChart.labels}
              currency={result[0].currency}
            />
          </div>
          <div className="bg-white h-1/4 py-4 flex items-center justify-center lg:p-0 rounded-b-lg lg:rounded-none lg:rounded-br-lg">
            <InterestBox tableInfo={tableInfo} currency={result[0].currency} />
          </div>
        </div>
      </div>
      <ExpiredCreditOffer
        hide={() => setExpiredOfferVisible(false)}
        visible={expiredCreditOfferVisible}
      />
    </>
  );
};

export default withRouter(LoanComponent);
