import * as Yup from "yup";
import { useState } from "react";
import { useFormik } from "formik";
import TwoColWithImage from "../../templates/twoColWithImage";
import Image from '../../assets/images/secure_pattern.png'
import Input from "../../components/input";
import { useGetErrorMessage } from "../../utils/";
import { useHistory } from "react-router";
import { setPassword } from "../../api/auth";
import { Toast } from "../../components";
import { ToastType } from "../../types";

const SetPassword = ({ location }) => {
  const search = new URLSearchParams(location.search);
  const [submitError, setSubmitError] = useState({});
  const { errorMessages } = useGetErrorMessage();
  const history = useHistory();

  let token = search.get("token");
  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setSubmitError({});
      const formData = { token: token, ...values };
      try {
        await setPassword(formData);

        Toast(
          {
            type: ToastType.SUCCESS,
            title: 'Yes, success!',
            text: "Password successfully set!",
          }
          )
        
        history.push("/login");
      } catch (err) {
        const error = errorMessages(err.response.data);
        setSubmitError(error);
      }
    },
    initialValues: {
      first_name: "",
      last_name: "",
      phone_number: "",
      password: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("First name is required"),
      last_name: Yup.string().required("Last name is required"),
      phone_number: Yup.number().required("Phone number is required"),
      password: Yup.string()
        .required("No password provided.")
        .min(8, "Password is too short - should be 8 charactes minimum."),
    }),
  });

  return (
    <TwoColWithImage title="Signup" image={<Image />}>
      <form onSubmit={formik.handleSubmit}>
        <h3 className="text-left mb-4 text-lg lg:text-2xl">
          Login credentials & User information
        </h3>
        <div className="flex flex-row flex-wrap w-full gap-4">
          <Input
            type="text"
            id="first_name"
            label="First name"
            className="flex-1 basis-5/12"
            required
            onChange={formik.handleChange}
            value={formik.values.firstName}
          />
          <Input
            type="text"
            id="last_name"
            label="Last name"
            className="flex-1 basis-5/12"
            required
            onChange={formik.handleChange}
            value={formik.values.lastName}
          />
          <Input
            type="tel"
            id="phone_number"
            label="Phone number"
            className={"w-full"}
            required
            onChange={formik.handleChange}
            value={formik.values.phoneNumber}
          />
          <Input
            type="password"
            id="password"
            label="Password"
            className="flex-1 basis-5/12"
            required
            onChange={formik.handleChange}
            value={formik.values.password}
            error={formik.errors.password || submitError.password}
          />
        </div>
        <button
          className="mt-4 ml-auto"
          type="submit"
          //onClick={(e) => formik.handleSubmit(e)}
          disabled={formik.isSubmitting}
        >
          Continue
        </button>
      </form>
    </TwoColWithImage>
  );
};

export default SetPassword;
