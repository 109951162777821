import { useContext } from "react";
import { UserContext } from "../../hooks/useWhoAmI";
import { Icon } from "@iconify/react";
import Loading from "../loading";
import LoanList from "../loanList";
import LoanOverview from "../loanOverview";
import AccountOverview from "../accountOverview";
import Invoice from "../invoice";

const Overview = () => {
  const { me, loading } = useContext(UserContext);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="bg-float-purple-6 pl-[140px] pt-[120px] flex flex-col mt-2 lg:mt-0 pb-11 pr-11 md:pr-24 lg:pr-11 h-screen gap-y-4 w-full min-h-screen max-h-full">
      {me && (
        <div className="flex flex-row">
          <h1 className="text-xl sm:text-3xl my-auto">
            Welcome back, {me.selected_company.name}
          </h1>
          <div className="tooltip">
            <Icon
              icon="clarity:beta-solid"
              className="text-float-red-1 ml-4"
              width="50"
            />
            <span className="text">
              Certain elements within this view are presently undergoing
              updates. Please note that some information may be temporarily
              outdated as we continue to refine and enhance this feature in its
              BETA phase
            </span>
          </div>
        </div>
      )}
      <div className="flex flex-col gap-y-8 max-w-[1500px]">
        <div className="flex flex-col gap-y-8 lg:flex-row gap-x-8">
          <div className="h-full bg-white rounded-lg w-full lg:w-2/4 space-y-1 shadow-lg">
            <div className="bg-float-purple-5 w-full h-10 left-0 top-0 rounded-t-lg -z-10 flex flex-row">
              <p className="ml-4 flex my-auto">Loan overview</p>
            </div>
            <LoanOverview />
          </div>
          <div className="h-full lg:w-2/5 bg-white rounded-lg space-y-1 shadow-lg">
            <div className="bg-float-purple-5 w-full h-10 left-0 top-0 rounded-t-lg -z-10 flex flex-row">
              <p className="ml-4 flex my-auto">Account overview</p>
            </div>
            <AccountOverview />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-x-8 max-h-[48%] gap-y-4 lg:mb-4 mb-8">
          <div className="h-full bg-white rounded-lg lg:w-2/4 space-y-1 shadow-lg flex flex-col">
            <div className="bg-float-purple-5 w-full h-10 left-0 top-0 rounded-t-lg flex flex-row">
              <p className="ml-4 flex my-auto">Your loans</p>
            </div>
            <LoanList />
          </div>
          <div className="h-full lg:w-2/5 bg-white rounded-lg space-y-1 shadow-lg flex flex-col my-4 lg:mt-0">
            <div className="bg-float-purple-5 w-full h-10 left-0 top-0 rounded-t-lg flex flex-row">
              <p className="ml-4 flex my-auto">Payments</p>
            </div>
            <Invoice />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
