import { useFormik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { postLoan } from "../../api";
import { addWorkingDays } from "../../utils";
import { AccountSelector } from "../accountSelector";
import { AccountVerification } from "../accountVerification";
import Info from "../info";
import { InitiateVerified } from "../initiateVerified";
import { ModalContext } from "../modal";
import useGetValidationSchema from "./validation/useGetValidationSchema";
import useLoanChart from "../loanChart/hooks/useLoanChart";
import { CountryContext } from "../../hooks/useCountry";
import { ToastType } from "../../types";
import { Toast } from "../toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const Selector = (props) => {
  const history = useHistory();
  const { country } = useContext(CountryContext);
  const { validationSchema } = useGetValidationSchema();
  const [termsOfferArray, setTermsOfferArray] = useState([[]]);
  const getPauOutDate = () => {
    /* Temporary make 9 september the earliest pay-out date*/
    const initialDate = addWorkingDays(5);
    const temporaryDate = new Date("2024-09-09");
    return initialDate < temporaryDate ? temporaryDate : initialDate;
  };
  const pay_out_date = getPauOutDate(); //addWorkingDays(5);
  const [activationDate, setActvationDate] = useState(new Date(pay_out_date));
  const inputRef = useRef(null);
  const onTextClick = () => inputRef.current.focus();

  let formatter = new Intl.NumberFormat(country?.code);
  const { setContent, setVisible, setTitle, setWrapperCSS } =
    useContext(ModalContext);
  const {
    used_credit,
    total_credit,
    interest,
    id,
    currency,
    loanChart,
    validMaxDate,
    accounts,
    refreshAccounts,
  } = props;

  let shortFormFormatter = Intl.NumberFormat(country?.code, {
    notation: "compact",
  });

  const maxLoanAmount = total_credit - used_credit;
  const minLoanAmount =
    total_credit * 0.1 < maxLoanAmount
      ? total_credit * 0.1
      : maxLoanAmount * 0.1;

  const getTermDefaultValue = () => {
    const entries = Object.entries(interest);
    const termsInterestOffer = entries.filter(
      (entry) => !Object.values(entry[1]).every((x) => x === null)
    );
    const defaultTerm = termsInterestOffer.find((entry) => entry[0] === "12");
    if (defaultTerm) {
      return defaultTerm[0];
    }
    return termsInterestOffer[termsInterestOffer.length - 1][0];
  };

  const formik = useFormik({
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: {
      amount: Math.floor((total_credit - used_credit) / 1000) * 1000,
      grace_period: "0",
      term: getTermDefaultValue(),
      activation_date: pay_out_date.toISOString().split("T")[0],
    },
    validationSchema: validationSchema(
      formatter,
      minLoanAmount,
      currency,
      total_credit,
      used_credit
    ),
  });

  const handleTerm = (e) => {
    if (interest[e.target.value][formik.values.grace_period] === null) {
      formik.setFieldValue("grace_period", "0");
    }
    formik.handleChange(e);
  };

  const handleDate = (date, e) => {
    setActvationDate(date);
    formik.handleChange(e);
  };

  const formatInput = (value) => {
    return `${formatter
      .formatToParts(value)
      .map((p) => {
        switch (p.type) {
          case "currency":
          case "literal":
          case "integer":
            return p.value;
          case "group":
            return " ";

          default:
            return null;
        }
      })
      .join("")}`;
  };

  const { setLoanChartValues } = useLoanChart(formatInput, formik);

  useEffect(() => {
    setLoanChartValues(loanChart, setTermsOfferArray, interest, pay_out_date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values, formik.errors.amount, activationDate]);

  const submit = async (account_id) => {
    try {
      const loan = await postLoan(
        Math.round(formik.values.amount),
        Math.round(
          formik.values.amount /
            (formik.values.term - formik.values.grace_period)
        ),
        account_id,
        id,
        parseInt(formik.values.grace_period),
        parseInt(formik.values.term),
        activationDate.toISOString().split("T")[0],
        interest[formik.values.term][formik.values.grace_period] || 0
      );
      if (country.contract_verified) {
        showVerifiedModal(loan);
      } else {
        history.push("/loan?state=signed");
      }
    } catch (ex) {
      console.error(ex);
      // TODO: Parse error better.
      Toast({
        type: ToastType.ERROR,
        title: "Oh no, there was an error",
        text: ex?.response?.data.error,
      });
    }
  };

  if (total_credit === used_credit) {
    return null;
  }

  const isWeekday = (date) => {
    const day = date.getDay(date);
    return day !== 0 && day !== 6;
  };

  const showAccountModal = () => {
    setWrapperCSS("w-2/6");
    setContent(<AccountSelector onComplete={submit} />);
    setTitle("Choose account and payment");
    setVisible(true);
  };

  const showVerifiedModal = (loan) => {
    setWrapperCSS("w-2/6");
    setContent(
      <InitiateVerified
        loan={loan}
        currency={currency}
        interest={interest}
        country={country.code}
      />
    );
    setTitle("Choose account and payment");
    setVisible(true);
  };

  const showAccountVerificationModal = () => {
    setWrapperCSS(null);
    setContent(
      <AccountVerification
        accounts={accounts}
        currency={currency}
        onSuccess={() => {
          refreshAccounts();
          setVisible(false);
        }}
        onCancel={() => {
          setVisible(false);
        }}
      />
    );
    setTitle("Verify bank account");
    setVisible(true);
  };

  const disabledGrace = (term, grace) => {
    return !interest[term][grace];
  };

  const payoutAccountStatus = (accounts) => {
    if (accounts.length === 0) {
      return "Unverified";
    }
    if (accounts.some((account) => account.verified_status === "verified")) {
      return "Verified";
    }
    if (accounts.some((account) => account.verified_status === "in_progress")) {
      return "Pending verification";
    }
    return "Unverified";
  };

  const getAccountStatusCSS = (status) => {
    if (status === "Pending verification") {
      return "text-float-orange-1";
    } else if (status === "Unverified") {
      return "text-float-red-1";
    }
  };

  const accountVerifiedStatus = payoutAccountStatus(accounts);
  return (
    <>
      <div className="flex flex-row lg:flex-col flex-1 h-full justify-around lg:space-y-6">
        <div>
          <div className="relative grid grid-cols-1">
            <div
              className="z-10 text-center text-lg font-bold row-start-1 col-start-1 mt-4"
              onClick={onTextClick}
            >
              {formik.values.amount !== "" && formatInput(formik.values.amount)}
            </div>
            <input
              name="amount"
              type="number"
              ref={inputRef}
              onChange={formik.handleChange}
              value={formik.values.amount}
              className="z-100 row-start-1 col-start-1 text-center border-0 text-lg font-bold rounded-lg p-4 w-full text-transparent"
            />
            <span className="font-bold absolute top-4 right-4">{currency}</span>
          </div>
          <div className="flex flex-row w-full items-center mt-6 lg:mt-0">
            <p>{shortFormFormatter.format(minLoanAmount)}</p>
            <input
              id="amount"
              name="amount"
              type="range"
              step={1000}
              min={minLoanAmount}
              max={maxLoanAmount}
              value={formik.values.amount}
              error={formik.errors.amount}
              onChange={formik.handleChange}
              className="flex-1 mx-4 appearance-none bg-float-purple-4 rounded h-2 accent-float-purple-3"
            />
            <p>{shortFormFormatter.format(maxLoanAmount)}</p>
          </div>
          {formik.errors?.amount && (
            <p className="text-functional-red-100">{formik.errors.amount}</p>
          )}
          <div>
            <p className="mb-3 mt-8 lg:mt-6">
              <b>Term</b> in months
            </p>
            <div className="flex flex-col w-full relative">
              <input
                name="term"
                value={formik.values.term}
                error={formik.errors.term}
                onChange={handleTerm}
                type="range"
                min={termsOfferArray[0][0]}
                max={termsOfferArray[termsOfferArray.length - 1][0]}
                step="3"
                list="datalist"
                className="items-center appearance-none rounded h-2 accent-float-purple-3 px-2 z-10 bg-transparent"
              />
              <div className="absolute flex px-2 justify-between w-full rounded bg-white">
                {termsOfferArray.map((term) => (
                  <span
                    className="bg-float-purple-3 rounded-full w-2 h-2"
                    key={term[0]}
                  ></span>
                ))}
              </div>
              <datalist id="datalist" className="flex justify-between mx-2">
                {termsOfferArray.map((term) => (
                  <option value={term[0]} className="text-sm mt-1">
                    {term[0]}
                  </option>
                ))}
              </datalist>
            </div>
          </div>
        </div>
        <div className="pl-6 lg:pl-0 w-1/2 lg:w-full border-l-2 border-float-purple-4 lg:border-transparent">
          <div>
            <label htmlFor="grace_period" className="mb-3 flex">
              <b>Grace period</b>&nbsp; (no repayments)
              <Info
                classname="text-sm font-medium text-gray-700"
                value={"Defer the beginning of repayments"}
              />
            </label>
            <div className="flex gap-4 items-center">
              <label
                className={`text-sm flex items-center ${
                  disabledGrace(formik.values.term, 0) && "text-gray-500 italic"
                }`}
              >
                <input
                  disabled={disabledGrace(formik.values.term, 0)}
                  id="grace_period"
                  className="mr-1 radiobutton disabled:bg-gray-500"
                  type="radio"
                  name="grace_period"
                  value={"0"}
                  checked={formik.values.grace_period === "0"}
                  onChange={formik.handleChange}
                />
                No, thanks
              </label>
              <label
                className={`text-sm flex items-center ${
                  disabledGrace(formik.values.term, 3) &&
                  "text-gray-500 italic line-through"
                }`}
              >
                <input
                  disabled={disabledGrace(formik.values.term, 3)}
                  className="mr-1 radiobutton"
                  type="radio"
                  name="grace_period"
                  value={"3"}
                  checked={formik.values.grace_period === "3"}
                  onChange={formik.handleChange}
                />
                3 months
              </label>
              <label
                className={`text-sm flex items-center ${
                  disabledGrace(formik.values.term, 6) && "text-float-grey-50"
                }`}
              >
                <input
                  disabled={disabledGrace(formik.values.term, 6)}
                  className="mr-1 radiobutton"
                  type="radio"
                  name="grace_period"
                  value={"6"}
                  checked={formik.values.grace_period === "6"}
                  onChange={formik.handleChange}
                />
                6 months
              </label>
            </div>
          </div>
          <div className="w-full">
            <label
              htmlFor="activation_date"
              className="font-bold flex mt-4 lg:mt-6 mb-3"
            >
              Pay-out date
              <Info
                classname="text-sm font-medium text-gray-700"
                value={"Pay out date can be earliest 4 workdays from now"}
              />
            </label>
            <DatePicker
              selected={activationDate}
              onChange={handleDate}
              filterDate={isWeekday}
              minDate={pay_out_date}
              maxDate={validMaxDate}
              name="activation_date"
              id="activation_date"
              className="rounded-lg border-transparent w-full"
              dateFormat="yyyy-MM-dd"
            />
            {accountVerifiedStatus !== "Verified" && (
              <div className="flex flex-row mt-4">
                <label htmlFor="payout-account-status" className="font-bold">
                  Payout account status
                </label>
                <p
                  id="payout-account-status"
                  className={`${getAccountStatusCSS(
                    accountVerifiedStatus
                  )} ml-2`}
                >
                  {accountVerifiedStatus}
                </p>
              </div>
            )}
          </div>
          <div className="flex flex-col items-center mt-4 lg:mt-6">
            {accounts.length === 0 || accountVerifiedStatus !== "Verified" ? (
              <button
                type="submit"
                className="font-semibold w-full mb-4"
                onClick={() => showAccountVerificationModal()}
              >
                Verify bank account
              </button>
            ) : null}
            <button
              type="submit"
              className="font-semibold w-full"
              disabled={
                !formik.isValid ||
                formik.values.amount === used_credit ||
                props.expiredOffer ||
                accounts.length === 0 ||
                accountVerifiedStatus !== "Verified"
              }
              onClick={() => showAccountModal()}
            >
              Continue
            </button>
            {props.expiredOffer ? (
              <p className="text-xs my-2 w-3/4 text-center">
                Your credit offer has expired. Contact float to get it renewed.
              </p>
            ) : (
              <p className="text-xs my-2 w-3/4 text-center">
                {accountVerifiedStatus !== "Verified" ? (
                  <>
                    You will not be able to continue until your payout account
                    has been verified.
                  </>
                ) : (
                  <>
                    This will generate the contract for you to first review and
                    then sign
                  </>
                )}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
