import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";

import { getCompanyDetails, patchCompanyDetails } from "../../api/admin";
import Input from "../input";
import { Toast } from "../toast";
import { ToastType } from "../../types";

const Company = ({ id }) => {
  const [companyId, setCompanyId] = useState(id);
  const [company, setCompany] = useState(null);
  const [readOnly, setReadOnly] = useState(true);

  useEffect(() => {
    const fetchResult = async () => {
      const result = await getCompanyDetails(companyId);
      setCompany(result);
    };
    if (companyId) {
      fetchResult();
    }
  }, [companyId]);

  useEffect(() => {
    setCompanyId(id);
  }, [id]);

  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
      name: company?.name,
      organisation_number: company?.organisation_number,
      address: company?.address,
      zipcode: company?.zipcode,
      city: company?.city,
    },
    onSubmit: async (values) => {
      await patchCompanyDetails(companyId, values);
      Toast(
        {
          type: ToastType.SUCCESS,
          title: 'Yes, success!',
          text: "Successfully updated company",
        }
        )
      setReadOnly(true);
    },
  });

  if (!companyId || !company) {
    return "";
  }

  return (
    <>
      <button
        onClick={() => {
          setReadOnly(!readOnly);
        }}
        className="ml-auto flex"
      >
        <Icon classname="my-auto" icon="bi-pencil-square" width={20} />
        <span className="ml-4">{readOnly ? "Edit" : "Cancel"}</span>
      </button>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-row flex-wrap w-full gap-4">
          <Input
            type="text"
            id="name"
            label="Name"
            className="flex-1 basis-5/12"
            value={formik.values.name}
            disabled={readOnly}
          />
          <Input
            type="text"
            id="organisation_number"
            label="Organisation number"
            className="flex-1 basis-5/12"
            value={formik.values.organisation_number}
            disabled={readOnly}
          />
          <Input
            type="text"
            id="address"
            label="Address"
            className="flex-1 basis-5/12"
            required
            onChange={formik.handleChange}
            value={formik.values.address}
            disabled={readOnly}
          />
          <Input
            type="text"
            id="city"
            label="City"
            className="flex-1 basis-5/12"
            required
            onChange={formik.handleChange}
            value={formik.values.city}
            disabled={readOnly}
          />
          <Input
            type="text"
            id="zipcode"
            label="Zipcode"
            className="flex-1 basis-5/12"
            required
            onChange={formik.handleChange}
            value={formik.values.zipcode}
            disabled={readOnly}
          />
        </div>
        <button
          // onClick={onClick}
          className="mt-4"
          type="submit"
          disabled={readOnly}
        >
          Submit
        </button>
      </form>
    </>
  );
};

export default Company;
