import { Dropdown } from "../../../components";
import Input from "../../../components/input";
import { SettingsLayout } from "../../../templates/SettingsLayout";
import { BUSINESS_MODEL } from '../../../types';
import { useCompanyDetailsForm } from "./hooks";

const CompanyDetails = () => {
  const { formik, error } = useCompanyDetailsForm();

  return (
    <SettingsLayout>
      <div className="w-full h-full">
      <form onSubmit={formik.handleSubmit}
        className="flex flex-col gap-3 max-w-xl"
      >
        <Input 
          disabled
          label="Company name"
          placeholder="Company name"
          type="text"
          name="company_name"
          id="company_name"
          value={formik.values.company_name}
          onChange={formik.handleChange}
          error={formik.errors.company_name || error?.company_name}
        />
        <Input 
          label="Organisation number"
          placeholder="Organisation number"
          disabled
          type="text"
          name="organisation_number"
          id="organisation_number"
          value={formik.values.organisation_number}
          onChange={formik.handleChange}
          error={formik.errors.organisation_number || error?.organisation_number}
        />
        <Dropdown 
          disabled
          label="Business model"
          placeholder="Choose your business model"
          type="text"
          name="business_model"
          id="business_model"
          value={formik.values.business_model}
          onChange={formik.handleChange}
          error={formik.errors.business_model || error?.business_model}
          options={BUSINESS_MODEL}
        />
        <Input 
          disabled
          label="Business website"
          placeholder="Business website"
          type="text"
          name="business_website"
          id="business_website"
          value={formik.values.business_website}
          onChange={formik.handleChange}
          error={formik.errors.business_website || error?.business_website}
        />
      </form>
    </div>
    </SettingsLayout>
  )
}

export default CompanyDetails;