import LogoNO from "./BankIDNorwayLogo";
import LogoSE from "./BankIDLogo";
import LogoDK from "./MitIDLogo";

const renderLogo = (country, width) => {
  switch (country?.code) {
    case "no":
      return <LogoNO width={width} />;
    case "dk":
      return <LogoDK width={width + 10} />;
    default:
      return <LogoSE width={width} />;
  }
};

const renderText = (country) => {
  switch (country?.code) {
    case "dk":
      return "Sign in with NemID";
    default:
      return "Sign in with BankID";
  }
};

const Button = ({ onClick, className, country, text, disabled = false }) => {
  const width = 40;
  return (
    <button
      onClick={onClick}
      className={`bankid ${className}`}
      type="submit"
      disabled={disabled}
    >
      {renderLogo(country, width)}
      {text || renderText(country)}
    </button>
  );
};

export default Button;
