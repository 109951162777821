import { Toast } from "../components";
import { history } from "../routing";
import { ToastType } from "../types";
import { clearAll } from "./localstorage";

export const logout = (
  route,
  message = null,
  title = "Your session timed out"
) => {
  clearAll();
  if (message) {
    Toast({
      type: ToastType.ERROR,
      title: title,
      text: message,
    });
  }
  history.push(route);
};
