import React from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  Filler,
} from "chart.js";
import { Chart } from "react-chartjs-2";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const LoanChart = ({
  outstandingPrinciple,
  repayment,
  interest,
  principle,
  labels,
  currency,
}) => {
  ChartJS.register(Filler);

  let width, height, gradient;
  function getGradient(ctx, chartArea) {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (!gradient || width !== chartWidth || height !== chartHeight) {
      width = chartWidth;
      height = chartHeight;
      gradient = ctx.createLinearGradient(
        0,
        chartArea.bottom,
        0,
        chartArea.top
      );
      gradient.addColorStop(0, "transparent");
      gradient.addColorStop(0.3, "rgb(255, 255, 255, 0)");
      gradient.addColorStop(1, "#BD87D4");
    }

    return gradient;
  }

  const data = {
    labels,
    datasets: [
      {
        type: "bar",
        label: "Amount",
        backgroundColor: "#450B70",
        data: principle,
        borderRadius: 10,
      },
      {
        type: "line",
        label: "Outstanding balance",
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return;
          }
          return getGradient(ctx, chartArea);
        },
        borderWidth: 2,
        fill: true,
        data: outstandingPrinciple,
        pointRadius: 0,
        pointHoverRadius: 4,
        pointDotStrokeWidth: 4,
        // 3 below are set to default to increase performance
        tension: false,
        stepped: 0,
        borderDash: [],
      },
      {
        type: "bar",
        label: "Interest",
        backgroundColor: "#FD884D",
        data: interest,
        borderRadius: 10,
      },
      {
        type: "bar",
        label: "Repayment",
        backgroundColor: "#ffc29c",
        data: repayment,
        borderColor: "white",
        borderWidth: 2,
        borderRadius: 10,
      },
    ],
  };
  const options = {
    hover: {
      intersect: false,
    },
    plugins: {
      tooltip: {
        boxWidth: 0,
        boxHeight: 0,
        boxPadding: 0,
        bodyColor: "black",
        titleColor: "black",
        backgroundColor: "transparent",
        callbacks: {
          title: (context) => `${context[0].formattedValue} ${currency}`,
          label: (context) => "",
        },
      },
      legend: {
        labels: {
          usePointStyle: true,
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        stacked: true,
        ticks: {
          display: false,
        },
        grid: {
          drawBorder: false,
          color: function (context) {
            if (context.tick.value === 0) {
              return "rgba(0, 0, 0, 0.1)";
            } else if (context.tick.value < 0 || context.tick.value > 0) {
              return "transparent";
            }
          },
        },
      },
    },
  };

  return <Chart id="loanChart" type="bar" data={data} options={options} />;
};

export default LoanChart;
