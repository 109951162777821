import { useState } from "react";
import { doPost } from "../../common/axiosUtil";
import { Toast } from "../toast";
import { ToastType } from "../../types";
import { MONTO_TENANT_UUID } from "../../common/constants";
import { ConnectButton } from "../connectButton";

const AccountingSync = ({ company_monto_uuid, company_name }) => {
  const [loading, setLoading] = useState(false);

  const createCompany = async () => {
    try {
      const company = await doPost("v1/accounting/company/", {
        name: company_name,
      });
      return company;
    } catch (ex) {
      Toast({
        type: ToastType.ERROR,
        title: "Oh no, there was an error",
        text: ex.response.data.error,
      });
      setLoading(false);
    }
  };

  const initiateAccountingSync = async () => {
    if (!company_monto_uuid) {
      const company = await createCompany();
      company_monto_uuid = company?.uuid;
    }
    window.location.href = `https://connect.monto.ai/${MONTO_TENANT_UUID}/companies/${company_monto_uuid}/link/accounting`;
  };

  return (
    <ConnectButton
      text={"Connect"}
      disabled={loading}
      onClick={initiateAccountingSync}
    />
  );
};

export default AccountingSync;
