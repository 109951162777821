import { doPost, doGet } from "../common/axiosUtil";

export const postNDA = async (company) => {
  const post = await doPost("v1/nda/", { company: company });
  return post;
};

export const getNDA = async () => {
  const get = await doGet("v1/nda/");
  return get;
};
