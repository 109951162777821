export const useMailToSupport = () => {
  const mailToSupportLink = "mailto:support@gofloat.io";
  const mailToSupport = () => {
    window.location.href = mailToSupportLink;
  }

  return {
    mailToSupport,
    mailToSupportLink,
  }
}