import { Icon } from "@iconify/react";
import toast from "react-hot-toast";
import { ToastType } from "../../types";

export const Toast = ({ type = ToastType.INFO, title, text }) => {
  const icons = {
    warning: "mingcute:alert-line",
    info: "material-symbols:info-outline-rounded",
    success: "mdi:success-circle-outline",
    error: "bx:error-alt",
  };

  const isErrorOrSuccess =
    type === ToastType.ERROR || type === ToastType.SUCCESS;

  return toast.custom((t) => (
    <div
      className={`
      max-w-2xl w-full bg-white shadow-lg rounded-lg pointer-events-auto flex mb-4
      ${t.visible ? "animate-enter" : "animate-leave"}
      ${
        type === ToastType.INFO &&
        "bg-functional-blue-10 text-float-black-white"
      }
      ${type === ToastType.WARNING && "bg-functional-yellow-10"}
      ${
        type === ToastType.ERROR &&
        "bg-functional-red-10 text-float-black-white"
      }
      ${
        type === ToastType.SUCCESS &&
        "bg-functional-green-10 text-float-black-white"
      }
      `}
    >
      <div className="flex-1 p-4">
        <div className="flex flex-row gap-5 items-center pl-2">
          <Icon icon={icons[type]} width={32} />
          <div className="flex items-start">
            <div className="ml-3 flex-1">
              <h4 className="font-bold">{title}</h4>
              <p className="mt-1 text-sm">{text}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex">
        <button
          onClick={() => toast.dismiss(t.id)}
          className="bg-transparent border-none hover:none hover:bg-transparent"
        >
          <svg
            className="w-5 h-5"
            fill={`${isErrorOrSuccess ? "#282828" : "#515151"}`}
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  ));
};
