const Logo = ({ color = "#450B70", width = 27, height = 31 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 27 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.918 1L25.5146 7.59663L18.918 14"
        stroke={color}
        stroke-width="2"
      />
      <path
        d="M8.59766 30.0967L2.00103 23.5L8.59766 17.0967"
        stroke={color}
        stroke-width="2"
      />
      <path d="M25 8.08105L5 8.08105" stroke={color} stroke-width="2" />
      <path
        d="M2.51562 23.0156L22.5156 23.0156"
        stroke={color}
        stroke-width="2"
      />
    </svg>
  );
};

export default Logo;
