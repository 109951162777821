import TwoColContent from "../../../../../templates/twoColContent";
import EmailCheck from "../../../../../assets/icons/email_check_icon.png";
import { SideBar } from "../../../components";
import { useCheckYourEmail } from "../../../hooks";
import { Button } from "../../../../../components";

const RightContent = ({ location }) => {
  const { loading, resendVerificationMail } = useCheckYourEmail({
    email: location?.state?.email,
  });

  return (
    <div className="max-w-[440px] h-full m-auto flex flex-col gap-4 items-center justify-center">
      <img src={EmailCheck} alt="Email check logo" />
      <h1 className="text-float-grey-100 text-2xl">Check your email</h1>
      <p className="text-center text-float-grey-90">
        {location?.state?.email ? (
          <>
            We have now sent an activation link to{" "}
            <span className="font-semibold">{location?.state?.email}</span>.
            Click the link to activate your account and continue here.
          </>
        ) : (
          <>
            We have now sent an activation link to your email. Click the link to
            activate your account and continue here.
          </>
        )}
      </p>
      {location?.state?.email && (
        <Button
          className="secondary mt-4"
          onClick={resendVerificationMail}
          loading={loading}
        >
          Resend activation email
        </Button>
      )}
    </div>
  );
};

export const CheckYourEmail = ({ location }) => {
  return (
    <TwoColContent
      leftContent={<SideBar />}
      rightContent={<RightContent location={location} />}
    />
  );
};
