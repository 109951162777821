import { useContext } from "react";
import { InfoModal } from "../../../../../components";
import { postNDA } from "../../../../../api/nda";
import { UserContext } from "../../../../../hooks/useWhoAmI";
import { Toast } from "../../../../../components/toast";
import { ToastType } from "../../../../../types";

export const NDAModal = ({ hide, visible }) => {
  const { me } = useContext(UserContext);
  const onHandleClick = async () => {
    try {
      await postNDA(me.selected_company.id);
      Toast({
        type: ToastType.SUCCESS,
        title: "Yes, success!",
        text: "NDA request sent!",
      });
    } catch (err) {
      Toast({
        type: ToastType.ERROR,
        title: "Oh no, there was an error!",
        text: `${err.response.data}`,
      });
    } finally {
      hide();
    }
  };
  return (
    <InfoModal
      hide={hide}
      visible={visible}
      title="I would like to request an NDA"
      closeText="Never mind"
      content={<div className="md:w-[400px]"></div>}
      submitButton={<button onClick={onHandleClick}>Yes</button>}
    />
  );
};
