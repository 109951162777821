import { SSN_LOOKUP } from "../../../../common/constants";
import Input from "../../../../components/input";
import { useSignUpBankID } from "../../automated/hooks";
import BankIDButton from "../../../../components/bankIDButton";

export const SignUpBankID = ({ country }) => {
  const { formik } = useSignUpBankID({ country });
  const getButtonText = () => {
    switch (country?.code) {
      case "dk":
        return "Continue with NemID";
      default:
        return "Continue with BankID";
    }
  };

  return (
    <form className="w-full" onSubmit={formik.handleSubmit}>
      <Input
        type="text"
        id="ssn"
        label="Personal number"
        required
        onChange={formik.handleChange}
        placeholder={SSN_LOOKUP[country.code].placeholder}
        pattern={`[0-9]{${SSN_LOOKUP[country.code].placeholder.length}}`}
        value={formik.values.ssn}
        error={formik.errors.ssn}
        disabled={formik.isSubmitting}
        className={`w-full`}
      />
      <div className="w-full flex flex-col items-center gap-2 mb-7">
        <BankIDButton
          text={getButtonText()}
          className="w-full mt-8"
          country={country}
        />
        <p className="text-float-grey-90 text-sm">
          No commitments, no personal credit check
        </p>
      </div>
    </form>
  );
};
