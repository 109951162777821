import { Button } from "../../components";
import { SecurePatternLayout } from "../../templates/SecurePatternLayout";
import { useMailToSupport } from "../../utils";

const NoCompany = ({ history }) => {
  const { mailToSupportLink} = useMailToSupport();
  
  return (
    <SecurePatternLayout>
      <div className="h-fit drop-shadow-large bg-white rounded-2xl p-12 flex flex-col items-center w-[480px] z-10 px-12 pt-14 pb-12">
        <h1 className="text-2xl	text-float-purple-1">Oh no, your user is not linked to any company.</h1>
        <p className="mt-4 text-base text-float-grey-90">
          We can’t find a company associated with your user details. Sign up and register your company first.
        </p>
        <Button className="w-full secondary mt-6" onClick={() => history.push("/signup")}>Create your account</Button>
        <div className="w-full flex items-center mt-6">
          <span className="h-px bg-float-grey-10 flex-1"/>
          <p className="text-float-grey-90 text-sm mx-2">OR</p>
          <span className="h-px bg-float-grey-10 flex-1"/>
        </div>
        <p className="mt-6 text-base text-float-grey-90">
          If you believe there was an error, please email us at <a href={mailToSupportLink}>support@gofloat.io</a>
        </p>
        <Button className="secondary w-full mt-6" onClick={() => history.push("/login")}>Back to login page</Button>
      </div>
    </SecurePatternLayout>
  );
};

export default NoCompany;
