import * as Yup from "yup";

const useGetValidationSchema = () => {
  const validationSchema = (formatter, minLoanAmount, currency, total_credit, used_credit) => (
    Yup.object({
    amount: Yup.number()
      .min(
        minLoanAmount,
        `Amount must be at least ${formatter.format(
          minLoanAmount
        )} ${currency}`
      )
      .max(
        total_credit - used_credit,
        `Amount can not be more than ${formatter.format(
          total_credit - used_credit
        )} ${currency}`
      )
      .required(),
  })
  );
  return {
    validationSchema,
  }
} 

export default useGetValidationSchema;