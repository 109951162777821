import { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { exchangeCode, getWhoAmI } from "../../../api";
import { SideBar } from "../components";
import { UserContext } from "../../../hooks/useWhoAmI";
import TwoColContent from "../../../templates/twoColContent";
import useQuery from "../../../hooks/useQuery";
import Loading from "../../../components/loading";

const ExhangeCodeAutomated = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { login } = useContext(UserContext);

  const search = useQuery();
  const history = useHistory();
  const code = search.get("code");

  useEffect(() => {
    async function fetchData() {
      try {
        if (code) {
          const startLogin = await exchangeCode(code);
          if (startLogin.access) {
            login(startLogin);
            const whoamI = await getWhoAmI();
            if (whoamI.company.length > 0) {
              history.push("/signup/automated?current_step=1");
            } else {
              history.push("/signup/automated");
            }
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  if (isLoading) {
    return (
      <TwoColContent
        leftContent={<SideBar />}
        rightContent={
          <div className="flex items-center justify-center h-full">
            <Loading />
          </div>
        }
      />
    );
  }
};

export default ExhangeCodeAutomated;
