const Logo = ({ color = "#6F3F91", width = 109, height = 29 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 109 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1377_6763)">
        <path
          d="M0 28.9982H6.57804C6.58087 26.5282 7.5667 24.1602 9.31922 22.4138C11.0717 20.6674 13.4478 19.6853 15.926 19.683V13.1267C11.7042 13.1323 7.6569 14.8062 4.67147 17.7814C1.68605 20.7567 0.00611477 24.7904 0 28.9982Z"
          fill={color}
        />
        <path
          d="M0 15.8768H6.57804C6.58087 13.4067 7.56664 11.0385 9.31911 9.29184C11.0716 7.54517 13.4476 6.56266 15.926 6.55985V0.00357056C11.7039 0.0091964 7.65632 1.68336 4.67085 4.65896C1.68537 7.63456 0.00564453 11.6687 0 15.8768Z"
          fill={color}
        />
        <path
          d="M29.4336 6.37901V9.85915H26.6602V14.7888H29.4336V28.9982H34.3796V14.7888H39.4713V9.85915H34.3707V6.37901C34.3712 6.03327 34.5092 5.70182 34.7545 5.45734C34.9997 5.21286 35.3323 5.07531 35.6792 5.07484H39.4625V0.145233H35.6792C34.0225 0.149452 32.435 0.807856 31.2644 1.97626C30.0938 3.14466 29.4355 4.72782 29.4336 6.37901Z"
          fill={color}
        />
        <path
          d="M48.0436 -4.57764e-05H43.0977V24.407C43.0986 25.5872 43.5698 26.7188 44.4076 27.5528C45.2454 28.3869 46.3812 28.8551 47.5654 28.8547H50.2322V23.9233H48.0436V-4.57764e-05Z"
          fill={color}
        />
        <path
          d="M109.001 10.8745V5.94136H104.782V-4.57764e-05H99.8359V24.407C99.8373 25.5859 100.308 26.7161 101.144 27.5498C101.98 28.3835 103.114 28.8528 104.297 28.8547H109.001V23.9233H104.782V10.8745H109.001Z"
          fill={color}
        />
        <path
          d="M62.309 8.48239C60.2839 8.48239 58.3043 9.08093 56.6205 10.2023C54.9367 11.3237 53.6244 12.9176 52.8495 14.7824C52.0746 16.6471 51.872 18.6991 52.2672 20.6787C52.6624 22.6582 53.6377 24.4765 55.0698 25.9036C56.5018 27.3307 58.3264 28.3025 60.3126 28.696C62.2988 29.0896 64.3575 28.8873 66.2283 28.1146C68.0992 27.342 69.6981 26.0337 70.8229 24.3553C71.9478 22.6769 72.5479 20.7038 72.5476 18.6854C72.5448 15.9799 71.4651 13.3861 69.5455 11.4733C67.626 9.56039 65.0234 8.48474 62.309 8.48239ZM62.309 23.9605C61.2622 23.9605 60.2389 23.6511 59.3685 23.0715C58.4981 22.4919 57.8198 21.668 57.4192 20.7041C57.0186 19.7402 56.9138 18.6795 57.118 17.6563C57.3222 16.633 57.8263 15.693 58.5665 14.9553C59.3067 14.2175 60.2497 13.7151 61.2764 13.5116C62.3031 13.308 63.3673 13.4125 64.3344 13.8118C65.3015 14.211 66.1281 14.8872 66.7096 15.7547C67.2912 16.6222 67.6016 17.642 67.6016 18.6854C67.6002 20.084 67.0421 21.4249 66.0499 22.4139C65.0576 23.4029 63.7122 23.9591 62.309 23.9605Z"
          fill={color}
        />
        <path
          d="M91.3013 10.1391C89.7564 9.13474 87.968 8.56494 86.125 8.48996C84.282 8.41497 82.453 8.83759 80.8311 9.71315C79.2092 10.5887 77.8548 11.8847 76.9109 13.4641C75.967 15.0436 75.4688 16.8478 75.4688 18.6862C75.4688 20.5246 75.967 22.3289 76.9109 23.9083C77.8548 25.4878 79.2092 26.7837 80.8311 27.6593C82.453 28.5349 84.282 28.9575 86.125 28.8825C87.968 28.8075 89.7564 28.2377 91.3013 27.2333V29.0053H96.2437V8.4097H91.3013V10.1391ZM85.717 23.9605C84.6702 23.9605 83.6469 23.6511 82.7766 23.0715C81.9062 22.4918 81.2278 21.668 80.8272 20.704C80.4267 19.7401 80.3218 18.6795 80.5261 17.6562C80.7303 16.6329 81.2343 15.693 81.9745 14.9552C82.7147 14.2175 83.6578 13.7151 84.6845 13.5115C85.7112 13.308 86.7753 13.4125 87.7424 13.8117C88.7095 14.211 89.5361 14.8871 90.1177 15.7546C90.6993 16.6221 91.0097 17.642 91.0097 18.6853C91.0083 20.0846 90.4497 21.426 89.4567 22.4151C88.4636 23.4042 87.1174 23.96 85.7135 23.9605H85.717Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1377_6763">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Logo;
