import * as React from "react";

function Logo({ dark = false, width = 150, height = 50 }) {
  const color = dark ? "#1D1D1B" : "#FFFFFF";
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 52 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_9093_57380)">
        <path
          d="M23.1013 1.67285V4.31436H25.1472V6.42757H23.1013V10.654C23.1013 11.4464 23.5275 11.7106 24.2095 11.7106C24.6357 11.7106 25.0619 11.6225 25.3177 11.3584V13.7357C24.9767 13.9118 24.2947 13.9999 23.6128 13.9999C21.7374 13.9999 20.5439 13.0313 20.5439 10.9181V6.42757H19.0947V4.31436H20.5439V1.67285H23.1013Z"
          fill={color}
        />
        <path
          d="M44.2428 0.175781C49.6985 0.175781 52.0001 3.25754 52.0001 7.04371C52.0001 10.8299 49.6985 13.9116 44.2428 13.9116H42.0264V0.175781H44.2428Z"
          fill={color}
        />
        <path
          d="M34.4393 0C32.6492 0 31.2 1.49688 31.2 3.34594C31.2 5.19499 32.6492 6.69179 34.4393 6.69179C36.2295 6.69179 37.6786 5.19499 37.6786 3.34594C37.6786 1.49688 36.2295 0 34.4393 0ZM34.4393 7.92449C31.4557 7.92449 28.9836 9.68554 28.6426 13.912H40.3213C39.9803 9.68554 37.5082 7.92449 34.4393 7.92449Z"
          fill={color}
        />
        <path
          d="M2.47213 0.704102L6.39344 6.95567L10.2295 0.704102H12.7016V13.9116H10.059V5.37077L6.43607 11.006H6.35082L2.64262 5.37077V13.9116H0V0.704102H2.47213Z"
          fill={color}
        />
        <path
          d="M16.3674 0C15.5149 0 14.833 0.704363 14.833 1.58487C14.833 2.46537 15.5149 3.16981 16.3674 3.16981C17.2199 3.16981 17.9019 2.46537 17.9019 1.58487C17.9019 0.704363 17.2199 0 16.3674 0ZM15.0887 4.31449V13.912H17.6461V4.31449H15.0887Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_9093_57380">
          <rect width="52" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Logo;
