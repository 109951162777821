const Step = ({ step, title, text }) => {
  return (
    <div className="grid grid-cols-[48px_3fr]">
      <div className="text-float-purple-3 text-4xl lg:text-5xl leading-10 w-6 flex justify-center">{step}</div>
      <div className="flex flex-col gap-1 text-white max-w-[280px]">
        <h3 className="text-sm lg:text-base font-bold leading-5">{title}</h3>
        <p className="text-sm leading-5">{text}</p>
      </div>
    </div>
  )
}

export const HowItWorks = () => {
  return (
    <div className="flex flex-col gap-8">
      <div className="border-float-purple-3 border-b pb-6">
        <h2 className="text-white">How it works</h2>
      </div>
      <Step 
        step={1}
        title="Create your account"
        text="Tell us a bit about you and your company first to set up your account."
      />
      <Step 
        step={2}
        title="Connect your financials"
        text="We need your financial info to assess your credit rating and give you an individual credit offer, NDA option available in this step."
      />
      <Step 
        step={3}
        title="Get credit offer"
        text="We will get back to you with an offer within 72 hours. After that you can configure your loan to your needs."
      />
    </div>
  )
}