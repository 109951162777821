const Logo = ({ color = "#2B85CF", width = 110, height = 35 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 110 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M72.0852 12.7892H67.9978C68.2609 10.7342 68.8663 10.5408 70.9637 10.5408H72.3615L72.8064 6.62749H71.0084C66.1609 6.62749 63.9386 8.07833 63.4126 12.7917L61.5757 28.5058H66.0997L67.4718 16.7192H71.6403L72.0852 12.7892ZM55.3139 12.7725H52.2745C48.216 12.7725 45.8622 14.2333 45.1989 19.345L44.1535 28.5033H48.5783L49.6237 19.8525C49.8826 17.6233 50.4409 16.9617 52.5375 16.9617H54.8036L55.3139 12.7717V12.7725ZM73.9031 12.7725H78.3403L76.5125 28.5058H72.0794L73.9031 12.7725ZM57.1384 12.7725H61.5757L59.7478 28.5058H55.3148L57.1384 12.7725ZM74.6152 6.61416H79.0491L78.5942 10.5475H74.1562L74.6152 6.61416ZM57.8506 6.61416H62.2836L61.8288 10.5475H57.3915L57.8506 6.61416ZM91.0854 25.3658H86.0709C83.3424 25.3658 82.3806 23.9075 82.6212 21.9325H86.5448C90.2807 21.9325 92.441 20.0517 92.637 17.2908C92.8033 14.2142 90.021 12.8142 87.0808 12.8142C82.9322 12.8142 79.8191 15.4892 79.3535 19.6058C79.2509 20.5308 79.2195 20.7 79.088 21.9333C78.7671 24.8517 80.341 28.5025 85.0958 28.5025H90.7133L91.0854 25.3658ZM86.4878 15.8617C88.2312 15.8617 89.1931 16.3175 88.9309 17.5892C88.7763 18.5233 87.869 18.9217 86.1768 18.9217H82.9289C83.0302 18.0559 83.4524 17.2606 84.1108 16.695C84.7719 16.1276 85.6199 15.8303 86.4878 15.8617ZM104.5 6.61416L103.698 13.3125C102.972 12.9645 102.178 12.7813 101.373 12.7758C97.2248 12.7758 94.7014 15.3442 94.2333 19.4742C94.1307 20.3992 94.0984 20.5683 93.9669 21.8017C93.6469 24.72 95.1281 28.4642 99.883 28.4642H106.33L108.927 6.63083L104.5 6.61416ZM103.124 19.74C102.829 22.1 102.518 24.8775 102.518 24.8775H100.386C97.9493 24.8775 97.5002 23.195 97.6796 21.7217L97.8823 19.955C98.2024 17.5633 99.4463 16.0092 101.566 16.3367C103.198 16.6033 103.358 17.8233 103.118 19.74H103.124ZM4.17965 9.73499H9.62341V4.29583H21.8905V16.9625L25.6834 8.41916V0.455826H6.51364L0.386719 6.59749V34.6258H21.3099L23.0988 30.8208H4.17965V9.73499Z"
        fill={color}
      />
      <path
        d="M31.8506 0.455826L21.8539 22.55L17.8087 13.54H13.125L19.7225 28.5025H24.1539L36.6212 0.455826H31.8506ZM42.3924 25.3658H37.3779C34.6494 25.3658 33.6884 23.9075 33.9291 21.9325H37.8494C41.5844 21.9325 43.7488 20.0517 43.9415 17.2908C44.1078 14.2142 41.3247 12.8142 38.3853 12.8142C34.2359 12.8142 31.1261 15.4892 30.658 19.6058C30.5555 20.5308 30.5265 20.7 30.3917 21.9333C30.0716 24.8517 31.6455 28.5025 36.4003 28.5025H41.9988L42.3924 25.3658ZM37.7948 15.8617C39.5424 15.8617 40.5009 16.3175 40.2379 17.5892C40.0841 18.5233 39.1768 18.9217 37.4871 18.9217H34.2367C34.3383 18.0557 34.7607 17.2605 35.4194 16.695C36.08 16.1279 36.9275 15.8306 37.7948 15.8617Z"
        fill={color}
      />
    </svg>
  );
};

export default Logo;
