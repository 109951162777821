import Company from "../company";
// import AdminLoan from "../adminLoan";

const CompanyWrapper = ({ id }) => {
  if (!id) {
    return "";
  }
  return (
    <>
      <Company id={id} />
      {/* <AdminLoan id={id} /> */}
    </>
  );
};

export default CompanyWrapper;
