import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { UserContext } from "../../../../../../hooks/useWhoAmI";
import { validationSchema } from "../validation";
import { Toast } from "../../../../../../components";
import { ToastType } from "../../../../../../types";
import { postAdditionalQuestions } from "../../../../../../api";


export const useSubmitConnectForm = () => {
  const { me, updateWhoAmI } = useContext(UserContext);
  const history = useHistory();

  const initialValues = {
    start_timespan: '',
    currency: '',
    amount: 0,
    comment: '',
    loan_taker: {},
  };

  const onSubmit = async (data) => {
    const normalizedLoanTakers = Object.entries(data.loan_taker)
    .filter(([key, value]) => value)
    .map(([key]) => key);

    const values = {
      ...data,
      loan_taker: normalizedLoanTakers,
      company: me.selected_company.id
    }
    try {
      await postAdditionalQuestions(values);
      updateWhoAmI();
      history.push('/dashboard/success');
    }
    catch (e) {
      Toast({
        type: ToastType.ERROR,
        title: 'Oh no, error',
        text: `Error: ${e.response.data}`
      })
    }
  }

  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validationSchema,
  });

  return {
    formik,
  }
}