import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router";
import { Button } from "../../../../components";
import Input from "../../../../components/input";
import { useSignUpEmailForm } from "../../hooks";
import { Toast } from "../../../../components/toast";
import { ToastType } from "../../../../types";

export const SignUpEmail = () => {
  const history = useHistory();

  const { formik, loading, submitError, done, email } = useSignUpEmailForm();

  useEffect(() => {
    if (
      submitError &&
      submitError.filter((error) => error === "Email needs to be unique")
    ) {
      Toast({
        type: ToastType.INFO,
        title: "Email already in use!",
        text: "Try logging in instead or reset your password!",
      });
      history.push("/login");
    }
  }, [submitError, history]);

  if (done && email) {
    return (
      <Redirect
        to={{
          pathname: "/email-verify",
          state: { email },
        }}
      />
    );
  }

  return (
    <>
      <div className="w-full flex items-center mt-6">
        <span className="h-px bg-float-grey-10 flex-1" />
        <p className="text-float-grey-90 text-sm mx-2">OR</p>
        <span className="h-px bg-float-grey-10 flex-1" />
      </div>
      <form onSubmit={formik.handleSubmit} className="w-full">
        <Input
          type="text"
          name="email"
          id="email"
          placeholder="name.lastname@company.se"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.errors.email || submitError}
        />
        <Button loading={loading} type="submit" className="w-full mt-8">
          Continue with email
        </Button>
      </form>
    </>
  );
};
