import { BjornLunden, Dynamics, Fortnox, PeAccounting, Visma } from "./logos";

export const useGetAccountingSoftware = () => {
  const accountingSoftware = [
    {
      logo: Fortnox,
      name: 'Fortnox'
    }, 
    {
      logo: Dynamics,
      name: 'Dynamics 365',
    },
    {
      logo: Visma,
      name: 'Visma',
    },
    {
      logo: PeAccounting,
      name: 'P:e accounting',
    },
    {
      logo: BjornLunden,
      name: 'Björn Lundén',
    }
  ]

  return {
    accountingSoftware,
  }
}