import { useState, useEffect, useContext } from "react";
import { doPut, doPost } from "../../common/axiosUtil";
import { Toast } from "../toast";
import { ToastType } from "../../types";
import { CountryContext } from "../../hooks/useCountry";
import { ConnectButton } from "../connectButton";

const StartBalanceFlows = async (
  url,
  session_id,
  accounts,
  setXS2AInitiated,
  callback
) => {
  /*
  Initiate a flow for each account and fetch the balances.
  */
  const balanceFlow = await doPut("v1/ais/flow/", {
    url: url,
    session_id: session_id,
    type: "balance",
    account_number: accounts[0]?.account_number,
    account_id: accounts[0]?.id,
    iban: accounts[0]?.iban,
  });
  try {
    window.XS2A.startFlow(balanceFlow.client_token, {
      onFinished: async () => {
        accounts.shift();
        if (accounts.length === 0) {
          await doPost("v1/ais/flow/", {
            url: balanceFlow.session_url,
            flow_id: balanceFlow.flow_id,
            final: true,
          });
          setXS2AInitiated(false);
          callback();
        } else {
          await doPost("v1/ais/flow/", {
            url: balanceFlow.session_url,
            flow_id: balanceFlow.flow_id,
          });
          StartBalanceFlows(
            url,
            session_id,
            accounts,
            setXS2AInitiated,
            callback
          );
        }
      },
      onError: (error) => {
        console.error(
          "onError: something bad happened during the flow.",
          error
        );
        Toast({
          type: ToastType.ERROR,
          title: "Oh no, there was an error",
          text: error.message,
        });
        setXS2AInitiated(false);
      },
    });
  } catch (e) {
    // Handle error that happened while opening the App
    console.error(e);
    Toast({
      type: ToastType.ERROR,
      title: "Oh no, there was an error",
      text: e,
    });
    setXS2AInitiated(false);
  }
};

const StartTransactionFlows = async (
  url,
  session_id,
  accounts,
  setXS2AInitiated,
  callback,
  balanceUrl,
  balanceAccounts
) => {
  /*
  Initiate a flow for each account and fetch the transactions.
  */
  const transactionFlow = await doPut("v1/ais/flow/", {
    url: url,
    session_id: session_id,
    type: "transaction",
    account_number: accounts[0]?.account_number,
    account_id: accounts[0]?.id,
    iban: accounts[0]?.iban,
  });
  try {
    window.XS2A.startFlow(transactionFlow.client_token, {
      onFinished: async () => {
        accounts.shift();
        if (accounts.length === 0) {
          StartBalanceFlows(
            balanceUrl,
            session_id,
            balanceAccounts,
            setXS2AInitiated,
            callback
          );
          setXS2AInitiated(false);
          callback();
        } else {
          await doPost("v1/ais/flow/", {
            url: transactionFlow.session_url,
            flow_id: transactionFlow.flow_id,
          });
          StartTransactionFlows(
            url,
            session_id,
            accounts,
            setXS2AInitiated,
            callback,
            balanceUrl,
            balanceAccounts
          );
        }
      },
      onError: (error) => {
        console.error(
          "onError: something bad happened during the flow.",
          error
        );
        Toast({
          type: ToastType.ERROR,
          title: "Oh no, there was an error",
          text: error.message,
        });
        setXS2AInitiated(false);
      },
    });
  } catch (e) {
    // Handle error that happened while opening the App
    console.error(e);
    Toast({
      type: ToastType.ERROR,
      title: "Oh no, there was an error",
      text: e,
    });
    setXS2AInitiated(false);
  }
};

export const BankSync = ({ callback }) => {
  const [XS2AInitiated, setXS2AInitiated] = useState(false);
  const { country } = useContext(CountryContext);

  useEffect(() => {
    const startFlow = async (flows, session_id) => {
      const accountFlow = await doPut("v1/ais/flow/", {
        url: flows.accounts,
        session_id: session_id,
        type: "account",
      });
      try {
        window.XS2A.startFlow(accountFlow.client_token, {
          hideTransitionOnFlowEnd: false,
          autoClose: false,
          onFinished: async () => {
            const accountResult = await doPost("v1/ais/flow/", {
              url: accountFlow.session_url,
              flow_id: accountFlow.flow_id,
            });
            StartTransactionFlows(
              flows.transactions,
              session_id,
              accountResult.result.accounts,
              setXS2AInitiated,
              callback,
              flows.balances,
              [...accountResult.result.accounts]
            );
          },
          onError: (error) => {
            console.error(
              "onError: something bad happened during the flow.",
              error
            );
            Toast({
              type: ToastType.ERROR,
              title: "Oh no, there was an error",
              text: error,
            });
            setXS2AInitiated(false);
          },
          onAbort: () => {
            setXS2AInitiated(false);
          },
          onClose: () => {
            setXS2AInitiated(false);
          },
        });
      } catch (e) {
        // Handle error that happened while opening the App
        console.error(e);
        Toast({
          type: ToastType.ERROR,
          title: "Oh no, there was an error",
          text: e,
        });

        setXS2AInitiated(false);
      }
    };

    const initiateBankSync = async () => {
      const res = await doPut("v1/ais/account/session/", {
        country_code: country?.code,
        consent_scope: ["accounts", "transactions", "balances"],
      });
      startFlow(res.flows, res.session_id);
    };
    if (XS2AInitiated) {
      initiateBankSync();
    }
  }, [XS2AInitiated, callback, country]);

  const initiateXS2AApp = () => {
    setXS2AInitiated(true);
  };
  return (
    <>
      <ConnectButton
        text="Connect"
        onClick={initiateXS2AApp}
        disabled={XS2AInitiated}
      />
    </>
  );
};
