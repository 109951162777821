import { Icon } from "@iconify/react";

const Circle = ({ color, width, height }) => {
  return (
    <Icon
      icon="akar-icons:circle-fill"
      color={color}
      width={width}
      height={height}
    />
  );
  // #b4002b
};
export default Circle;
