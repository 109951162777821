export const StorageKeys = {
  TOKEN: "token",
  REFRESH: "refresh",
  COMPANYDETAILS: "company_details",
  ORG: "org",
  COUNTRY: "country",
  EMAIL: "email",
  COUNTRY_CODE: "country_code",
  SUBSCRIPTION: "subscription",
  ACCOUNTING: "accounting",
  TRANSACTION: "transaction",
};

export const getLocalStorageItem = (key) => window.localStorage.getItem(key);

export const saveToken = (token) => {
  if (token.refresh) {
    localStorage.setItem(StorageKeys.REFRESH, token.refresh);
  }
  if (token.access) {
    localStorage.setItem(StorageKeys.TOKEN, token.access);
  }
};

export const clearToken = (both = true) => {
  localStorage.removeItem(StorageKeys.TOKEN);
  if (both) {
    localStorage.removeItem(StorageKeys.REFRESH);
    clearOrgNr();
  }
};

export const getTokenAccess = () => localStorage.getItem(StorageKeys.TOKEN);
export const getTokenRefresh = () => localStorage.getItem(StorageKeys.REFRESH);

export const clearOrgNr = () => localStorage.removeItem(StorageKeys.ORG);
export const saveOrgNr = (orgNr) => {
  const buffer = Buffer.from(orgNr);
  localStorage.setItem(StorageKeys.ORG, buffer.toString("base64"));
};
export const getOrgNr = () => {
  const item = localStorage.getItem(StorageKeys.ORG);
  if (!item) {
    return null;
  }
  const buffer = Buffer.from(item, "base64");
  return buffer.toString();
};

export const clearAll = () => {
  localStorage.clear();
};

export const saveCompanyDetails = (details) =>
  localStorage.setItem(StorageKeys.COMPANYDETAILS, JSON.stringify(details));
export const getCompanyDetails = () =>
  localStorage.getItem(StorageKeys.COMPANYDETAILS);
export const clearCompanyDetails = () =>
  localStorage.removeItem(StorageKeys.COMPANYDETAILS);

export const setExpandedAccordion = (key) => localStorage.setItem(key, true);
export const getExpandedAccordion = (key) => localStorage.getItem(key);
export const removeExpandedAccordion = (key) => localStorage.removeItem(key);
