import Input from "../../../components/input";
import { useYourDetailsForm } from '../hooks/useYourDetailsForm';
import { SettingsLayout } from "../../../templates/SettingsLayout";

const YourDetails = () => {
  const { formik, isLoading, error } = useYourDetailsForm();

  return (
    <SettingsLayout>
      <div className="mb-6 h-full">
        <form onSubmit={formik.handleSubmit}
          className="flex flex-col gap-3 max-w-xl"
        >
          <Input 
            label="First name"
            placeholder="First name"
            type="text"
            name="company_name"
            id="first_name"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            error={formik.errors.first_name || error?.first_name}
          />
          <Input 
            label="Last name"
            placeholder="Last name"
            type="text"
            name="last_name"
            id="last_name"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            error={formik.errors.last_name || error?.last_name}
          />
          <Input 
            label="Email"
            placeholder="Email"
            type="text"
            name="email"
            id="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.errors.email || error?.email}
          />
          <Input 
            label="Phone number"
            placeholder="Phone number"
            type="text"
            name="phone_number"
            id="phone_number"
            value={formik.values.phone_number}
            onChange={formik.handleChange}
            error={formik.errors.phone_number || error?.phone_number}
          />
          <div 
            className="h-px bg-float-purple-5 w-full my-3"
          />
          <button type="submit" className="w-[170px] mt-8 bg-transparent" disabled={isLoading}>Save changes</button>
        </form>
      </div>
    </SettingsLayout>
  )
}

export default YourDetails;