import { Redirect, withRouter } from "react-router";
import { useContext, useEffect } from "react";
import { UserContext } from "../../hooks/useWhoAmI";
import Loading from "../../components/loading";
import Overview from "../../components/overview";
import Onboard from "./onboard";
import useAPI from "../../hooks/useAPI";

const Dashboard = () => {
  const { me, updateWhoAmI } = useContext(UserContext);
  const [result, loading] = useAPI({ me, path: "v1/credit_offer/" });

  useEffect(() => {
    if (me && !me.selected_company) {
      updateWhoAmI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  if (loading) {
    return (
      <div className="bg-float-purple-6 w-screen min-h-screen max-h-full flex items-center justify-center">
        <Loading />
      </div>
    );
  }

  if (result.length > 0) {
    return <Overview />;
  }

  if (!me.selected_company) {
    return <Redirect to="/signup" />;
  }

  return <Onboard />;
};

export default withRouter(Dashboard);
