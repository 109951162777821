import { useContext, useEffect, useState } from "react";
import { UserContext } from "./useWhoAmI";
import { ToastType } from "../types";
import { Toast } from "../components/toast";

export const useFileUploader = () => {
  const { me } = useContext(UserContext);
  const [files, setFile] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadComplete, setUploadComplete] = useState(false);

  const onHandleSubmit = async (e, postEndpoint, setFileUploadError) => {
    e.preventDefault();

    if (files.length < 1) {
      return setFileUploadError("You need to upload a file to continue");
    }

    for (const file of files) {
      const formdata = new FormData();
      formdata.append("file", file);
      formdata.append("company", parseInt(me.selected_company.id));
      // TODO: error handling

      const uploadedFile = await postEndpoint(formdata);
      if (uploadedFiles.length > 0) {
        const fileList = uploadedFiles.push(uploadedFile);
        setUploadedFiles(fileList);
      } else {
        uploadedFiles.push(uploadedFile);
        setUploadedFiles([uploadedFile]);
      }
    }

    setUploadComplete(true);
    return uploadedFiles;
  };

  const onHandleRemove = (file) => {
    setFile(files.filter(({ name }) => file.name !== name));
  };

  useEffect(() => {
    if (uploadComplete) {
      if (files.length === 1) {
        Toast({
          type: ToastType.SUCCESS,
          title: "That worked great!",
          text: `Successfully uploaded ${files[0].name}`,
        });
      } else {
        Toast({
          type: ToastType.SUCCESS,
          title: "That worked great!",
          text: `Successfully uploaded ${files.length} files`,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadComplete]);

  return {
    files,
    setFile,
    uploadComplete,
    onHandleSubmit,
    onHandleRemove,
  };
};
