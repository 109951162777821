import { Route } from "react-router-dom";
import { logout } from "../common";
import { getLocalStorageItem, StorageKeys } from "../common/localstorage";
import User from "../hooks/useWhoAmI";

const SignUpRoute = ({ children, ...rest }) => {
  let isAuthenticated =
    getLocalStorageItem(StorageKeys.REFRESH) !== null ? true : false;
  const redirect = () => {
    logout("/login");
  };

  if (!isAuthenticated) {
    redirect();
    return null;
  }

  return (
    <Route
      {...rest}
      render={() => {
        return (
          <User>
            {children}
          </User>
        );
      }}
    />
  );
};

export default SignUpRoute;
