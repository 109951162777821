import { useState, useEffect } from "react"
import { getBankConnectionsList } from "../../../../../api";
import { capitalize } from "../../../../../common/utils";

export const useGetBankConnections = () => {
  const [bankConnections, setBankConnections] = useState([]);

  useEffect(() => {
    getBankConnectionsList()
      .then((res) => { 
        const connections = res.map((connection) => ({
          ...connection,
          bank_name: capitalize(connection.bank_name)
        }))
        setBankConnections(connections)
      })
      .catch((err) => console.log(err));
      // TODO: handle error
  }, [])

  return {
    bankConnections,
  }
}