import { useState, useEffect } from "react"
import { getUsers } from '../../../../api';

export const useUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getUsers().then((res) => setUsers(res))
    .finally(() => setLoading(false))
  }, [])


  return {
    users,
    loading
  }
}