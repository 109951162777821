export const useFileListFormat = () => {
  const getFileListFormat = (files) => {
    return files.map((file) => (
      {
        ...file,
        name: file.file_name.split("/").pop(),
        size: file.file_size,
      }
    ))
  }
  
  return {
    getFileListFormat,
  }
}