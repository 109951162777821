import { Icon } from "@iconify/react";

export const Item = ({
  repaid_amount,
  credit_offer,
  credit_amount,
  index,
  country,
  activation_date,
  interest,
  agreement,
}) => {
  const formatter = new Intl.NumberFormat(country);
  const loanAmount = `${formatter.format(credit_amount)} ${
    credit_offer.currency
  }`;
  const date = new Date(activation_date).toLocaleString("en-En", {
    month: "short",
    day: "numeric",
  });
  const isRepaid =
    credit_amount - repaid_amount === 0 ? (
      <Icon icon="mdi:check-circle-outline" width={16} color="#79AA36" />
    ) : (
      <Icon icon="mdi:cancel" width={16} color="#ff726f" />
    );
  const getInterestRate = () => {
    if (interest.toString().length > 2) {
      return interest.toFixed(2);
    }
    return interest;
  };
  return (
    <tr className="text-black border-b-gray-100 border-b-2">
      <td className="px-2">
        {agreement ? (
          <a href={agreement} target="_blank" rel="noreferrer">
            <p className="font-bold text-sm md:text-base">{`#${index + 1}`}</p>
          </a>
        ) : (
          <p className="font-bold text-sm md:text-base">{`#${index + 1}`}</p>
        )}
      </td>
      <td className="px-2">
        <p className="font-bold text-sm md:text-base">{loanAmount}</p>
      </td>
      <td className="px-2">
        <p className="font-bold text-sm md:text-base">{date}</p>
      </td>
      <td className="px-2">
        <p className="font-bold text-sm md:text-base">{`${getInterestRate()} %`}</p>
      </td>
      <td className="px-2 text-sm md:text-base">{isRepaid}</td>
    </tr>
  );
};
