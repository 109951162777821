import { doGet, doPatch } from "../common/axiosUtil";

export const getCompanyDetails = async (id) => {
  const get = await doGet(`v1/admin/company/${id}/`);
  return get;
};

export const patchCompanyDetails = async (id, data) => {
  const patch = await doPatch(`v1/admin/company/${id}/`, data);
  return patch;
};

export const getCompanyLoans = async (id) => {
  const get = await doGet(`v1/admin/company/${id}/loan/`);
  return get;
};
