import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({
  labels,
  currentLoans,
  availableCredit,
  expiredCredit,
  has_any_valid_credit_offer,
  total,
  centerText,
  position,
}) => {
  const getData = () => {
    if (has_any_valid_credit_offer) {
      return [currentLoans, availableCredit];
    } else {
      return [currentLoans, expiredCredit];
    }
  };

  const getBackGroundColor = () => {
    if (has_any_valid_credit_offer) {
      return ["#450b70", "#FFC29C"];
    } else {
      return ["#450b70", "#959595"];
    }
  };

  const data = {
    labels,
    cutout: "70%",
    hoverOffset: 0,
    datasets: [
      {
        label: "",
        data: getData(),
        backgroundColor: getBackGroundColor(),
        borderWidth: 0,
      },
    ],
  };

  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 200).toFixed(2);
        ctx.font = fontSize + "em Aeonik";
        ctx.textBaseline = "top";
        var text = total,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / centerText;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  const options = {
    events: [],
    plugins: {
      legend: {
        position,
      },
    },
  };

  return <Doughnut options={options} data={data} plugins={plugins} />;
};

export default DoughnutChart;
