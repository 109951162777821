import { useGetSubscriptionServices } from "./useGetSubscriptionServices";

export const SupportedSubscriptions = () => {
  const { subscriptions } = useGetSubscriptionServices();

  return (
    <div className="flex flex-col md:flex-row gap-6">
      <div className="md:w-1/3">
        <h5 className="font-bold">What data do we use?</h5>
        <p className="text-sm mt-1">
          We will read your subscription and billing data in order to calculate
          KPIs like your MRR, net revenue retention, churn rate and customer
          concentration. By sharing your Chargebee data it does not give us
          rights to edit or make changes.
        </p>
        <p className="text-sm mt-2">
          By sharing your data it helps us evaluate your credit score and give
          you a good offer.
        </p>
      </div>
      <div className="max-h-96 md:w-2/3 flex flex-col gap-5 mb-10">
        <div className="border rounded-xl p-7">
          {subscriptions.map(({ logo, name }) => (
            <img key={name} src={logo} alt={name} className="h-fit m-auto" />
          ))}
        </div>
        <p className="text-sm">
          We will keep adding more services. Manually upload your subscription
          KPIs here instead if you don’t use Chargebee or if not all your data
          run through Chargbee.
        </p>
      </div>
    </div>
  );
};
