import React from "react";
import ReactDOM from "react-dom";
import "./styles/output.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { gtag, install } from "ga-gtag";
import Main from "./components/main";
import reportWebVitals from "./reportWebVitals";
import Helmet from "react-helmet";
import { SERVER_ENVIRONMENT, GOOGLE_CLIENT_ID } from "./common/constants";
import { Toaster } from "react-hot-toast";
import Country from "./hooks/useCountry";
import User from "./hooks/useWhoAmI";
import Config from "./hooks/useConfig";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ShowCompanyModal from "./hooks/useShowCompanyModal";

const dsn = {
  staging:
    "https://3a9bc4c114fb483f945cefd707600fd9@o1185167.ingest.sentry.io/6366201",
  production:
    "https://8fea3cc6ad9b475997e5e4343a63d6b4@o1185167.ingest.sentry.io/6324955",
};

if (["production", "staging"].includes(SERVER_ENVIRONMENT)) {
  Sentry.init({
    dsn: dsn[SERVER_ENVIRONMENT],
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.7,
  });
}
install("G-6FFXH387MD");

ReactDOM.render(
  <React.StrictMode>
    <Helmet titleTemplate="%s · Float" />
    <Toaster
      position="bottom-center"
      gutter={8}
      toastOptions={{
        success: {
          style: {
            border: "1px solid green",
          },
        },
        error: {
          style: {
            border: "1px solid red",
          },
        },
      }}
    />
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <Config>
        <Country>
          <User>
            <ShowCompanyModal>
              <Main />
            </ShowCompanyModal>
          </User>
        </Country>
      </Config>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

function sendToGoogleAnalytics({ name, delta, value, id }) {
  // Assumes the global `gtag()` function exists, see:
  // https://developers.google.com/analytics/devguides/collection/ga4
  gtag("event", name, {
    // Built-in params:
    value: delta, // Use `delta` so the value can be summed.
    // Custom params:
    metric_id: id, // Needed to aggregate events.
    metric_value: value, // Optional.
    metric_delta: delta, // Optional.

    // OPTIONAL: any additional params or debug info here.
    // See: https://web.dev/debug-performance-in-the-field/
    // metric_rating: 'good' | 'needs-improvement' | 'poor',
    // debug_info: '...',
    // ...
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendToGoogleAnalytics);
