import { useContext } from "react";
import { UserContext } from "../../../../../../../hooks/useWhoAmI";
import { useFormik } from "formik";
import { useState } from "react";
import { patchUser } from "../../../../../../../api";
import { validationSchema } from "../../validation.yourDetails";

export const useYourDetailsForm = ({ nextPage, me }) => {
  const { updateWhoAmI } = useContext(UserContext)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const onSubmit = async (data) => {
    try {
      setLoadingSubmit(true)
      const values = me.email === data.email ? {
        first_name: data.first_name,
        last_name: data.last_name,
        phone_number: data.phone_number,
      } : {
        ...data,
      }

      await patchUser(values);
      updateWhoAmI();
      nextPage();
    } catch (e) {
      formik.setFieldError('email', 'Email is already in use')
    } finally {
      setLoadingSubmit(false);
    }
  }

  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit,
    validationSchema,
    initialValues: {
      first_name: me?.first_name || '',
      last_name: me?.last_name || '',
      email: me?.email || '',
      phone_number: me?.phone_number || '',
    }
  });

  return {
    formik,
    loadingSubmit,
  }
}