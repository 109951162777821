import { useEffect, useState } from "react";
import { verifyEmailPut } from "../../../../../api";
import { Toast } from "../../../../../components";
import useQuery from "../../../../../hooks/useQuery";
import { ToastType } from "../../../../../types";

export const useVerifyEmail = () => {
  const [done, setDone] = useState(false);
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const search = useQuery();
  const code = search.get("code");

  useEffect(() => {
    const verify = async () => {
      if (code) {
        try {
          setLoading(true)
          const { email } = await verifyEmailPut({ code });
          setEmail(email);
          setDone(true);
        } catch (err) {
          Toast({
            type: ToastType.ERROR,
            title: 'Error, something went wrong here...',
            text: `${err?.response?.data}`
          })
        } finally {
          setLoading(false);
        }
      }
    };
    verify();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code])


  return {
    loading,
    done,
    email,
  }
}