import { useState } from "react";

export const useGetFormValues = () => {
  const [interest, setInterest] = useState([]);
  const [repayment, setRepayment] = useState([]);
  const [principle, setPrinciple] = useState([]);
  const [outstandingPrinciple, setOutstandingPrinciple] = useState([]);
  const [labels, setLabels] = useState([]);
  const [tableInfo, setTableInfo] = useState({
    grace: false,
    months: 12,
    interest: 0,
    interestRate: 0,
    repayment: 0,
    amount: 0,
  });

  const [hideGraph, setHideGraph] = useState(true);

  return {
    repayment,
    setRepayment,
    interest,
    setInterest,
    principle,
    setPrinciple,
    outstandingPrinciple,
    setOutstandingPrinciple,
    labels,
    setLabels,
    hideGraph,
    setHideGraph,
    tableInfo, 
    setTableInfo,
  }
}
