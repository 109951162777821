import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { getCompanyLookup, registerCompany } from "../../../../../../../api";
import { getCountryCookie } from "../../../../../../../common/cookie";
import { useGetValidationSchema, useGetInitialValues } from "../";
import { clearCompanyDetails } from "../../../../../../../common/localstorage";
import { Toast } from "../../../../../../../components";
import { ToastType } from "../../../../../../../types";

export const useCompanyDetailsForm = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [noCompany, setNoCompany] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [done, setDone] = useState(false);
  const [ARR, setARR] = useState();

  const country = getCountryCookie();
  const isSwedish = country?.code === "se";
  const { validationSchema } = useGetValidationSchema({
    country: country?.code,
  });
  const { initialValues } = useGetInitialValues({ isSwedish, companies });

  useEffect(() => {
    if (!isSwedish) {
      return;
    }
    setIsLoading(true);
    getCompanyLookup(country?.code)
      .then((res) => {
        const companyOptions = res.map((company) => ({
          label: `${company.company_name}, ${company.company_id}`,
          value: company.company_id,
          name: company.company_name,
        }));
        setCompanies(companyOptions);
        if (companyOptions.length === 0) {
          setNoCompany(true);
        }
      })
      .catch((err) => setNoCompany(true))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data, { setErrors }) => {
    setLoadingSubmit(true);
    if (isSwedish) {
      const organisation_number = data.company;
      const company = companies.find(
        ({ value }) => value === organisation_number
      );
      const values = {
        name: company.name,
        organisation_number,
        annual_recurring_revenue: data.annual_recurring_revenue,
        annual_growth: data.annual_growth,
        business_model: data.business_model,
        country: country.id,
      };

      try {
        await registerCompany(values);
        setARR(values.annual_recurring_revenue);
        setDone(true);
        clearCompanyDetails();
      } catch (err) {
        if (err.response.data.non_field_errors) {
          Toast({
            type: ToastType.ERROR,
            title: "Oh no, there was an error",
            text: `Failed to create company, reason: ${err.response.data}`,
          });
        }
      } finally {
        setLoadingSubmit(false);
      }
    } else {
      // Only DK flow for now
      try {
        await registerCompany({ country: country.id, ...data });
        setARR(data.annual_recurring_revenue);
        setDone(true);
        clearCompanyDetails();
      } catch (e) {
        if (e?.response.status === 409) {
          setErrors({ organisation_number: "Company already exists" });
        } else {
          Toast({
            type: ToastType.ERROR,
            title: "Oh no, there was an error",
            text: `Failed to create company, reason: ${e.response.data?.non_field_errors}`,
          });
        }
      } finally {
        setLoadingSubmit(false);
      }
    }
  };

  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  });

  return {
    formik,
    isLoading,
    noCompany,
    companies,
    loadingSubmit,
    isSwedish,
    done,
    ARR,
  };
};
