import axios from "axios";
import { doPost } from "../common/axiosUtil";

export const forgotPassword = async (data) => {
  const post = await doPost("auth/password/reset/", data);
  return post;
};

export const resetPassword = async (data) => {
  const post = await doPost("auth/password/reset/confirm/", data);
  return post;
};

export const changePassword = async (data) => {
  const post = await doPost("auth/password/change/", data);
  return post;
};
export const login = async (data) => {
  const post = await doPost("auth/login/", data);
  return post;
};

export const registerUser = async (data) => {
  const post = await doPost("auth/registration/", data);
  return post;
};

export const verifyEmail = async (data) => {
  const post = await doPost("auth/verify-email", data);
  return post;
};

export const setPassword = async (data) => {
  const post = await doPost("auth/password/set/", data);
  return post;
};

export const googleLogin = async (data) => {
  const post = await doPost("auth/google-login/", data);
  return post;
};

export const googleUserInfo = async (accessToken) => {
  return await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};
