export const UploadSubscriptionInformation = () => {
  return (
    <div className="flex flex-col w-full">
      <h5 className="text-base font-semibold">Information needed when uploading manually</h5>
      <ul className="list-disc list-outside font-medium text-gray-700 ml-5">
        <li>Invoicing and subscription reports for the past 18,-24 months, split monthly.</li>
        <li>Example: Can be an export from your subscription management system if you have one (like Younium, Recurly, Chargebee)</li>
      </ul>
      <h5 className="text-base font-semibold mt-5">Must include:</h5>
      <ul className="list-disc list-outside font-medium text-gray-700 ml-5">
        <li>
          Monthly, for 24 months: MRR or ARR, net revenue retention (and/or churn).
        </li>
        <li>Most recent: Number of customers, MRR per customer (can be anonymised), monthly vs, annual billings, CAC payback time (or: LTV:CAC)</li>
        <li>Optional: client contract length, cohort analysis</li>
      </ul>
      <a
        target="_blank"
        className="mt-5 font-bold mb-4" 
        href="https://docs.google.com/spreadsheets/d/1lT4HK9er7FkrhBhnlvIpXh4Y-BttaJ4wMobj4SAeTyU/edit#gid=0" rel="noreferrer"
      >
        Download subscription template
      </a>
    </div>
  );
};
