import * as Yup from "yup";
import { useFormik } from "formik";
import Input from "../../components/input";
import BankIDButton from "../../components/bankIDButton";
import { SSN_LOOKUP } from "../../common/constants";
import Loading from "../../components/loading";
import useQuery from "../../hooks/useQuery";
import { useLoginEid } from "../../hooks/useLoginEid";

const Automated = ({ country }) => {
  const { login } = useLoginEid();
  const search = useQuery();
  let invite_token = search.get("invite_token");

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      ssn: "",
      country,
    },
    onSubmit: async (values) => {
      const params = {
        ssn: values.ssn,
        country: country,
      };
      await login({ values: params, formik, invite_token });
    },
    validationSchema: Yup.object({
      ssn: Yup.string()
        .required("Personal number is required")
        .matches(
          SSN_LOOKUP[country.code].regex,
          `Needs to be ${SSN_LOOKUP[country.code].placeholder.length} digits.`
        ),
    }),
  });
  const doAction = (e) => formik.handleSubmit(e);

  return (
    <>
      {formik.isSubmitting && <Loading />}
      <form className="w-full text-left mt-4">
        <Input
          type="text"
          id="ssn"
          label="Personal number"
          required
          placeholder={SSN_LOOKUP[country.code].placeholder}
          pattern={`[0-9]{${SSN_LOOKUP[country.code].placeholder.length}}`}
          onChange={formik.handleChange}
          value={formik.values.ssn}
          error={formik.errors.ssn}
          disabled={formik.isSubmitting}
          className={`w-full`}
        />
        <BankIDButton
          className={`mt-8 w-full`}
          country={country}
          disabled={formik.isSubmitting}
          onClick={doAction}
        />
      </form>
    </>
  );
};

export default Automated;
