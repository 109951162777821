import { useHistory } from "react-router";
import { SecurePatternLayout } from "../../../templates/SecurePatternLayout";

const CompanyExists = () => {
  const history = useHistory();
  return (
    <SecurePatternLayout>
      <div className="h-fit drop-shadow-large bg-white rounded-2xl p-12 flex flex-col items-center max-w-[474px] z-10 gap-4 mx-8">
        <h2 className="text-float-purple-1">
          The company you tried to create an account for already exists.
        </h2>
        <p className="mt-2">
          To access this company account, please request an invite from the
          account creator.
        </p>
        <div className="flex flex-row w-full">
          <span className="bg-float-grey-10 w-2/4 h-px my-auto"></span>
          <span className="mx-2">OR</span>
          <span className="bg-float-grey-10 w-2/4 h-px my-auto"></span>
        </div>
        <p>
          If you believe there was an error, please email us at
          <a className="ml-2" href="mailto:support@gofloat.io">
            support@gofloat.io
          </a>
        </p>
        <button
          onClick={() => {
            history.push("/login");
          }}
          className="mt-4 bg-white border-float-grey-25 text-float-purple-2 w-full"
        >
          Back to login page
        </button>
      </div>
    </SecurePatternLayout>
  );
};

export default CompanyExists;
