import Loading from "../loading"

export const Button = ({
  children,
  loading,
  className,
  disabled,
  type,
  onClick,
}) => {
  return (
    <button
      type={type}
      className={className}
      disabled={loading || disabled}
      onClick={onClick}
    >
      {
        loading ? 
          <div className="flex items-center w-full justify-center">
            <Loading width={24} height={24}/>
          </div> 
          : children
      }
    </button>
  )
}