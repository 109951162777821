import { Icon } from "@iconify/react"

export const ConnectButton = ({ onClick, text, disabled = false }) => {
  return (
    <button
      className="bg-float-purple-2 border-float-purple-2 flex gap-2 w-48 xl:w-64 justify-center"
      onClick={onClick}
      disabled={disabled}
    >
      <Icon
        icon="ic:outline-cloud-done"
        width={24}
      />
      {text}
    </button>
  )
}