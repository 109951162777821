import { useContext } from "react";
import useAPI from "../../hooks/useAPI";
import { UserContext } from "../../hooks/useWhoAmI";
import Loading from "../../components/loading";
import { Layout } from "../../templates/Layout";
import { Icon } from "@iconify/react";
import { Button } from "../../components";
import { useMailToSupport } from "../../utils";

const FAQ = () => {
  const { me } = useContext(UserContext);
  const [result, loading, error] = useAPI({ me, path: "v1/faq/topic/" });
  const { mailToSupport } = useMailToSupport();

  if (error) {
    return (
      <Layout>
        <h2 className="">Frequently asked questions</h2>
        <div className="flex flex-col gap-6 lg:flex-row lg:gap-16">
          <div className="w-[800px]">
            <p>An error occurred, please try again later.</p>
          </div>
        </div>
      </Layout>
    )
  }

  if (loading) {
    return (
      <Layout>
        <h2 className="">Frequently asked questions</h2>
        <div className="flex flex-col gap-6 lg:flex-row lg:gap-16">
          <div className="w-[800px] mt-8">
          <Loading />
          </div>
        </div>
      </Layout>
    );
  }
  return (
    <Layout>
      <h2 className="">Frequently asked questions</h2>
      <div className="flex flex-col gap-6 lg:flex-row lg:gap-16">
          <div className="w-[800px]">
          {result.map((item) => (
            <details className="border-b py-3">
              <summary className="flex items-center justify-end">
                <div className="flex items-center gap-4">
                  <Icon 
                    icon="mdi:comment-question-outline"
                    width={22}
                    className="text-float-purple-2"
                    />
                  <p className="text-float-black-white">
                    {item.subject}
                  </p>
                  </div>
                  <Icon
                    className="ml-auto my-auto arrow"
                    icon="ic:baseline-keyboard-arrow-up"
                    color="#707070"
                    width={28}
                    />
                </summary>
              <p className="text-float-grey-90 pl-9 pt-2 pb-5">{item.text}</p>
            </details>
          ))}
        </div>
      <div className="w-[300px]">
        <p className="text-float-black-white mb-1">Can't find your specific question?</p>
        <p className="text-float-black-white text-sm">Just ask us and we'll get back to you as soon as possible by email.</p>
        <Button className={'secondary mt-5 w-[256px]'} onClick={mailToSupport}>
          <span className="flex items-center gap-2 justify-center">
            <Icon 
              icon="mdi:comment-processing-outline"
              width={22}
              className="text-float-purple-2"
            />
            Ask us a question
          </span>
        </Button>
      </div>
      </div>
    </Layout>
  );
};

export default FAQ;
