import { Toast } from "../../components/toast";
import { ToastType } from "../../types";
import { getWhoAmI } from "../../api";

export const LoginRedirect = async (
  history,
  setCookiesAndLogin,
  setShowCompanyModal
) => {
  const whoamI = await getWhoAmI();
  if (whoamI.company.length === 0) {
    Toast({
      type: ToastType.INFO,
      title: "No company registered!",
      text: "Complete the signup to register a company!",
    });
    history.push(
      `/signup/manual?step=0&isActiveUser=true&email=${whoamI?.email}`
    );
  } else {
    setCookiesAndLogin({ me: whoamI, history, setShowCompanyModal });
  }
};
