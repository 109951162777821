import { useState } from "react";

export const useDeleteUser = () => {
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);

  const deleteUser = (user) => {
    //TODO: make sure that only people with correct access can delete a user 
    console.log(user);
  }


  return {
    showDeleteUserModal,
    setShowDeleteUserModal,
    deleteUser,
  }
}