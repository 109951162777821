import { useContext } from "react";
import { useEffect, useState } from "react";
import { getAccountingConnections } from "../../../../../api";
import { UserContext } from "../../../../../hooks/useWhoAmI";

export const useGetAccountingConnections = () => {
  const { me } = useContext(UserContext);
  const [loadingAccountingConnections, setLoadingAccountingConnections] =
    useState(false);
  const [accountingConnections, setAccountingConnections] = useState([]);

  useEffect(() => {
    if (me?.selected_company?.monto_established_connection) {
      setLoadingAccountingConnections(true);
      getAccountingConnections()
        .then((res) => {
          setAccountingConnections(res);
        })
        .finally(() => setLoadingAccountingConnections(false));
    }
  }, [me]);

  return {
    accountingConnections,
    loadingAccountingConnections,
  };
};
