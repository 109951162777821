import Loading from "../../../../../components/loading"

export const SubmitButton = ({ onHandleSubmit, loading = false }) => {
  return (
    <button
      style={{ width: 120 }}
      className="bg-float-purple-2"
      onClick={onHandleSubmit}
      disabled={loading}
    >
      {
        loading ? 
         <div>
          <Loading height={24} width={72} /> 
         </div> 
        : 'Save'
      }
  </button>
  )
}