import { InfoModal } from "../../../../../components"

export const DeleteFileModal = ({ hide, visible, file, onDelete }) => {

  return (
    <InfoModal 
      hide={hide}
      visible={visible}
      title="Delete this uploaded file?"
      closeText="No, cancel"
      content={
        <div className="max-w-[500px] md:w-[500px] h-[116px]">
          <p className="truncate text-float-grey-100 text-sm">
            {`Are you sure you want to delete ${file.name}`}
          </p>
        </div>
      }
      submitButton={
        <button onClick={onDelete}>
          Yes, delete
        </button>
      }
    />
  )
}