import CelebrationLogo from "../celebrationLogo";
import Loading from "../loading";

export const SignedInformation = ({ onClose, me, loading }) => {
  if (loading) {
    return <Loading />;
  }
  return (
    <div className="flex flex-col m-auto">
      <div className="flex flex-row mx-auto">
        <div className="mx-2">
          <CelebrationLogo />
        </div>
      </div>
      <p className="mt-6">
        Thank you{" "}
        <span className="capitalize">
          {me?.first_name.split(" ")[0].toLowerCase()},
        </span>
      </p>
      <p>
        We have received your loan application and will contact you very
        shortly.
      </p>
      <button className="ml-auto my-8" onClick={() => onClose()}>
        Close
      </button>
    </div>
  );
};
