import { useEffect, useState } from "react";
import { InfoModal } from "../infoModal";
import { renewCreditOffer } from "../../api/loan";
import {
  setCreditOfferRenewCookie,
  getCreditOfferRenewCookie,
} from "../../common/cookie";
import { ToastType } from "../../types";
import { Toast } from "../toast";

const ExpiredCreditOffer = ({ hide, visible }) => {
  const alreadyRenewed = getCreditOfferRenewCookie();
  const [renewed, setRenewed] = useState();
  const onRenew = () => {
    try {
      renewCreditOffer();
      setCreditOfferRenewCookie();
      setRenewed(true);
      Toast({
        type: ToastType.SUCCESS,
        title: "Yes, success!",
        text: "Renewal request sent!",
      });
    } catch {
      Toast({
        type: ToastType.ERROR,
        title: "Oh no, there was an error",
        text: "Renewal request failed. Please try again!",
      });
      setRenewed(false);
    }
  };

  useEffect(() => {
    setRenewed(alreadyRenewed);
  }, [alreadyRenewed, setRenewed]);
  return (
    <InfoModal
      hide={hide}
      visible={visible}
      title="Your credit offer has expired"
      closeText="Close"
      submitButton={
        <button disabled={renewed} onClick={onRenew} className="ml-auto mt-4">
          Renew
        </button>
      }
      content={
        <div className="flex flex-col mx-auto">
          {alreadyRenewed ? (
            <p>
              We have received your new credit offer requested and will contact
              you shortly.
            </p>
          ) : (
            <p>
              Your credit offer has expired. But it's easy to renew - just click
              the button below to start the process.
            </p>
          )}
        </div>
      }
    />
  );
};

export default ExpiredCreditOffer;
