import { useGetAccountingSoftware } from "./useGetAccountingSoftware"

export const SupportedAccounting = () => {
  const { accountingSoftware } = useGetAccountingSoftware();

  return (
    <div className="flex flex-col md:flex-row gap-6">
    <div className="md:w-1/3">
      <h5 className="font-bold">What data do we use?</h5>
      <p className="text-sm mt-1">
      Balance sheet and income statement for the last 24 months.
      </p>
    </div>
    <div className="max-h-96 md:w-2/3 flex flex-col gap-5 mb-10">
      <div className="border rounded-xl p-7 grid grid-cols-3 gap-8">
        {
          accountingSoftware.map(({ logo, name }) => <img key={name} src={logo} alt={name} className="h-fit m-auto" />)
        }
      </div>
    </div>
  </div>
  )
}