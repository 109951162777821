import { Icon } from "@iconify/react";
import { useContext } from "react";
import { CountryContext } from "../../../../../hooks/useCountry";
import { useSubscriptionFiles } from "../../../../../hooks/useSubscriptionFiles";
import Accordion from "../../../../../components/accordion";
import { UploadButton, FileList } from "../../../../../components/";
import { DeleteFileModal } from "../deleteFileModal";
import { useDeleteFileModal } from "../../hooks";
import { ConnectButton } from "../../../../../components/connectButton";

export const SubscriptionAccordion = ({
  open,
  checked,
  onDataClick,
  onUploadButtonClick,
  uploadComplete,
  onSupportedSubscriptionClick,
  onConnect,
}) => {
  const { subscriptionFiles, deleteSubscriptionFileById, isLoading } =
    useSubscriptionFiles({ uploadComplete });
  const {
    showDeleteModal,
    openDeleteModal,
    closeDeleteModal,
    onDeleteFile,
    currentFile,
  } = useDeleteFileModal({ deleteFileById: deleteSubscriptionFileById });
  const { country } = useContext(CountryContext);

  return (
    <>
      <Accordion
        title={"Add subscription information"}
        checked={checked}
        open={open}
      >
        <div className="ml-[77px] mr-12">
          <p className="mb-8 mr-5">
            We will assess the quality and performance of your client base
            because growth loans rely on a steady growth of revenues.
          </p>
          <div>
            <div className="flex flex-col gap-4 md:flex-row md:gap-0 mb-8">
              {country.subscription_chargebee && (
                <div className="flex flex-col gap-2 md:gap-4 items-center md:border-r-2 border-float-grey-10 md:pr-6">
                  <ConnectButton
                    text={"Connect Chargebee"}
                    onClick={onConnect}
                  />
                  <p
                    className="text-sm font-normal flex gap-2 items-center cursor-pointer text-float-purple-2"
                    onClick={onSupportedSubscriptionClick}
                  >
                    <Icon icon="ri:information-line" width={16} />
                    Supported subsciption software
                  </p>
                </div>
              )}
              {country.subscription_upload && (
                <div
                  className={`flex flex-col gap-2 md:gap-4 items-center md:pl-6`}
                >
                  <UploadButton onClick={onUploadButtonClick} />
                  <p
                    className="text-sm font-normal flex gap-2 items-center cursor-pointer text-float-purple-2"
                    onClick={onDataClick}
                  >
                    <Icon icon="ri:information-line" width={16} />
                    What data do we need?
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        {subscriptionFiles && subscriptionFiles.length > 0 && (
          <div className="border-t px-6">
            <FileList
              files={subscriptionFiles}
              onHandleRemove={openDeleteModal}
              isLoading={isLoading}
            />
          </div>
        )}
      </Accordion>
      <DeleteFileModal
        visible={showDeleteModal}
        hide={closeDeleteModal}
        file={currentFile}
        onDelete={onDeleteFile}
      />
    </>
  );
};
