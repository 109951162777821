import { useState, useEffect, useContext } from "react";
import { UserContext } from "./useWhoAmI";
import { getTransactionFiles, deleteTransactionFile } from "../api";
import { Toast } from "../components";
import { ToastType } from "../types";
import { useFileListFormat } from "../utils";
import { setExpandedAccordion, StorageKeys } from "../common/localstorage";

export const useTransactionFiles = ({ uploadComplete }) => {
  const { updateWhoAmI } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const { getFileListFormat } = useFileListFormat();
  const [transactionFiles, setTransactionFiles] = useState([]);

  // TODO: error handling
  const getFiles = (deletingFile = false) => {
    setIsLoading(true);
    getTransactionFiles()
      .then((data) => {
        const files = getFileListFormat(data);

        setTransactionFiles(files);
        if (deletingFile && files.length === 0) {
          setExpandedAccordion(StorageKeys.TRANSACTION);

          updateWhoAmI();
        }
        return files;
      })
      .finally(() => setIsLoading(false));
  };

  const deleteTransactionFileById = async (file) => {
    deleteTransactionFile(file.id)
      .then(() => getFiles(true))
      .then(() => {
        Toast({
          type: ToastType.SUCCESS,
          title: "Yes, success!",
          text: `${file.name} has been deleted`,
        });
      })
      .catch((err) => console.log("Error: ", err));
  };

  useEffect(() => {
    getFiles();
    return () => setTransactionFiles([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadComplete]);

  return {
    transactionFiles,
    deleteTransactionFileById,
    isLoading,
  };
};
