import { useState } from "react";
import { withRouter } from "react-router";
import Companies from "../../components/companies";
import CompanyWrapper from "../../components/companyWrapper";
import TwoColContent from "../../templates/twoColContent";

const LeftContent = ({ setSelected }) => {
  return <Companies setSelected={setSelected} />;
};

const RightContent = ({ selected }) => {
  return <CompanyWrapper id={selected} />;
};
const Admin = ({ location }) => {
  const search = new URLSearchParams(location.search);
  let company = search.get("company");
  const [selected, setSelected] = useState(company ? company : null);

  return (
    <TwoColContent
      leftContent={<LeftContent setSelected={setSelected} />}
      rightContent={<RightContent selected={selected} />}
    />
  );
};

export default withRouter(Admin);
