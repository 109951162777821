const Logo = ({ color = "#6F3F91", widht = 128, height = 128 }) => {
  return (
    <svg
      width={widht}
      height={height}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="64" cy="64" r="64" fill={color} />
      <path
        d="M60.0899 51.0131L58.4133 50.4449L59.2516 50.7279L58.4133 50.449C58.5118 50.1556 60.7437 43.1905 53.0586 35.6428L54.316 34.4031C62.8143 42.7546 60.2114 50.6783 60.0899 51.0131Z"
        fill="white"
      />
      <path
        d="M91.1494 72.8235C90.9231 72.6168 85.5076 67.625 75.2887 71.2119L74.6914 69.5589C86.0085 65.5877 92.1365 71.3214 92.3922 71.5672L91.1473 72.8235L91.776 72.2036L91.1494 72.8235Z"
        fill="white"
      />
      <path
        d="M68.4198 42.3476C66.0831 39.2049 67.5606 36.021 68.6294 33.6965C68.7719 33.3907 68.9102 33.0911 69.036 32.8019C69.9833 30.6283 69.6647 26.9546 68.3779 24.9504L69.8806 24.0083C71.5027 26.5228 71.859 30.7812 70.6749 33.5126C70.5428 33.8143 70.3982 34.1325 70.2557 34.4445C69.2079 36.6925 68.1348 39.0148 69.8512 41.3248L68.4198 42.3476Z"
        fill="white"
      />
      <path
        d="M90.3871 63.3253C89.392 63.3388 88.4012 63.1936 87.4531 62.8955C87.1387 62.7901 86.8139 62.6724 86.4785 62.5505C84.125 61.7013 81.6918 60.8232 79.4892 62.691L78.3281 61.3583C81.3209 58.8169 84.6552 60.0215 87.0905 60.9017C87.4133 61.0174 87.7192 61.1289 88.0273 61.2302C90.3012 61.9926 93.9898 61.4037 95.9241 59.9698L96.9951 61.3748C95.3268 62.6166 92.7533 63.3253 90.3871 63.3253Z"
        fill="white"
      />
      <path
        d="M34.1562 92.8095L50.1253 53.3452C50.1253 53.3452 57.6264 54.6125 65.2556 62.2997C72.8849 69.9869 72.649 77.0329 72.649 77.0329L34.1562 92.8095Z"
        fill="white"
      />
      <path
        d="M60.6732 25.2552L62.4776 24.3192C62.5311 24.292 62.5913 24.28 62.6514 24.2845C62.7114 24.289 62.769 24.31 62.8177 24.3449C62.8664 24.3798 62.9043 24.4274 62.9271 24.4824C62.95 24.5373 62.9569 24.5974 62.9471 24.656L62.6034 26.6375C62.5944 26.6885 62.5981 26.7409 62.6141 26.7902C62.6302 26.8395 62.6581 26.8842 62.6956 26.9205L64.1626 28.3235C64.2058 28.3652 64.2363 28.418 64.2507 28.4759C64.265 28.5339 64.2627 28.5946 64.244 28.6513C64.2253 28.708 64.1908 28.7584 64.1446 28.7968C64.0984 28.8353 64.0422 28.8603 63.9824 28.8689L61.9641 29.1561C61.9122 29.1639 61.863 29.1839 61.8207 29.2145C61.7784 29.245 61.7442 29.2853 61.7211 29.3318L60.8178 31.1334C60.7913 31.1871 60.75 31.2324 60.6987 31.2641C60.6474 31.2957 60.5881 31.3125 60.5275 31.3125C60.467 31.3125 60.4076 31.2957 60.3563 31.2641C60.305 31.2324 60.2637 31.1871 60.2372 31.1334L59.3361 29.3318C59.3125 29.2852 59.2779 29.245 59.2352 29.2144C59.1925 29.1839 59.143 29.1639 59.0909 29.1561L57.0726 28.8689C57.013 28.8599 56.957 28.8347 56.9111 28.7962C56.8651 28.7576 56.831 28.7071 56.8125 28.6505C56.794 28.5939 56.7919 28.5333 56.8064 28.4755C56.8209 28.4177 56.8514 28.3651 56.8945 28.3235L58.3615 26.9205C58.3997 26.8846 58.4282 26.84 58.4446 26.7906C58.4611 26.7413 58.4649 26.6887 58.4558 26.6375L58.0975 24.6643C58.0874 24.6054 58.0942 24.5449 58.1171 24.4897C58.14 24.4344 58.1782 24.3866 58.2272 24.3516C58.2762 24.3166 58.3342 24.2957 58.3946 24.2915C58.455 24.2872 58.5154 24.2997 58.569 24.3275L60.3735 25.2635C60.4203 25.2863 60.4721 25.2974 60.5244 25.296C60.5766 25.2946 60.6277 25.2806 60.6732 25.2552Z"
        fill="white"
      />
      <path
        d="M79.2302 39.271L81.0367 38.3351C81.0902 38.3078 81.1504 38.2958 81.2104 38.3003C81.2705 38.3049 81.3281 38.3258 81.3768 38.3607C81.4255 38.3956 81.4634 38.4432 81.4862 38.4982C81.5091 38.5531 81.516 38.6133 81.5062 38.6718L81.1604 40.6533C81.1504 40.7058 81.1538 40.7598 81.1703 40.8107C81.1867 40.8615 81.2157 40.9076 81.2547 40.9446L82.7217 42.3476C82.7651 42.3891 82.7957 42.4417 82.8102 42.4996C82.8247 42.5574 82.8224 42.6181 82.8036 42.6747C82.7848 42.7313 82.7503 42.7816 82.704 42.8199C82.6576 42.8581 82.6013 42.8827 82.5415 42.891L80.5233 43.1802C80.4714 43.188 80.4221 43.208 80.3798 43.2385C80.3375 43.2691 80.3033 43.3094 80.2801 43.3558L79.3769 45.1575C79.3504 45.2112 79.3092 45.2565 79.2578 45.2882C79.2065 45.3198 79.1472 45.3366 79.0866 45.3366C79.0261 45.3366 78.9668 45.3198 78.9154 45.2882C78.8641 45.2565 78.8228 45.2112 78.7964 45.1575L77.8931 43.3558C77.87 43.3094 77.8358 43.2691 77.7934 43.2385C77.7511 43.208 77.7019 43.188 77.65 43.1802L75.6318 42.891C75.5721 42.8824 75.516 42.8575 75.47 42.8192C75.4239 42.7808 75.3897 42.7305 75.3711 42.6739C75.3526 42.6174 75.3505 42.5568 75.3651 42.4991C75.3796 42.4414 75.4103 42.3889 75.4536 42.3476L76.9206 40.9446C76.9583 40.9084 76.9866 40.8638 77.003 40.8145C77.0194 40.7652 77.0235 40.7127 77.015 40.6616L76.6692 38.6801C76.6594 38.6215 76.6662 38.5614 76.6891 38.5065C76.7119 38.4515 76.7498 38.4039 76.7985 38.369C76.8472 38.334 76.9048 38.3131 76.9649 38.3086C77.0249 38.3041 77.0851 38.3161 77.1386 38.3433L78.9452 39.2793C78.99 39.3 79.0392 39.31 79.0887 39.3086C79.1382 39.3072 79.1866 39.2943 79.2302 39.271Z"
        fill="white"
      />
      <path
        d="M92.599 35.1531L94.4034 34.2172C94.457 34.1899 94.5171 34.1779 94.5772 34.1825C94.6372 34.187 94.6948 34.2079 94.7435 34.2428C94.7922 34.2778 94.8301 34.3254 94.8529 34.3803C94.8758 34.4352 94.8827 34.4954 94.8729 34.554L94.5292 36.5354C94.5202 36.5864 94.5239 36.6388 94.5399 36.6881C94.556 36.7374 94.5839 36.7822 94.6214 36.8185L96.0884 38.2214C96.1316 38.2632 96.1621 38.316 96.1765 38.3739C96.1908 38.4318 96.1885 38.4925 96.1698 38.5492C96.1511 38.6059 96.1166 38.6563 96.0704 38.6948C96.0242 38.7332 95.968 38.7582 95.9082 38.7669L93.8899 39.0561C93.838 39.063 93.7886 39.0824 93.7462 39.1127C93.7038 39.143 93.6696 39.1832 93.6469 39.2297L92.7436 41.0335C92.7165 41.0865 92.6751 41.1311 92.6238 41.1623C92.5726 41.1934 92.5135 41.2099 92.4533 41.2099C92.3931 41.2099 92.3341 41.1934 92.2828 41.1623C92.2315 41.1311 92.1901 41.0865 92.163 41.0335L91.2619 39.2297C91.2386 39.1831 91.204 39.143 91.1613 39.1127C91.1185 39.0824 91.0688 39.063 91.0167 39.0561L88.9984 38.7669C88.9388 38.7579 88.8828 38.7327 88.8369 38.6941C88.7909 38.6555 88.7568 38.6051 88.7383 38.5484C88.7198 38.4918 88.7177 38.4312 88.7321 38.3734C88.7466 38.3157 88.7772 38.263 88.8203 38.2214L90.2873 36.8185C90.3248 36.7822 90.3527 36.7374 90.3688 36.6881C90.3848 36.6388 90.3885 36.5864 90.3795 36.5354L90.0338 34.554C90.0237 34.4951 90.0305 34.4346 90.0534 34.3794C90.0763 34.3241 90.1144 34.2763 90.1635 34.2412C90.2125 34.2062 90.2705 34.1854 90.3309 34.1812C90.3913 34.1769 90.4517 34.1894 90.5053 34.2172L92.3098 35.1531C92.3546 35.1755 92.4041 35.1871 92.4544 35.1871C92.5046 35.1871 92.5541 35.1755 92.599 35.1531Z"
        fill="white"
      />
      <path
        d="M75.5013 50.5833L77.3058 49.6473C77.3593 49.6201 77.4194 49.6081 77.4795 49.6126C77.5395 49.6171 77.5971 49.638 77.6458 49.673C77.6945 49.7079 77.7324 49.7555 77.7553 49.8105C77.7781 49.8654 77.785 49.9255 77.7752 49.9841L77.4315 51.9656C77.4225 52.0166 77.4262 52.069 77.4423 52.1183C77.4583 52.1676 77.4863 52.2123 77.5237 52.2486L78.9907 53.6515C79.0339 53.6933 79.0644 53.7461 79.0788 53.804C79.0932 53.8619 79.0909 53.9227 79.0721 53.9794C79.0534 54.0361 79.019 54.0865 78.9727 54.1249C78.9265 54.1634 78.8703 54.1883 78.8105 54.197L76.7923 54.4863C76.7403 54.4931 76.691 54.5125 76.6485 54.5428C76.6061 54.5731 76.572 54.6133 76.5492 54.6598L75.6459 56.4636C75.6189 56.5166 75.5774 56.5612 75.5261 56.5924C75.4749 56.6236 75.4159 56.6401 75.3556 56.6401C75.2954 56.6401 75.2364 56.6236 75.1851 56.5924C75.1339 56.5612 75.0924 56.5166 75.0654 56.4636L74.1642 54.6598C74.1409 54.6133 74.1064 54.5731 74.0636 54.5428C74.0208 54.5126 73.9712 54.4931 73.919 54.4863L71.9008 54.197C71.8411 54.188 71.7852 54.1628 71.7392 54.1242C71.6933 54.0857 71.6591 54.0352 71.6406 53.9786C71.6221 53.9219 71.62 53.8613 71.6345 53.8036C71.649 53.7458 71.6795 53.6932 71.7226 53.6515L73.1897 52.2486C73.2273 52.2124 73.2556 52.1678 73.272 52.1185C73.2884 52.0692 73.2925 52.0167 73.284 51.9656L72.9382 49.9841C72.9281 49.9252 72.9349 49.8648 72.9578 49.8095C72.9807 49.7543 73.0189 49.7064 73.0679 49.6714C73.1169 49.6364 73.1749 49.6156 73.2353 49.6113C73.2957 49.607 73.3561 49.6195 73.4097 49.6473L75.2142 50.5833C75.2587 50.6053 75.3079 50.6167 75.3577 50.6167C75.4076 50.6167 75.4567 50.6053 75.5013 50.5833Z"
        fill="white"
      />
      <path
        d="M89.2745 49.5838L91.0789 48.6478C91.1325 48.62 91.1929 48.6075 91.2533 48.6118C91.3137 48.616 91.3717 48.6368 91.4207 48.6719C91.4698 48.7069 91.5079 48.7547 91.5308 48.81C91.5538 48.8652 91.5605 48.9257 91.5505 48.9846L91.2047 50.966C91.1957 51.017 91.1994 51.0694 91.2154 51.1187C91.2315 51.168 91.2594 51.2128 91.2969 51.2491L92.7639 52.652C92.807 52.6936 92.8376 52.7463 92.852 52.804C92.8665 52.8618 92.8644 52.9224 92.8459 52.979C92.8274 53.0357 92.7933 53.0861 92.7473 53.1247C92.7014 53.1633 92.6454 53.1885 92.5858 53.1975L90.5676 53.4867C90.5154 53.4936 90.4657 53.513 90.4229 53.5433C90.3802 53.5736 90.3456 53.6137 90.3223 53.6603L89.4212 55.4641C89.3941 55.5171 89.3527 55.5617 89.3014 55.5929C89.2502 55.624 89.1911 55.6405 89.1309 55.6405C89.0707 55.6405 89.0117 55.624 88.9604 55.5929C88.9091 55.5617 88.8677 55.5171 88.8406 55.4641L87.9374 53.6603C87.9146 53.6138 87.8804 53.5736 87.838 53.5433C87.7956 53.513 87.7462 53.4936 87.6943 53.4867L85.676 53.1975C85.6162 53.1888 85.56 53.1638 85.5138 53.1254C85.4675 53.0869 85.4331 53.0365 85.4144 52.9798C85.3957 52.9231 85.3934 52.8624 85.4078 52.8045C85.4221 52.7466 85.4526 52.6938 85.4958 52.652L86.9628 51.2491C87.0003 51.2128 87.0282 51.168 87.0443 51.1187C87.0603 51.0694 87.064 51.017 87.055 50.966L86.7113 48.9846C86.7015 48.926 86.7084 48.8659 86.7313 48.8109C86.7541 48.756 86.792 48.7084 86.8407 48.6734C86.8894 48.6385 86.947 48.6176 87.0071 48.6131C87.0671 48.6085 87.1273 48.6206 87.1808 48.6478L88.9852 49.5838C89.0301 49.6061 89.0796 49.6177 89.1299 49.6177C89.1801 49.6177 89.2296 49.6061 89.2745 49.5838Z"
        fill="white"
      />
    </svg>
  );
};

export default Logo;
