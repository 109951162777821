import { CountryItem } from "../countryItem";
import { getCountries } from "../../../../api";
import { useEffect, useState } from "react";

export const CountryList = ({ setCountry, country }) => {
  const [countries, setCountries] = useState([]);
  const [countriesOriginal, setCountriesOriginal] = useState([]);
  useEffect(() => {
    const fetchCountries = async () => {
      // TODO: Error handling
      const countries = await getCountries();
      const filteredCountries = countries.filter((c) =>
        Boolean(c.ais_go_cardless)
      );
      const sortedCountries = filteredCountries.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setCountries(sortedCountries);
      setCountriesOriginal(sortedCountries);
    };
    fetchCountries();
  }, []);

  return (
    <div className="flex flex-wrap h-full overflow-auto max-h-[500px]">
      <div className="flex w-full">
        <input
          type="text"
          placeholder="Search"
          className="mb-4 border rounded-md p-2 mx-8 cursor-pointer w-full"
          onChange={(e) => {
            const value = e.target.value;
            if (!value) {
              setCountries(countriesOriginal);
              return;
            }
            const filteredCountries = countries.filter((c) =>
              c.name.toLowerCase().includes(value.toLowerCase())
            );
            setCountries(filteredCountries);
          }}
        />
      </div>
      {countries?.map((c) => (
        <CountryItem
          key={c.id}
          name={c.name}
          code={c.code}
          onClick={() => {
            setCountry(c);
          }}
        />
      ))}
    </div>
  );
};
