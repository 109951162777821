import Helmet from "react-helmet";
import Help from "../components/help";
import Logo from "../components/logo";

function TwoColWithImage({
  title,
  logoColor = "white",
  children,
  leftContent,
  image = null,
}) {
  return (
    <div className="w-screen h-screen flex flex-row text-black">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div
        className={`left-col h-screen flex flex-2 flex-col pt-14 pb-16 lg:px-20 px-10 justify-between items-left max-w-md`}
      >
        <div className="">
          <Logo color={logoColor} />
        </div>
        {leftContent}
      </div>
      <div className="bg-float-purple-5 h-screen flex flex-3 items-center justify-center flex-col p-14 h-full">
        <div className="h-screen flex flex-1 items-center flex-col justify-center z-10">
          {children}
        </div>
        {image && (
          <img
            className="absolute bottom-0 right-0 md:h-[600px] w-2/5 md:max-w-[490px]"
            src={image.type}
            alt="secure_pattern"
          />
        )}
      </div>
      <div className="fixed right-1 bottom-1 bg-float-purple-2 rounded-full p-0.5 cursor-pointer">
        <Help size={24} />
      </div>
    </div>
  );
}

export default TwoColWithImage;
