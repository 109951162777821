import React from "react";
import ErrorHandling from "../../hooks/useGlobalError";
import Routes from "../../routing";
import Modal from "../modal";

function Main() {
  return (
    <div className="App">
      <ErrorHandling>
        <Modal>
          <Routes />
        </Modal>
      </ErrorHandling>
    </div>
  );
}

export default Main;
