import { createContext, useState } from "react";
export const ShowCompanyModalContext = createContext(null);

const ShowCompanyModal = ({ children }) => {
  const [isCompanyModalVisible, setShowCompanyModal] = useState(false);

  return (
    <ShowCompanyModalContext.Provider
      value={{
        isCompanyModalVisible,
        setShowCompanyModal,
      }}
    >
      {children}
    </ShowCompanyModalContext.Provider>
  );
};

export default ShowCompanyModal;
