import { Icon } from "@iconify/react";
import Circle from "../circle";

export const BankConnection = ({ bank_name, accounts, status }) => {
  return (
    <div className="flex flex-row items-center justify-between border-t py-4 px-6">
      <div className="flex gap-4">
        <div className="border rounded py-2 px-7 h-fit">
          <Icon icon="circum:bank" width={24} />
        </div>
        <div>
          <div className="flex flex-row">
            <h5 className="text-sm font-bold text-float-purple-2 mb-0.5">
              {bank_name} connected
            </h5>
            {status === "EXPIRED" ? (
              <div className="ml-2 my-auto tooltip">
                <Circle color="#dc2626" width="18" height="18" />
                <span className="text">Connection expired</span>
              </div>
            ) : null}
          </div>
          <ul>
            {accounts.map(({ account_number, alias, iban }) => (
              <li
                key={account_number}
                className="text-xs text-float-black-90 py-0.5"
              >
                {alias ? <span>{alias}</span> : "Account"}
                {account_number && <span> {account_number}</span>}
                {iban && <span> / {iban}</span>}
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* <Icon
            className="cursor-pointer"
            icon="mdi:cancel-box-outline"
            width={20}
            color="#959595"
          /> */}
    </div>
  );
};
