import LogoSmall from "../../components/logoSmall";
import { UserContext } from "../../hooks/useWhoAmI";
import { Icon } from "@iconify/react";
import Helmet from "react-helmet";
import { useContext } from "react";
import useAPI from "../../hooks/useAPI";
import { NavLink } from "react-router-dom";
import { ContextualMenu } from "../contextualMenu";

const Dashboard = ({ children }) => {
  const { me } = useContext(UserContext);
  const [result] = useAPI({ me, path: "v1/credit_offer/" });

  return (
    <div>
      <div className="w-screen h-screen h-full">
        <Helmet>
          <title>Dashboard</title>
        </Helmet>
        <div>
          <div
            id="Main"
            className="fixed flex h-screen bg-float-purple-1 flex-col justify-between p-3 z-20 w-[90px]"
          >
            <div className="mt-7 flex flex-col w-full space-y-6 items-center">
              <NavLink to="/dashboard" exact>
                <LogoSmall color="white" width="26" height="48" />
              </NavLink>
              {result.length > 0 && (
                <>
                  <NavLink to="/dashboard" exact>
                    <div className="flex flex-col items-center gap-1">
                      <div className="dashboard-icon-container">
                        <Icon
                          className="dashboard-icon"
                          icon="carbon:dashboard"
                          width={28}
                        />
                      </div>
                    </div>
                  </NavLink>
                  <NavLink to="/loan" exact>
                    <div className="flex flex-col items-center gap-1">
                      <div className="dashboard-icon-container">
                        <Icon
                          className="dashboard-icon"
                          icon="carbon:meter-alt"
                          width={28}
                        />
                      </div>
                    </div>
                  </NavLink>
                </>
              )}
            </div>
            <div className="mt-6 mb-6 flex flex-col w-full space-y-4 items-center z-indedx-">
              {result?.length <= 0 ? (
                <NavLink to="/dashboard" exact>
                  <div className="flex flex-col items-center gap-1">
                    <div className="dashboard-icon-container">
                      <Icon
                        className="dashboard-icon"
                        icon="ic:outline-cloud-done"
                        width={24}
                      />
                    </div>
                    <p className="text-xs text-white">Connect</p>
                  </div>
                </NavLink>
              ) : (
                <>
                  <NavLink to="/connect" exact>
                    <div className="flex flex-col items-center gap-1">
                      <div className="dashboard-icon-container">
                        <Icon
                          className="dashboard-icon"
                          icon="ic:outline-cloud-done"
                          width={24}
                        />
                      </div>
                      <p className="text-xs text-white">Connect</p>
                    </div>
                  </NavLink>
                  <NavLink to="/settings" exact>
                    <div className="flex flex-col items-center gap-1">
                      <div className="dashboard-icon-container">
                        <Icon
                          className="dashboard-icon"
                          icon="mdi:settings-outline"
                          width={24}
                        />
                      </div>
                      <p className="text-xs text-white">Settings</p>
                    </div>
                  </NavLink>
                </>
              )}
              <NavLink to="/users" exact>
                <div className="flex flex-col items-center gap-1">
                  <div className="dashboard-icon-container">
                    <Icon
                      className="dashboard-icon"
                      icon="mdi:user-check-outline"
                      width={24}
                    />
                  </div>
                  <p className="text-xs text-white">Users</p>
                </div>
              </NavLink>
              <NavLink to="/faq" exact>
                <div className="flex flex-col items-center gap-1">
                  <div className="dashboard-icon-container">
                    <Icon
                      className="dashboard-icon"
                      icon="mdi:question-box-outline"
                      width={24}
                    />
                  </div>
                  <p className="text-xs text-white">FAQ</p>
                </div>
              </NavLink>
            </div>
          </div>
          {me && (
            <div className="fixed flex flex-row z-10 py-4 pr-8 w-full justify-end border-b bg-float-purple-6">
              <ContextualMenu />
            </div>
          )}
        </div>
        <div className="h-full">{children}</div>
      </div>
    </div>
  );
};

export default Dashboard;
