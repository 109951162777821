import { Icon } from "@iconify/react";
import Collapsible from "react-collapsible";

const Accordion = ({ title, children, checked = false, icon, open }) => (
  <div className="rounded-lg drop-shadow bg-white shadow">
    <Collapsible
      transitionTime={100}
      transitionCloseTime={100}
      open={open}
      trigger={
        <div className="flex flex-row py-6 px-[28px]">
          {checked && (
            <Icon
              icon="carbon:checkmark-filled"
              className="text-float-purple-3 mr-3"
              width="37"
            />
          )}
          {
            icon && !checked && (
              <div className="bg-float-purple-6 mr-3.5 rounded-full w-[33px] h-[33px] flex items-center justify-center">
                <Icon 
                  icon={icon}
                  width="23"
                  className="text-float-purple-3"
                />
              </div>
            )
          }
          {
          !checked && !icon && (
            <Icon
              icon="akar-icons:circle-fill"
              width="37"
              className="text-float-purple-5 mr-3"
            />
          )}
          <h4 className="font-semibold my-auto text-sm md:text-base select-none">{title}</h4>
          <Icon
            className="ml-auto my-auto"
            icon="ic:baseline-keyboard-arrow-up"
            color="#707070"
            width={28}
          />
        </div>
      }
    >
      <div className="tab-content flex flex-col max-w-4xl">{children}</div>
    </Collapsible>
  </div>
);

export default Accordion;
