import { useEffect, useState, useContext, useCallback, useRef } from "react";
import { CountryContext } from "../../hooks/useCountry";
import { setCountryCookie, getCountryCookie } from "../../common/cookie";
import { useHandleClickOutside } from "../../hooks/useHandleClickOutside";

const CountrySelector = ({ onChange, value, setValue }) => {
  const { countries: countriesOriginal, loading } = useContext(CountryContext);
  const [countries, setCountries] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState(countries);
  const [visible, setVisible] = useState(false);
  const ref = useRef();

  useHandleClickOutside(ref, () => setVisible(false));

  useEffect(() => {
    setCountries(countriesOriginal.filter((c) => Boolean(c.login_enabled)));
  }, [countriesOriginal]);

  useEffect(() => {
    if (countries.length > 1) {
      const storedCookie = getCountryCookie();
      let country = null;
      if (storedCookie !== undefined) {
        country = countries.find(
          (country) => country.code === storedCookie?.code
        );
      } else {
        country = countries.find((country) => country.code === "se");
      }
      setValue(country);
      setCountryCookie(country);
    }

    const storedCookie = getCountryCookie();
    if (value.code !== storedCookie?.code) {
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries, setValue]);

  const closeFunction = useCallback(
    (event) => {
      if (event.key === "Escape") {
        setVisible(false);
      }
    },
    [setVisible]
  );

  useEffect(() => {
    document.addEventListener("keydown", closeFunction, false);

    return () => {
      document.removeEventListener("keydown", closeFunction, false);
    };
  }, [closeFunction]);

  useEffect(() => {
    if (visible) {
      setFilteredCountries(countries);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  if (loading) {
    return null;
  }

  return (
    <div ref={ref} className="flex flex-col w-full mt-2">
      <label
        htmlFor="country"
        className="text-sm font-medium text-gray-700 flex"
      >
        Country
      </label>
      <div className="mt-1 relative w-full">
        <div
          onClick={() => {
            setVisible(!visible);
          }}
          type="text"
          className="flex cursor-pointer hover:ring-float-purple-3 focus:ring-1 hover:border-float-purple-4 w-full pl-2 pr-2 sm:text-sm border-gray-300 rounded h-10 disabled:text-black disabled:bg-zinc-100"
        >
          <img
            src={`https://flagcdn.com/w40/${value.code}.png`}
            className="w-6 h-6 rounded-full"
            alt={`Country flag for ${value.name}`}
          />
          <span className="ml-4">{value.name}</span>
        </div>
        {visible && (
          <div className="flex flex-col h-48 w-full overflow-auto absolute border-2 rounded-md z-10 bg-white px-2 pt-2">
            <input
              autoFocus={true}
              className="flex flex-row mt-2cursor-pointer rounded-md p-1"
              type="text"
              placeholder="Search"
              onChange={(e) => {
                const value = e.target.value;
                if (!value) {
                  setFilteredCountries(countries);
                  return;
                }
                setFilteredCountries(
                  countries.filter((c) =>
                    c.name.toLowerCase().includes(value.toLowerCase())
                  )
                );
                //setCountries(filteredCountries);
              }}
            ></input>
            {filteredCountries.map((country) => (
              <div
                onClick={() => {
                  setCountryCookie(country);
                  setValue(country);
                  setVisible(false);
                }}
                value={JSON.stringify(country)}
                key={country.code}
                className="flex flex-row mt-2 hover:border-2 hover:border-float-purple-4 cursor-pointer rounded-md p-1"
              >
                <img
                  src={`https://flagcdn.com/w40/${country.code}.png`}
                  className="w-6 h-6 rounded-full"
                  alt={`Country flag for ${country.name}`}
                />
                <span className="ml-4">{country.name}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CountrySelector;
