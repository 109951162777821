import { useState, createContext } from "react";

export const VALUES = {
  COMPANY_USER_NO_PERMISSION:
    "You do not have permission to register this company.",
  COMPANY_DETAILS_ERROR: "There was an issue retrieving the company details.",
};

export const ErrorContext = createContext(null);

const ErrorHandling = ({ children }) => {
  const [error, setError] = useState(null);

  function clearError() {
    setError(null);
  }

  function getHumanReadableMessage() {
    if (error) {
      return (
        VALUES[error] ||
        "Your account is not connected to a company. Please enter your company's organisation number to begin."
      );
    }

    return null;
  }

  return (
    <ErrorContext.Provider
      value={{ error, setError, clearError, getHumanReadableMessage }}
    >
      {children}
    </ErrorContext.Provider>
  );
};

export default ErrorHandling;
